import './Login.css';
import { useState,useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../shared/context/authContext';

function Login() {

  const auth = useContext(AuthContext)
  let navigate = useNavigate()

  const [error,setError]=useState({})
  const [showPswd,setShowPswd]=useState(false)
  const [values, setValues] = useState({
    username: "",
    password: ""
  });

  const handleChange = (e) => {
    setValues({
      ...values, [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);

    const payload = {
      username: values.username,
      password: values.password
    }

    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_URL+`accounts/login/`,
      data: payload,
      headers: {
        // 'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      console.log(response);
      if(response.data.type===101){
      auth.login(response.data.idencode,response.data.bearer);
      navigate('/');
      setValues(
          {
              password: "",
              username: "",
          }
      )
        }
        else{
          setError({detail:"Restricted for this User"}) 
        }
    }
    )
      .catch((error) => {
        console.log('error', error.response.data)
        setError(error.response.data)

      })
 
  }

  return (
    <div className="App">
      <div className="container">
        <h1 className="page-title">
          PAS Company
        </h1>
        <span className="page-desc">
          Login to manage your Route sale!
        </span>
        <form className="login-form form">
          <input autoFocus type="email" style={{outline:'none',border:'none'}} className="input" name='username' value={values.username} placeholder="Username" onChange={handleChange} />
          <center><span style={{ color: "red" }}>{error.username}</span></center>
          <div className="password-container">
          <input style={{boxShadow:'none',outline:'none',border:'none'}} type={showPswd?'text':'password'} className="input" name='password' value={values.password} placeholder="Password" onChange={handleChange} />
          {!showPswd?<i onClick={()=>setShowPswd(true)} class="fa-sharp fa-solid fa-eye"></i>:
          <i onClick={()=>setShowPswd(false)} class="fa-solid fa-eye-slash"></i>}
          </div>
          <span style={{ color: "red"}}>{error.password}</span>
          {/* <p className="forgot-link" >Forgot password?</p> */}
          <button style={{marginTop:'20px'}} className="send-btn btn" onClick={handleSubmit}>Login</button>
          <center><span style={{ color: "red" }}>{error.detail}</span></center>
        </form>
      </div>
    </div>
  );
}

export default Login;

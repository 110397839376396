import React, { useState, useContext, useEffect, useRef } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import ImageUpload from "../../common/components/ImageUpload";
import { AuthContext } from "../../shared/context/authContext";
import capitalize from "../../common/Capitalize";
import Loading from "../../common/Loading";

function AddShop({ onClose,forRefresh,routeId,routeName }) {

    const [shop, setShop] = useState({
        route: routeId || "",
        name: "",
        latitude: "",
        longitude: "",
        optradio: "",
        gst_number:'',
        location:'',
        address:'',
        phone:''

    });
    const [loading, setLoading] = useState(false);
    const [error,setError]=useState([])
    const[routeForApi,setRouteForApi]=useState(routeId || null)
    const [value, setValue] = useState(routeName || "Select a Route")
    const [routeSearch, setRouteSearch] = useState('')
    const [shopSearch, setShopSearch] = useState('')
    const [dropDownRoute, setDropDownRoute] = useState([])
    const [dropDownShop, setDropDownShop] = useState([])
    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=route&limit=1000&offset=0&name=${routeSearch}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('route for dropdown', response.data.results)
            setDropDownRoute(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })

        
    }, [routeSearch])

    useEffect(()=>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=shop&limit=1000&offset=0&name=${shopSearch}&route=${routeForApi}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop for dropdown', response.data.results)
            setDropDownShop(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    },[routeForApi, shopSearch])

    const auth = useContext(AuthContext)
   
    const [image, setImage] = useState("");
    const onImageAdd = (img) => {
        setImage(img);
    }

    const notify = () => toast.success("Great! Shop Added Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setShop({
            ...shop, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        console.log(shop)
        let position
        if (shop.optradio === 'End') {
            position = 0
        }
        else if (shop.optradio === 'Begining') {
            position = 1
        }
        else if (shop.optradio === 'After') {
            position = positionValue+1
        }
        else if (shop.optradio === 'Before') {
            position = positionValue
        }
        console.log(position)
        var formdata = new FormData();
        formdata.append("route", shop.route);
        formdata.append("name",capitalize(shop.name));
        formdata.append("position", position);
        formdata.append("latitude", shop.latitude);
        formdata.append("longitude", shop.longitude);
        formdata.append("gst_number", shop.gst_number);
        image && formdata.append("image", image);
        formdata.append("location",capitalize(shop.location));
        formdata.append("address",capitalize(shop.address));
        formdata.append("phone", shop.phone);
        

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/create/`,
            data: formdata,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'multipart/form-data'
            },
        }).then((response) => {
            console.log(response);
            setShop(
                {
                    route: "",
                    name: "",
                    latitude: "",
                    longitude: "",
                    optradio:"",
                    gst_number:'',
                    location:'',
        address:'',
        phone:''
                }
            )
            setImage("")
            notify();
            onClose()
            forRefresh();
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error)
                setError(error.response.data)
                setLoading(false)

            })




    }
    const[positionValue,setPositionValue] = useState()
    const handleChangeShop = (position, name) => {
        setPositionValue(position)
        setShopPosition(name)
        setShowShop(false)
    }
    const handleChangeRoute = (id, name) => {
        setShop({
            ...shop, route: id
        });
        setValue(name)
        setPositionValue()
        setShopPosition("Select a Shop")
        setShow(false)
        setRouteForApi(id)
    }
    
    const [show, setShow] = useState(false)
    const handleToggle = (e) => {
        e.target.focus();
        setShow(!show);
    }
    const [shopPosition, setShopPosition] = useState("Select a Shop")
    const [showShop, setShowShop] = useState(false)
    const handleToggleShop = (e) => {
        e.target.focus();
        setShowShop(!showShop);
    }



    return (
        <div className="dialoguePage">
            {loading && <Loading/>}
            <div className="dialogueBox" style={{ height: "700px",width:'1000px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Add Shop</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <h4 style={{ marginTop: "5px" }}>Where do you want to Add?</h4>
                <div className="radio-option">
                    <div className="radio-item">
                        <label>
                            <input type="radio" name="optradio" value='Begining' onChange={handleChange} />At Begining
                        </label>
                    </div>

                    <div className="radio-item">
                        <label >
                            <input type="radio" name="optradio" value='End' onChange={handleChange} />At End
                        </label>
                    </div>

                    <div className="radio-item">
                        <label >
                            <input type="radio" name="optradio" value='Before' onChange={handleChange} />Before a Shop
                        </label>
                    </div>

                    <div className="radio-item">
                        <label >
                            <input type="radio" name="optradio" value='After' onChange={handleChange} />After a Shop
                        </label>
                    </div>
                </div>

                <form>
                    <div className="user-details">
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Route*</span><span style={{color:'red',fontWeight:'400'}}>{error.route?error.route:''}</span></div>


                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={value}
                                        className="dropdown"
                                        onClick={handleToggle}
                                    />
                                </label>
                                <div hidden={!show} className="dropdown-list" style={{width:'460px'}}>
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setRouteSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownRoute.map((DRoute) => (
                                            <li className="option" onClick={() => handleChangeRoute(DRoute.id, DRoute.name)} key={DRoute.id}>{DRoute.name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>



                            {/* <select className='dropdown' name="route" value={shop.route} onChange={handleChange} >
                                <option>Select Route</option>
                                {dropDownRoute !==null && dropDownRoute.map((DRoute)=>(
                                    <option key={DRoute.id} value={DRoute.id}>{DRoute.name}</option>
                                ))}
                            </select> */}
                        </div>
                        <div className="input-box" >
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Before/After</span><span style={{color:'red',fontWeight:'400'}}>{error.position?error.position:''}</span></div>


                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={shopPosition}
                                        className="dropdown"
                                        onClick={handleToggleShop}
                                        disabled={shop.route !== '' && (shop.optradio === "Before" || shop.optradio === 'After')?false:true}
                                    />
                                </label>
                                <div hidden={!showShop} className="dropdown-list" style={{width:'460px'}}>
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setShopSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownShop.map((DShop) => (
                                            <li className="option" onClick={() => handleChangeShop(DShop.position, DShop.name)} key={DShop.id}>{DShop.name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>

                        </div>



                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Name*</span><span style={{color:'red',fontWeight:'400'}}>{error.name?error.name:''}</span></div>
                            <input type="text" placeholder="Enter Name" name="name" value={shop.name} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Location</span>
                            <input type="text" placeholder="Enter Location" value={shop.location} name="location" onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Address</span>
                            <input type="text" placeholder="Enter Address" value={shop.address} name="address" onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Phone</span>
                            <input type="number" placeholder="Enter Phone" value={shop.phone} name="phone" onChange={handleChange} required />
                        </div>

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Latitude*</span><span style={{color:'red',fontWeight:'400'}}>{error.latitude?error.latitude:''}</span></div>
                            <input type="text" placeholder="Enter Latitude" name="latitude" value={shop.latitude} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Longitude*</span><span style={{color:'red',fontWeight:'400'}}>{error.longitude?error.longitude:''}</span></div>
                            <input type="text" placeholder="Enter Longitude" name="longitude" value={shop.longitude} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">GST Number</span>
                            <input type="text" placeholder="Enter GST number" name="gst_number" value={shop.gst_number} onChange={handleChange} required />
                        </div>

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Photo*</span><span style={{color:'red',fontWeight:'400'}}>{error.image?error.image:''}</span></div>
                            <div className="imageSelect ">
                                <ImageUpload imageAdd={onImageAdd} text={image?"Change Shop Photo":"Select Shop Photo"} />
                            </div>
                        </div>
                    </div>
                    {/* <center><span style={{ color: "red" }}>{error}</span></center> */}
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddShop;
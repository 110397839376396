import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../shared/context/authContext'
import './viewUser.css'
import MainHeader from '../../common/components/MainHeader';
import { useParams } from 'react-router-dom'
import Sidebar from '../../common/components/Sidebar';
import axios from 'axios';
import Loading from '../../common/Loading';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import EditOrderEmployee from '../popupForms/EditOrderEmployee';
import EditOrderVehicle from '../popupForms/EditOrderVehicle';
import EditOrderDelivery from '../popupForms/EditOrderDelivery';
import EditOrderDate from '../popupForms/EditOrderDate';
import ViewNote from '../popupForms/ViewNote';
import ShopPurchasedPopup from '../popupForms/ShopPurchasedPopup';
import AddStock from '../popupForms/AddStock';
import SaleBillDetail from '../Component/SaleBillDetail';
import AddBill from '../popupForms/AddBill';
import EditStock from '../popupForms/EditStock';

const ViewOrder = () => {


    let notify;
    let navigate=useNavigate()

    const [searchShop, setSearchShop] = useState("");
    const [searchBill, setSearchBill] = useState("");
    const [addBill,setAddBill] =useState(false)
    const [editEmployee, setEditEmployee] = useState('');
    const [note,setNote]=useState('')
    const [addStock,setAddStock]=useState(false)
    const [stockEdit,setStockEdit]=useState(null)
    const [shopPurchased, setShopPurchased] = useState(null);
    const [editDeliveryDate, setEditDeliveryDate] = useState(false);
    const [editOrderDate, setEditOrderDate] = useState(false);
    const [editVehicle, setEditVehicle] = useState(false);
    const auth = useContext(AuthContext)
    const orderId = useParams().orderId
    const [loading, setLoading] = useState(true);
    const [reLoad, setReLoad] = useState(true);
    const [verifiedShop, setVerifiedShop] = useState(true);
    const [activeShop, setActiveShop] = useState('completed');
    const [visitedStatus, setVisitedStatus] = useState('completed')
    const [deliveryStatus, setDeliveryStatus] = useState(localStorage.getItem('deliveryStatus') || 201)
    const [refresh, setRefresh] = useState(true);
    const [tab, setTab] = useState('shop')
    const [shop, setShop] = useState([])
    const [bill, setBill] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();

    const [nextDelivery, setNextDelivery] = useState("");
    const [previousDelivery, setPreviousDelivery] = useState("");
    const [countDelivery, setCountDelivery] = useState('');
    const [countADelivery, setCountADelivery] = useState(1);
    const [countBDelivery, setCountBDelivery] = useState('');
    const [prevCountBDelivery, setPrevCountBDelivery] = useState();

    const [order, setOrder] = useState({
        route: { id: '' }
    })
    const handleChange = (e) => {
        setOrder({
            ...order, [e.target.name]: e.target.value
        });
    }
    useEffect(() => {
        setLoading(true)
        const storedShopUrl = localStorage.getItem('viewOrderDeliveredTrackUrl');
        const storedShopData = JSON.parse(localStorage.getItem('viewOrderDeliveredPageDetails'));

            if (storedShopData && storedShopUrl) {
                setCountADelivery(storedShopData.countA)
                
      
                  axios({
                      method: 'get',
                      url: storedShopUrl,
                      headers: {
                          //  'Authorization': `bearer ${token}`,
                          'bearer': auth.token,
                          'user-id': auth.userId,
                          'Content-Type': 'application/json'
                      },
                  }).then((response) => {
                      console.log(response)
                      setBill(response.data.results);
                      setNextDelivery(response.data.next);
                      setCountDelivery(response.data.count)
                      setCountBDelivery(storedShopData.countA+response.data.results.length-1) 
                      setPreviousDelivery(response.data.previous);
                      setLoading(false)
                      setPrevCountBDelivery(response.data.results.length)
                  }
                  )
                      .catch((error) => {
                          console.log('error', error.response.data)
          
                      })
              }
              else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/bill/list/?order=${orderId}&shop=&status=&delivery=${deliveryStatus}&search=${searchBill}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('bill', response)
            setBill(response.data.results);
            setNextDelivery(response.data.next);
            setPreviousDelivery(response.data.previous);
            setLoading(false)
            setCountDelivery(response.data.count)
            setCountBDelivery(response.data.results.length)
            setLoading(false)
       
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setLoading(false)

            })
    }}, [deliveryStatus,refresh,searchBill])


    useEffect(() => {
        setLoading(true)

        const storedShopUrl = localStorage.getItem('viewOrderVisitedTrackUrl');
        const storedShopData = JSON.parse(localStorage.getItem('viewOrderVisitedPageDetails'));

            if (storedShopData && storedShopUrl) {
                setCountA(storedShopData.countA)
                
      
                  axios({
                      method: 'get',
                      url: storedShopUrl,
                      headers: {
                          //  'Authorization': `bearer ${token}`,
                          'bearer': auth.token,
                          'user-id': auth.userId,
                          'Content-Type': 'application/json'
                      },
                  }).then((response) => {
                      console.log(response)
                      setShop(response.data.results);
                      setNext(response.data.next);
                      setCount(response.data.count)
                      setCountB(storedShopData.countA+response.data.results.length-1) 
                      setPrevious(response.data.previous);
                      setLoading(false)
                      setPrevCountB(response.data.results.length)
                  }
                  )
                      .catch((error) => {
                          console.log('error', error.response.data)
          
                      })
              }
else{

        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/list/?position=&name=${searchShop}&route=${order.route?order.route.id:''}&order=${orderId}&status=${activeShop}&is_active=true&verified=${verifiedShop}&ordering=position`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop', response)
            setShop(response.data.results);
            setLoading(false)
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }}, [order, visitedStatus, reLoad,searchShop]
    )
    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/order-details/${orderId}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('order', response)
            setOrder(response.data);

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, [refresh]
    )
    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ' ' + month + ' ' + year;
        return time;
    }

    const actionBill = (billId, status) => {
        const payload = {
            is_active: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/bill/update/${billId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })

    }

    const onDelete = (e, shopId) => {
        notify = () => toast.success("Great! Shop Rejected Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
        e.stopPropagation()
        axios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/delete/${shopId}/`,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setReLoad(!reLoad)
            })
    }

    const completeOrder = (e) => {
        notify = () => toast.success("Great! Order Completed Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
        e.preventDefault();
        const payload = {
            status: 301
        }
        
        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/order/update/${orderId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)
            notify();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const onVerify = (e, shopId) => {
        e.stopPropagation()
        notify = () => toast.success("Great! Shop Approved Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
        const payload = {
            is_active: true,
            verified: true

        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/update/${shopId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const findBorder = (status) => {
        switch (status) {
            case 301: return '2px solid darkgreen'
            case 401: return '2px solid red'
            default: return '0'
        }
    }

    const generateBill = (e, shop) => {
        e.preventDefault();
        console.log(order)
        const payload = {
            order: orderId,
            shop: shop
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`sales/bill/create/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            navigate(`/bill/${response.data.id}`)
       
        }
        )
            .catch((error) => {
                notify = () => toast.error("There is one empty bill in this Order!!", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
                console.log('error', error.response.data)
             notify();

            })




    }

    const onSwitchPage = (url,action) => {
        localStorage.removeItem('viewOrderVisitedPageDetails')
        localStorage.setItem('viewOrderVisitedTrackUrl',url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setShop(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }


    const onSwitchPageDelivery = (url,action) => {
        localStorage.removeItem('viewOrderDeliveredPageDetails')
        localStorage.setItem('viewOrderDeliveredTrackUrl',url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setBill(response.data.results);
            setNextDelivery(response.data.next);
            setPreviousDelivery(response.data.previous);
            setLoading(false)
            setPrevCountBDelivery(response.data.results.length)
            if (action === 'next') {
                setCountADelivery((value) => value + 10)
                setCountBDelivery((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountADelivery((value) => value - 10)
                setCountBDelivery((value) => value - prevCountBDelivery)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const downloadOrder = (e) => {
        e.stopPropagation()
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/order/download/${orderId}/`,
            responseType: 'blob',
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/docx'
            },
        }).then((response) => {
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `order-${orderId}.docx`);
            document.body.appendChild(link);
            link.click();
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error)

            })
    }


    const downloadStock = (e) => {
        e.stopPropagation()
        axios({
            method: 'get',
            url:process.env.REACT_APP_BACKEND_URL+`sales/stock/download/?search=&order=${orderId}`,
            responseType: 'blob',
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/docx'
            },
        }).then((response) => {
            console.log(response)
            setRefresh(!refresh);
            const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Stock_${orderId}.docx`);
    document.body.appendChild(link);
    link.click();
            
        }
        )
            .catch((error) => {
                console.log('error', error)

            })
            
    }




    const completeDelivery = (e,billId) => {

        e.stopPropagation()
        const payload = {
            delivery: '201',
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/bill/update/${billId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }
    const findUnit=(value)=>{
        if(value===101){
            return 'KG'
        }
        else if(value===201){
            return 'L'
        }
        else if(value===301){
            return 'G'
        }
        else if(value===401){
            return 'ML'
        }
    }

    const actionLocationCheck = (status) => {
        const payload = {
            location_check: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/order/update/${orderId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }


    return (
        <div>
            {stockEdit!==null &&<EditStock onClose={()=>setStockEdit(null)} stockId={stockEdit} forRefresh={() => setRefresh(!refresh)}/>}
            {addBill && <AddBill onClose={()=>setAddBill(false)} orderId={orderId}/>}
            {note!=='' && <ViewNote onClose={setNote} note={note}/>}
            {addStock && <AddStock onClose={setAddStock} orderId={orderId} forRefresh={() => setRefresh(!refresh)}/>}
            {shopPurchased!==null && <ShopPurchasedPopup onClose={()=>setShopPurchased(null)} shops={shopPurchased}/>}
            {editDeliveryDate && <EditOrderDelivery onClose={setEditDeliveryDate} odrId={orderId} forRefresh={() => setRefresh(!refresh)} />}
            {editOrderDate && <EditOrderDate onClose={setEditOrderDate} odrId={orderId} forRefresh={() => setRefresh(!refresh)} />}
            {editVehicle && <EditOrderVehicle onClose={setEditVehicle} odrId={orderId} forRefresh={() => setRefresh(!refresh)} />}
            {editEmployee !== '' && <EditOrderEmployee onClose={setEditEmployee} userType={editEmployee} odrId={orderId} forRefresh={() => setRefresh(!refresh)} />}
            <Sidebar />
            { }
            <div class="content">
                {loading && <Loading />}
                <ToastContainer />
                <MainHeader />

                <div class="main-container">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <h3 style={{ color: "#060082", marginTop: "10px" }}>Order Details <button className='button-31' style={{padding:'5px 15px',background:'white',marginLeft:'10px',color:'black'}} onClick={downloadOrder}><i class="fa-solid fa-download" style={{color:'darkblue',cursor:'pointer'}}></i> Download</button></h3>
                        
                        <span style={{ color: order.status === 401 ? "red" : 'green', border: findBorder(order.status), padding: '3px 8px', fontWeight: '600' }}>
                            {order.status === 301 && 'Completed'}{order.status === 401 && 'Cancelled'}</span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0px', marginBottom: '0px' }}>
                        <h3><span style={{ fontWeight: '400', fontSize: '17px' }}>Visited Shop :</span> {order.info ? order.info.bill_count : ''}</h3>
                        <h3><span style={{ fontWeight: '400', fontSize: '17px' }}>Order Received :</span> {order.info ? order.info.active_bill : ''}</h3>
                        <h3><span style={{ fontWeight: '400', fontSize: '17px' }}>No Order :</span> {order.info ? order.info.no_order : ''}</h3>
                        <h3><span style={{ fontWeight: '400', fontSize: '17px' }}>Cancelled Shop :</span> {order.info ? order.info.canceled : ''}</h3>
                        <h3><span style={{ fontWeight: '400', fontSize: '17px' }}>Not Visited Shop :</span> {order.info ? order.info.not_visited : ''}</h3>

                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px' }}>

                         <div className="input-box">
                            <span className="details">Executive Name
                                <i onClick={() => setEditEmployee('201')} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i></span>

                            <input readOnly type="text" value={order.executive ? order.executive.full_name : ''} name="name" onChange={handleChange} required />
                        </div>
                        {order.type===101 &&
                        <div className="input-box">
                            <span className="details">Route</span>
                            <input readOnly type="text" value={order.route ? order.route.name : ''} name="name" onChange={handleChange} required />
                        </div>}
                        <div className="input-box">
                            <span className="details">Order Date
                            {order.status === 101 && <i onClick={() => setEditOrderDate(true)} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i>}</span>
                            
                            <input readOnly type="text" value={timeConverter(order.date)} name="name" onChange={handleChange} required />
                        </div>
                        {order.status === 101 && order.type===201 && <div className="input-box" style={{paddingTop:'30px',paddingLeft:'130px'}}>
                            <button className='button-31' style={{background:'darkgreen'}} onClick={completeOrder}>Complete Order</button>
                        </div>}

                       

                        {order.delivered_date && <div className="input-box">
                            <span className="details">Delivery Date
                                <i onClick={() => setEditDeliveryDate(true)} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i></span>
                            <input readOnly type="text" value={timeConverter(order.delivered_date)} name="name" onChange={handleChange} required />
                        </div>}
                        {(order.status === 201 || order.status === 301) && <>
                            <div className="input-box">
                                <span className="details">Salesman Name
                                    <i onClick={() => setEditEmployee('301')} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i></span>
                                <input readOnly type="text" value={order.salesman ? order.salesman.full_name : ''} name="name" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Driver
                                    <i onClick={() => setEditEmployee('401')} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i></span>
                                <input readOnly type="text" value={order.driver ? order.driver.full_name : ''} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Vehicle
                                    <i onClick={() => setEditVehicle(true)} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i></span>
                                <input readOnly type="text" value={order.vehicle ? `${order.vehicle.name} - ${order.vehicle.number}` : ''} required />
                            </div>

                        </>}

                        {(order.status === 101 || order.status === 201) &&
                        <div className="input-box" style={{display:'flex',marginTop:'25px'}}> 
                        <span className="details">Location Check : </span>
                         <div className="toggle-container" style={{ marginTop: '-5px', marginLeft: '5px' }}>

                         <div className="toggle-switch" onClick={(e) => actionLocationCheck(order.location_check)}>
                             <input readOnly type="checkbox" className="checkbox" checked={order.location_check}
                                 name='label' />
                             <label className="label" >
                                 <span className="inner" />
                                 <span className="switch" />
                             </label>
                         </div>
                         </div>
                     </div>}

                    </div>

                    <hr />

                    <hr style={{ border: '2px solid black' }} />
                    <div class="tabs" style={{ marginTop: '20px', marginLeft: '0px',display:'flex',justifyContent:'space-between' }}>
                        <div style={{display:'flex'}}>
                        <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab === 'shop' ? "activetab" : "tab"} onClick={() => {setTab('shop') }}>{order.type===101?'Shop Details':'Bill Details'}</div>
                        <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab === 'purchase' ? "activetab" : "tab"} onClick={() => {setTab('purchase');localStorage.removeItem('viewOrderVisitedTrackUrl');localStorage.removeItem('viewOrderVisitedPageDetails');localStorage.removeItem('viewOrderDeliveredTrackUrl');localStorage.removeItem('viewOrderDeliveredPageDetails');localStorage.removeItem('viewSalesrDeliveredTrackUrl');localStorage.removeItem('viewSalesDeliveredPageDetails');setCountADelivery(1)}}>Sales Details </div>
                        {order.type===201 && <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab === 'stock' ? "activetab" : "tab"} onClick={() => {setTab('stock');localStorage.removeItem('viewSalesDeliveredTrackUrl');localStorage.removeItem('viewSalesDeliveredPageDetails') }}>Stock Details</div>}
                        </div>
                        <div  style={{display:'flex'}}>
                        {tab === 'shop' && order.type===201 && <button onClick={()=>setAddBill(true)} style={{marginRight:'5px'}} className='button-31'>Add Bill</button>}
                        {tab === 'stock' && <button onClick={()=>{setAddStock(true)}} style={{marginRight:'5px'}} className='button-31'>Add Stock</button>}
                        {tab === 'stock' && 
                        <button onClick={downloadStock} className='button-31' style={{background:'darkblue',padding:'2px 10px',marginRight:'5px'}}  >
                                                <i style={{color:'white'}} class="fa-solid fa-file-export"></i>
                            </button>}
                            {order.type===201 && <> <div style={{fontSize:'15px',background:'lightgrey',padding:'2px 5px',fontWeight:'600',display:'flex',flexDirection:'column',marginRight:'5px',alignItems:'center'}}><span>Initial Count:</span> <span style={{fontSize:'15px',fontWeight:'bold',color:'darkblue'}}>{order.info && order.info.initial_count}</span></div>
                        <div style={{fontSize:'15px',background:'lightgrey',padding:'2px 5px',fontWeight:'600',display:'flex',flexDirection:'column',marginRight:'5px',alignItems:'center'}}>Initial Case: <span style={{fontSize:'15px',fontWeight:'bold',color:'darkblue'}}>{order.info && order.info.initial_case}</span></div>
                            <div style={{fontSize:'15px',background:'lightgrey',padding:'2px 5px',fontWeight:'600',display:'flex',flexDirection:'column',marginRight:'5px',alignItems:'center'}}>Balance Count: <span style={{fontSize:'15px',fontWeight:'bold',color:'darkblue'}}>{order.info && order.info.balance_count}</span></div>
                        <div style={{fontSize:'15px',background:'lightgrey',padding:'2px 5px',fontWeight:'600',display:'flex',flexDirection:'column',marginRight:'5px',alignItems:'center'}}>Balance Case: <span style={{fontSize:'15px',fontWeight:'bold',color:'darkblue'}}>{order.info && order.info.balance_case}</span></div>
                        </>}
                        <div style={{fontSize:'15px',background:'lightgrey',padding:'2px 5px',fontWeight:'600',display:'flex',flexDirection:'column',marginRight:'5px',alignItems:'center'}}>Sold Count: <span style={{fontSize:'15px',fontWeight:'bold',color:'darkblue'}}>{order.info && order.info.total_count}</span></div>
                        <div style={{fontSize:'15px',background:'lightgrey',padding:'2px 5px',fontWeight:'600',display:'flex',flexDirection:'column',marginRight:'5px',alignItems:'center'}}>Sold Case: <span style={{fontSize:'15px',fontWeight:'bold',color:'darkblue'}}>{order.info && order.info.total_case}</span></div>

                        <div style={{fontSize:'15px',background:'lightgrey',padding:'2px 5px',fontWeight:'600',display:'flex',flexDirection:'column',alignItems:'center'}}>Total Amount: <span style={{fontSize:'15px',fontWeight:'bold',color:'darkblue'}}>&#8377;{order.total_amount}</span></div>
                        </div>
                    </div>
                    {order.type===101 && <div>
                    {order.status === 101 && <>
                        {tab === 'shop' &&
                            <div>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div class="tabs" style={{ marginLeft: '0px' }}>
                                    <div class={visitedStatus === 'completed' ? "activetab" : "tab"} onClick={() => { setVisitedStatus('completed'); setActiveShop('completed'); setVerifiedShop(true);localStorage.removeItem('viewOrderVisitedTrackUrl');localStorage.removeItem('viewOrderVisitedPageDetails');setCountA(1) }}>Visited </div>
                                    <div class={visitedStatus === 'upcoming' ? "activetab" : "tab"} onClick={() => { setVisitedStatus('upcoming'); setActiveShop('upcoming'); setVerifiedShop(true);localStorage.removeItem('viewOrderVisitedTrackUrl');localStorage.removeItem('viewOrderVisitedPageDetails');setCountA(1) }}>Not Visited </div>
                                    <div class={visitedStatus === '' ? "activetab" : "tab"} onClick={() => { setVisitedStatus(''); setActiveShop(''); setVerifiedShop(false);localStorage.removeItem('viewOrderVisitedTrackUrl');localStorage.removeItem('viewOrderVisitedPageDetails');setCountA(1) }}>Not Verified </div>

                                </div>
                                {verifiedShop &&
                                <div class="search" onClick={()=>localStorage.removeItem('viewOrderVisitedTrackUrl')}>
                            <input type="text" placeholder="search here..." onChange={(e) =>{setSearchShop(e.target.value)}} />
                            <label ><i class="fas fa-search"></i></label>
                        </div>}
                                </div>
                                <table >
                                    <thead>
                                        <td>Name</td>
                                        {visitedStatus === 'completed' && <><td>Total Amount</td>
                                            {/* <td>Status</td> */}
                                        </>}
                                        <td>Action</td>
                                        {visitedStatus === 'completed' && <><td>View Bill</td>
                                          
                                        </>}


                                    </thead>
                                    {!loading &&
                                    <tbody>
                                        {shop.map((shop) => (
                                            <tr key={shop.id} >

                                                <td>{shop.name}</td>
                                                {visitedStatus === 'upcoming' &&
                                                 <button className='button-31' onClick={(e)=>generateBill(e,shop.id)} style={{marginTop: "4px", color: 'darkgreen',background:'white' }}>
                                                    Generate Bill
                                                    </button>}
                                             
                                                {visitedStatus === 'completed' && <td>&#8377; {shop.bill_details ? shop.bill_details.total_amount : '0'}</td>}
                                                {/* {visitedStatus === 'completed' && <td>{shop.bill_details ? shop.bill_details.status : ''}</td>} */}
                                                {visitedStatus === 'completed' && shop.bill_details && (shop.bill_details.status === 301 || shop.bill_details.status === 101) &&
                                                    <td><button className='button-31' style={{background:'white',padding:'2px 10px'}} onClick={()=>setNote(shop.bill_details.note)}><i style={{ color: 'darkblue' }} class="fa-solid fa-comment"></i></button></td>}
                                                {visitedStatus === 'completed' && <> {shop.bill_details && shop.bill_details.status === 201 &&
                                                    <td style={{display:'flex'}}>
                                                        <div className="toggle-container">

                                                            <div className="toggle-switch" onClick={() => actionBill(shop.bill_details.idencode, shop.bill_details.is_active)}>
                                                                <input type="checkbox" className="checkbox" checked={shop.bill_details.is_active}
                                                                    name='label' />
                                                                <label className="label" >
                                                                    <span className="inner" />
                                                                    <span className="switch" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                            
                                                    </td>}
                                                   <td><button onClick={()=>{navigate(`/bill/${shop.bill_details.idencode}`);localStorage.setItem('viewOrderVisitedPageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}))}} className="button-31" style={{padding:'2px',width:'25%',background:'white'}}><i style={{color:'blue'}} class="fa-solid fa-chevron-right"></i></button></td>
                                                </>}

                                                {!verifiedShop && <div style={{ marginTop: '5px' }}> <button onClick={(e) => onVerify(e, shop.id)} className='button-31' style={{padding: '5px 8px',width:'30px',background:'green'}}><i style={{ color: 'white' }} class="fa-sharp fa-solid fa-check" ></i></button>
                                                    <button onClick={(e) => onDelete(e, shop.id)} className='button-31' style={{padding: '5px 8px',width:'30px',background:'rgb(212, 7, 7)', marginLeft: '5px' }}><i style={{ color: 'white' }} class="fa-sharp fa-solid fa-close"></i></button>
                                                </div>}
                                            </tr>
                                        ))}

                                    </tbody>}
                                </table>
                                <hr />
                            {!loading && <div className="switchbutton">
                               {(previous || next) && <div>
                                
                               <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                               <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                           </div>}
                           {shop.length!==0 && !loading &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                           </div>}

                            </div>}
                      </>}
                      

                    {(order.status === 201 || order.status === 301) && <>
                        {tab === 'shop' &&
                            <div>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div class="tabs" style={{ marginLeft: '0px' }}>
                                    <div class={deliveryStatus == 201 ? "activetab" : "tab"} onClick={() => {setDeliveryStatus(201);localStorage.setItem('deliveryStatus',201);localStorage.removeItem('viewOrderDeliveredTrackUrl');localStorage.removeItem('viewOrderDeliveredPageDetails');setCountADelivery(1)}}>Delivered </div>
                                    <div class={deliveryStatus == 101 ? "activetab" : "tab"} onClick={() =>{setDeliveryStatus(101);localStorage.setItem('deliveryStatus',101);localStorage.removeItem('viewOrderDeliveredTrackUrl');localStorage.removeItem('viewOrderDeliveredPageDetails');setCountADelivery(1)}}>Not Delivered</div>

                                </div>
                                <div class="search" onClick={()=>localStorage.removeItem('viewOrderDeliveredTrackUrl')}>
                            <input type="text" placeholder="search here..." onChange={(e) =>{setSearchBill(e.target.value)}} />
                            <label ><i class="fas fa-search"></i></label>
                        </div>
                        </div>
                                <table >
                                    <thead>
                                        <td>Name</td>
                                        <td>Total Amount</td>
                                        {deliveryStatus == 101 && <td>Action</td>}




                                    </thead>
                                    {!loading && <tbody>
                                        {bill.map((bill) => (
                                            <tr key={bill.id} onClick={()=>{navigate(`/bill/${bill.id}`);localStorage.setItem('viewOrderDeliveredPageDetails',JSON.stringify({'countA':countADelivery,'countB':countBDelivery,'count':countDelivery}))}}>

                                                <td>{bill.shop.name}</td>
                                                <td>&#8377; {bill.total_amount}</td>
                                                {deliveryStatus == 101 && 
                                                <button className='button-31' onClick={(e)=>completeDelivery(e,bill.id)} style={{ marginTop: "4px", fontWeight: '600',background:'darkgreen' }}>Complete</button>}


                                            </tr>
                                        ))}

                                    </tbody>}
                                </table>
                                <hr />
                            <div className="switchbutton">
                               {(previousDelivery || nextDelivery) && <div>
                                
                               <button disabled={previousDelivery === null} className="nextbtn" onClick={() => onSwitchPageDelivery(previousDelivery, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                               <button disabled={nextDelivery === null} className="nextbtn" onClick={() => onSwitchPageDelivery(nextDelivery, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                           </div>}
                           {bill.length!==0 && !loading && <div style={{paddingLeft:'30px'}}>Showing {countADelivery}-{countBDelivery} of {countDelivery}</div>}
                           </div>


                            </div>}
                       </>}
                    </div>}

                    {order.type===201 && <div>
                        {tab === 'stock' &&
                                <table >
                                    <thead>
                                        <td></td>
                                        <td>Product</td>
                                        <td>Initial Quantity</td>
                                        <td>Sold Quantity</td>
                                        <td>Balance Quantity</td>
                                        <td>Action</td>
                                    </thead>
                                    {!loading && <tbody>
                                        {order.stock.map((stock,i) => (
                                           <tr>
                                            <td>{i+1}</td>
                                            <td>{stock.product.name} {stock.product.weight} {findUnit(stock.product.unit)}</td>
                                            <td>{stock.quantity} ({stock.case})</td>
                                            <td>{stock.selled_quantity} ({stock.selled_case})</td>
                                            <td>{stock.balance} ({stock.balance_case})</td>
                                            <td><i onClick={()=>setStockEdit(stock.id)} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i></td>
                                           </tr>
                                        ))}

                                    </tbody>}
                                </table>}
                                {tab === 'shop' &&
                                <SaleBillDetail orderId={orderId}/>}
                        </div>}


                    {tab === 'purchase' &&
                            <table >
                                <thead>
                                    <td></td>
                                    <td>Product Name</td>
                                    <td>Count (case)</td>
                                    <td>Info</td>



                                </thead>
                                <tbody>
                                    {order.info && order.info.purchase_details.map((order,i) => (
                                        <tr key={order.product__name} >
                                            <td>{i+1}</td>
                                            <td>{order.product__name} {order.weight} {findUnit(order.unit)}</td>
                                            <td><span style={{fontWeight:'bold'}}>{order.total}</span> ({order.case})</td>
                                            <td  onClick={()=>setShopPurchased(order.shops)} ><i style={{color:'blue'}} class="fa-solid fa-circle-info"></i></td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        }

                </div>
            </div>
        </div>


    )

}
export default ViewOrder
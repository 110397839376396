import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../shared/context/authContext";

function UpdateOrder({ onClose,id,refreshPage }) {

    const auth = useContext(AuthContext)

    const [error,setError]=useState()
    
    const [dropDownExecutive, setDropDownExecutive] = useState([])
    
    const [userSearch, setUserSearch] = useState('')
    
    const [valueExecutive, setValueExecutive] = useState("Select an Executive")
   
    const [showExecutive, setShowExecutive] = useState(false)
    const [order, setOrder] = useState({
        
        executive: "",
        date: "",
    });

    const notify = () => toast.success("Great! Order Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&type=201&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('executive for dropdown', response.data.results)
            setDropDownExecutive(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])


    const handleToggleExecutive = (e) => {
        e.target.focus();
        setShowExecutive(!showExecutive);
    }
    const handleChange = (e) => {
        setOrder({
            ...order, [e.target.name]: e.target.value
        });
    }

   
    const handleChangeExecutive = (id, name) => {
        setOrder({
            ...order, executive: id
        });
        setValueExecutive(name)
        setShowExecutive(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(order)
        const payload = {
           
            executive: order.executive,
            date: parseInt((new Date(order.date).getTime() / 1000).toFixed(0))
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/order/update/${id}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
           
            notify();
            onClose()
            refreshPage()
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError("Please Fill All Fields")
            })




    }

    const disabledDate = () =>{
        var today,dd,mm,yy;
        today=new Date()
        dd = today.getDate();
        mm = today.getMonth()+1
        yy = today.getFullYear()
        dd<10?dd='0'+dd:dd=dd
        mm<10?mm='0'+mm:mm=mm
        return yy+'-'+mm+'-'+dd
    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Update Order</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={()=>onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                            <span className="details">Executive</span>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={valueExecutive}
                                        className="dropdown"
                                        onClick={handleToggleExecutive}
                                    />
                                </label>
                                <div hidden={!showExecutive} className="dropdown-list" >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setUserSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownExecutive.map((DExecutive) => (
                                            <li className="option" onClick={() => handleChangeExecutive(DExecutive.id, DExecutive.full_name)} key={DExecutive.id}>{DExecutive.full_name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="input-box">
                            <span className="details">Pickup Date</span>
                            <input min={disabledDate()} type="date" name="date" value={order.date} onChange={handleChange} required />
                        </div>
                    </div>
                    <center><span style={{ color: "red" }}>{error}</span></center>
                    <div className="button" >
                        <input type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default UpdateOrder;
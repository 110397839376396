import React, { useState, useContext } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ImageUpload from "../../common/components/ImageUpload";
import { AuthContext } from "../../shared/context/authContext";
import Loading from "../../common/Loading";

function EditShopPhoto({ onClose,shopId }) {

    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState(false)


    const [profile_pic, setProImage] = useState(null);
    const onProImageAdd = (img) => {
        setProImage(img);
    }

    const notify = () => toast.success("Great! Photo Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });



    const handleSubmit = (e) => {
        e.preventDefault();
setLoading(true)
        var formdata = new FormData();
        formdata.append("image", profile_pic);

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/update/${shopId}/`,
            data: formdata,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'multipart/form-data'
            },
        }).then((response) => {
            console.log(response);


            setProImage("");
            notify();
            onClose()
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setLoading(false)
            })




    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px', width: '700px' }}>
                {loading && <Loading/>}
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Update Photo</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details">

                        <div className="input-box" style={{ width: '700px' }}>
                            <span className="details">Photo</span>
                            <div className="imageSelect ">
                                <ImageUpload imageAdd={onProImageAdd} text={profile_pic?"Change Photo":"Select Photo"} />
                            </div>
                        </div>
                    </div>

                    <div className="button" >
                        <input type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default EditShopPhoto;
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../shared/context/authContext'
import './viewUser.css'
import MainHeader from '../../common/components/MainHeader';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom'
import Sidebar from '../../common/components/Sidebar';
import Loading from '../../common/Loading';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import capitalize from '../../common/Capitalize';
import AddShop from '../popupForms/AddShop';

const ViewRoute = () => {

    let navigate = useNavigate()
    
    
    const openMap = (lat, lon) => {
        const url = `https://www.google.com/maps?q=${lat},${lon}`;
        var top = window.screen.height - 600;
        top = top > 0 ? top / 2 : 0;

        var left = window.screen.width - 800;
        left = left > 0 ? left / 2 : 0;

        var uploadWin = window.open(url, "location", "width=800,height=600" + ",top=" + top + ",left=" + left);
        uploadWin.moveTo(left, top);
        uploadWin.focus();
    }

    const [newShop, setNewShop] = useState(false)
    const [refresh,setRefresh] = useState(false)
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(localStorage.getItem('shopStatus') || 'active')
    const [active, setActive] = useState(localStorage.getItem('activeShop') || true)
    const [verify, setVerify] = useState(localStorage.getItem('verifyShop') || true)
    const [searchShop, setSearchShop] = useState("");
    const auth = useContext(AuthContext)
    const routeId = useParams().routeId
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [shop, setShop] = useState([])
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();
    const [route, setRoute] = useState({
        name: "",
        start: "",
        end: ""
    })
    const handleChange = (e) => {
        setRoute({
            ...route, [e.target.name]: e.target.value
        });
    }
    const notify = () => toast.success("Great! Route Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
    const updateShop = (e) => {
        e.preventDefault();

        const payload = {
            name:capitalize(route.name),
            start:capitalize(route.start),
            end:capitalize(route.end),
          }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/route/update/${routeId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            notify();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }
    
    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/route-details/${routeId}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('route', response)
            setRoute(response.data);
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, []
    )
    useEffect(() => {
        setCountA(1)

        const storedShopUrl = localStorage.getItem('viewRouteShopTrackUrl');
        const storedShopData = JSON.parse(localStorage.getItem('viewRouteShopPageDetails'));

            if (storedShopData && storedShopUrl) {
                setCountA(storedShopData.countA)
                
      
                  axios({
                      method: 'get',
                      url: storedShopUrl,
                      headers: {
                          //  'Authorization': `bearer ${token}`,
                          'bearer': auth.token,
                          'user-id': auth.userId,
                          'Content-Type': 'application/json'
                      },
                  }).then((response) => {
                      console.log(response)
                      setShop(response.data.results);
                      setNext(response.data.next);
                      setCount(response.data.count)
                      setCountB(storedShopData.countA+response.data.results.length-1) 
                      setPrevious(response.data.previous);
                      setLoading(false)
                      setPrevCountB(response.data.results.length)
                  }
                  )
                      .catch((error) => {
                          console.log('error', error.response.data)
          
                      })
              }

else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/list/?position=&name=${searchShop}&route=${routeId}&order=&status=&verified=${verify}&ordering=position&is_active=${active}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop', response)
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setShop(response.data.results);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }}, [active, verify,searchShop,refresh]
    )

    const onSwitchPage = (url,action) => {
        localStorage.removeItem('viewRouteShopPageDetails')
        localStorage.setItem('viewRouteShopTrackUrl',url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setShop(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }


    return (
        <div>
            
            <Sidebar />
            <ToastContainer />
            {newShop && <AddShop onClose={()=>setNewShop(!newShop)} forRefresh={()=>setRefresh(!refresh)} routeId={routeId} routeName={route.name}/>}
            <div class="content">
                <MainHeader />
                {loading && <Loading />}

                <div class="main-container">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', marginBottom: '10px' }}>
                        <h3>{route.name} Route</h3>
                        <button className='button-31' style={{ background: 'green', color: 'white' }} onClick={updateShop} >Save</button>
                    </div>
                    <hr />
                    <h3 style={{ color: "#060082", marginTop: "10px" }}>Route Details</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px' }}>

                        <div className="input-box">
                            <span className="details">Name</span>
                            <input type="text" value={route.name} name="name" onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Start</span>
                            <input type="text" value={route.start} name="start" onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">End</span>
                            <input type="text" value={route.end} name="end" onChange={handleChange} required />
                        </div>
                    </div>
                    <hr style={{ border: '2px solid black' }} />
                   
                    <h3 style={{ color: "#060082", marginTop: "10px" }}>Shops on this Route</h3>
                    
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    <div class="tabs" style={{ marginLeft: '0px',marginTop:'10px' }}>
                        <div class={status === 'active' ? "activetab" : "tab"} onClick={() => { setStatus('active'); setActive(true); setVerify(true);setCountA(1);localStorage.setItem('activeShop', 'true');localStorage.setItem('verifyShop', 'true');localStorage.setItem('shopStatus', 'active');localStorage.removeItem('viewRouteShopTrackUrl') }}>Active</div>
                        <div class={status === 'notactive' ? "activetab" : "tab"} onClick={() => { setStatus('notactive'); setActive(false); setVerify(true);setCountA(1);localStorage.setItem('activeShop', 'false');localStorage.setItem('verifyShop', 'true');localStorage.setItem('shopStatus', 'notactive') ;localStorage.removeItem('viewRouteShopTrackUrl')}}>Not-Active</div>
                        <div class={status === 'notverified' ? "activetab" : "tab"} onClick={() => { setStatus('notverified'); setActive(true); setVerify(false);setCountA(1);localStorage.setItem('activeShop', 'true');localStorage.setItem('verifyShop', 'false');localStorage.setItem('shopStatus', 'notverified') ;localStorage.removeItem('viewRouteShopTrackUrl')}}>Not-Verified</div>
                    </div>
                    <div class="search" onClick={()=>localStorage.removeItem('viewRouteShopTrackUrl')}>
                            <input type="text" placeholder="search shops here..." onChange={(e) => setSearchShop(e.target.value)} />
                            <label ><i class="fas fa-search"></i></label>
                        </div><div></div><div><button className='button-31' onClick={()=>setNewShop(!newShop)}>New</button></div></div>
                    <table >
                        <thead>
                            <td>Name</td>
                            <td>Position</td>
                            <td>Location</td>


                        </thead>
                        <tbody>
                            {shop.map((shop) => (
                                <tr key={shop.id} onClick={ ()=>{navigate(`/shop/${shop.id}`);localStorage.removeItem('ShopViewTab');localStorage.setItem('viewRouteShopPageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}))}}>

                                    <td>{shop.name}</td>
                                    <td>{shop.position}</td>
                                    <td> <button className='button-31' style={{ border: '1px solid grey',padding:'0px 5px',background:'white',color:'black' }} onClick={(e) =>{e.stopPropagation();openMap(shop.latitude,shop.longitude)}}><i style={{color:'blue'}} class="fa-solid fa-location-dot"></i>Open map</button></td>

                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                {shop.length!==0 && <hr style={{margin:'0 15px'}}/>  }
                
                <div className="switchbutton">
                               {(previous || next) && <div>
                                
                               <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                               <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                           </div>}
                           {shop.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                           </div>
            </div>
        </div>


    )

}
export default ViewRoute
import React, {useState,useContext} from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import ImageUpload from "../../common/components/ImageUpload";
import { AuthContext } from "../../shared/context/authContext";
import Loading from "../../common/Loading";
import capitalize from "../../common/Capitalize";

function AddUser({onClose,forRefresh}) {

  const [showCnfPswd,setShowCnfPswd]=useState(false)
  const [showPswd,setShowPswd]=useState(false)

  const auth = useContext(AuthContext)
  const [error,setError]=useState([])
  const [errorPass,setErrorPass]=useState()
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    username: "",
    first_name: "",
    last_name: "",
    password: "",
    cnfrm_password: "",
    type: "201",
    email: "",
    phone_number: "",
    driving_lc:''
  });
  const [aadhar, setAadharImage] = useState(null);
  const onAadharImageAdd = (img) => {
    setAadharImage(img);
  }
  const [profile_pic, setProImage] = useState(null);
  const onProImageAdd = (img) => {
    setProImage(img);
  }

  const notify = () => toast.success("Great! Employee Added Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

  const handleChange = (e) => {
    setUser({
      ...user, [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(user.password===user.cnfrm_password){
    setLoading(true)
    setErrorPass("")
    console.log(profile_pic,aadhar,user)

    var formdata = new FormData();
    formdata.append("username", user.username);
    formdata.append("first_name",capitalize(user.first_name));
    formdata.append("last_name",capitalize(user.last_name));
    formdata.append("email", user.email);
    formdata.append("password", user.password);
    formdata.append("phone_number", user.phone_number);
    formdata.append("type", user.type);
    profile_pic && formdata.append("profile_pic", profile_pic);
    aadhar && formdata.append("aadhar", aadhar);
    formdata.append("driving_lc", user.driving_lc);
    formdata.append("is_active", 'true');
    

    
    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_URL+`accounts/create/user/`,
      data: formdata,
      headers: {
        'bearer': auth.token,
        'user-id': auth.userId,
        'Content-Type': 'multipart/form-data'
      },
    }).then((response) => {
      console.log(response);
      setUser(
          {
            username: "",
            first_name: "",
            last_name: "",
            password: "",
            type: "201",
            email: "",
            phone_number: "",
            driving_lc:''
          }
      )
      setAadharImage("");
      setProImage("");
      notify();
      onClose()
      setLoading(false)
      forRefresh()
    }
    )
      .catch((error) => {
        console.log('error', error.response.data)
        setError(error.response.data)
        setLoading(false)
      })
    }
    else{
      setErrorPass("Password Mismatch")
    }



  }

  return (
    <div className="dialoguePage">
      {loading && <Loading/>}
      <div className="dialogueBox" style={{ height: "700px",width:'1000px' }}>
        <div className="head">
        <h2 style={{color:"#003cff"}}>Add User</h2>
        <span style={{color:"red",fontSize:"20px",cursor:"pointer"}} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
        </div>
      <form>
                <div className="user-details">
                  <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">User Name*</span><span style={{color:'red',fontWeight:'400'}}>{error.username?error.username:''}</span></div>
                    <input  type="text" placeholder="Enter Username" name="username" value={user.username} onChange={handleChange}  required />
                  </div>
                  <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Password*</span><span style={{color:'red',fontWeight:'400'}}>{error.password?error.password:''}</span></div>

                  <div className="password-container-user">
          <input style={{boxShadow:'none',outline:'none',border:'none',background:'white'}} type={showPswd?'text':'password'} className="input" value={user.password} placeholder="Enter Password" name="password" onChange={handleChange} />
          {!showPswd?<i onClick={()=>setShowPswd(true)} class="fa-sharp fa-solid fa-eye"></i>:
          <i onClick={()=>setShowPswd(false)} class="fa-solid fa-eye-slash"></i>}
          
          </div>

                    {/* <input  type="password" placeholder="Enter Password" name="password" value={user.password} onChange={handleChange}  required /> */}
                  </div>
                  <div className="input-box">
                    <span className="details"> Confirm Password*</span>


                    <div className="password-container-user">
          <input style={{boxShadow:'none',outline:'none',border:'none',background:'white'}} type={showCnfPswd?'text':'password'} className="input" value={user.cnfrm_password} name="cnfrm_password"   placeholder="Enter Password" onChange={handleChange} />
          {!showCnfPswd?<i onClick={()=>setShowCnfPswd(true)} class="fa-sharp fa-solid fa-eye"></i>:
          <i onClick={()=>setShowCnfPswd(false)} class="fa-solid fa-eye-slash"></i>}
          
          </div>


                    {/* <input type="text" placeholder="Enter Password" name="cnfrm_password" value={user.cnfrm_password} onChange={handleChange}  required /> */}
                  </div>
                  <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">First Name</span><span style={{color:'red',fontWeight:'400'}}>{error.first_name?error.first_name:''}</span></div>
                    <input type="text" placeholder="Enter First Name" name="first_name" value={user.first_name} onChange={handleChange}  required />
                  </div>
                  <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Last Name</span><span style={{color:'red',fontWeight:'400'}}>{error.last_name?error.last_name:''}</span></div>
                    <input type="text" placeholder="Enter Last Name" name="last_name" value={user.last_name} onChange={handleChange}  required />
                  </div>
                  <div className="input-box">
                   <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Email</span><span style={{color:'red',fontWeight:'400'}}>{error.email?error.email:''}</span></div> 
                    <input type="email" placeholder="Enter Email" name="email" value={user.email} onChange={handleChange}  required />
                  </div>
                  <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Mobile</span><span style={{color:'red',fontWeight:'400'}}>{error.phone_number?error.phone_number:''}</span></div>
                    <input type="number" placeholder="Enter Mobile Number" name="phone_number" value={user.phone_number} onChange={handleChange}  required />
                  </div>
                  <div className="input-box">
                    <span className="details">Type*</span>
                    <select className='dropdown' name="type" value={user.type} onChange={handleChange} >
                      <option value="201">Executive</option>
                      <option value="301">Salesman</option>
                      <option value="401">Driver</option>
                    </select>
                  </div>
                  <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Photo*</span><span style={{color:'red',fontWeight:'400'}}>{error.profile_pic?error.profile_pic:''}</span></div>
                    <div className="imageSelect ">
                  <ImageUpload imageAdd={onProImageAdd} text={profile_pic?"Change Profile Photo":"Select Profile Photo"}/>
                  </div>
                  </div>
                  <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Aadhar*</span><span style={{color:'red',fontWeight:'400'}}>{error.aadhar?error.aadhar:''}</span></div>
                    <div className="imageSelect ">
                  <ImageUpload imageAdd={onAadharImageAdd} text={aadhar?"Change Aadhar Photo":"Select Aadhar Photo"}/>
                  </div>
                  </div>
                  <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Licence Number</span><span style={{color:'red',fontWeight:'400'}}>{error.driving_lc?error.driving_lc:''}</span></div>
                    <input
                    type="text" placeholder="Enter Licence Number" name="driving_lc" value={user.driving_lc} onChange={handleChange}  required />
                  </div>
                </div>
                <center><span style={{ color: "red" }}>{errorPass}</span></center>
                <div className="button" >
                  <input type="submit" value="Add" onClick={handleSubmit}/>
                </div>
              </form>
      </div>
    </div>
  );
}
export default AddUser;
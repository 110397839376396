import './addUser.css';

function ShopPurchasedPopup({ onClose,shops }) {


    return (
        <div className="dialoguePage" >
            <div className="dialogueBox" style={{ height: 'auto',width:'auto',minWidth:'600px' }}>
                <div className="head" style={{marginBottom:'15px'}}>
                    <h2 style={{ color: "#003cff" }}>Shops</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                
                    <ul style={{listStyleType:'none',padding:'5px 0',maxHeight:'200px',overflow: 'auto'}}>
                        {shops.map((shop) => (
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} key={shop}>{shop} </li>
                            <hr/>
                            </div>
                        ))}

                    </ul>
                
            </div>
        </div>
    );
}
export default ShopPurchasedPopup;

import './addUser.css';


function ViewVehicleDetails({ onClose,vehicle}) {

    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ' ' + month + ' ' + year;
        return time;
    }
   

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: 'auto' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>{vehicle.name}</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={()=>onClose('')}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                <ul style={{listStyleType:'none',padding:'5px 0',maxHeight:'200px',overflow: 'auto'}}>
                     
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >Registration Date </li>
                            
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >Fitness Valid Upto </li>
                          
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >Tax Valid Upto </li>
                         
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >Insurance Valid Upto </li>
                            
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >PUCC Valid Upto </li>
                            
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >Permit Vaild Upto </li>
                            
                            </div>

                    </ul>
            
                    <ul style={{listStyleType:'none',padding:'5px 0',maxHeight:'200px',overflow: 'auto'}}>
                     
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >{vehicle.registration_date!==null?timeConverter(vehicle.registration_date):'empty'} </li>
                            
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >{vehicle.fitness_date!==null?timeConverter(vehicle.fitness_date):'empty'} </li>
                            
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >{vehicle.tax_date!==null?timeConverter(vehicle.tax_date):'empty'} </li>
                           
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >{vehicle.insurance_date!==null?timeConverter(vehicle.insurance_date):'empty'} </li>
                            
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >{vehicle.pollution_date!==null?timeConverter(vehicle.pollution_date):'empty'} </li>
                            
                            </div>
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} >{vehicle.permit_date!==null?timeConverter(vehicle.permit_date):'empty'} </li>
                            
                            </div>

                    </ul>
                    </div>
            </div>
        </div>
    );
}
export default ViewVehicleDetails;
import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import AddProduct from './popupForms/AddProduct';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import { useNavigate} from 'react-router-dom';
import Loading from '../common/Loading';
import DefultProduct from '../images/product.png'


const Product = () => {

    const auth = useContext(AuthContext)
    let navigate=useNavigate()
    const [view, setView] = useState(localStorage.getItem('pageView') ||"grid");
    const [searchProduct, setSearchProduct] = useState("");
    const [reLoad, setReLoad] = useState(true);
    const [newProduct, setNewProduct] = useState(false)
    const [product, setProduct] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(localStorage.getItem('activePrd')  || 'true')
    const [active, setActive] = useState(localStorage.getItem('activePrd')  || true)
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();

    const formHandler = () => {
        setNewProduct(!newProduct);
    }
    const findUnit=(value)=>{
        if(value===101){
            return 'KG'
        }
        else if(value===201){
            return 'LTR'
        }
        else if(value===301){
            return 'GM'
        }
        else if(value===401){
            return 'ML'
        }
    }
    useEffect(() => {
        setCountA(1)
        setLoading(true)
        const storedPrdUrl = localStorage.getItem('prdTrackUrl');
        const storedPrdData = JSON.parse(localStorage.getItem('prdPageDetails'));
        if (storedPrdData && storedPrdUrl) {
          setCountA(storedPrdData.countA)
          

            axios({
                method: 'get',
                url: storedPrdUrl,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                console.log(response)
                setProduct(response.data.results);
                setNext(response.data.next);
                setCount(response.data.count)
                setPrevious(response.data.previous);
                setLoading(false)
                setCountB(storedPrdData.countA+response.data.results.length-1) 
                setPrevCountB(response.data.results.length)
            }
            )
                .catch((error) => {
                    console.log('error', error.response.data)
    
                })
        }
        else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/product/list/?search=${searchProduct}&ordering=&is_active=${active}&limit=12`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('product', response)
            setProduct(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }}, [searchProduct,reLoad,active])

    const onSwitchPage = (url,action) => {
        localStorage.removeItem('prdPageDetails')
        localStorage.setItem('prdTrackUrl',url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setProduct(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 12)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 12)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    return (
        <div className='full'>
            
            {newProduct && <AddProduct onClose={formHandler} forReload={()=>setReLoad(!reLoad)}/>}
            <Sidebar name="product" />
            <ToastContainer />
            <div class="content">
            {loading && <Loading/>}
                <MainHeader />


                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginBottom: "10px" }}>Product Details</h3>
                        <div class="search" onClick={()=>localStorage.removeItem('prdTrackUrl')}>
                            <input type="text"  placeholder="search here..." onChange={(e)=>setSearchProduct(e.target.value)} />
                            <label for="search"><i class="fas fa-search"></i></label>
                        </div>
                        <div>
                        {view==='grid' && <button onClick={()=>{setView('list');localStorage.setItem('pageView','list')}} style={{marginRight:'10px',height:'32px',width:'32px',background:'grey',opacity:'50%'}}> <i class="fa-solid fa-list" style={{color:'white'}}></i></button>}
                        {view==='list' && <button onClick={()=>{setView('grid');localStorage.setItem('pageView','grid')}} style={{marginRight:'10px',height:'32px',width:'32px',background:'grey'}}> <i class="fa-solid fa-list" style={{color:'white'}}></i></button>}
                        <button className='button-31' onClick={formHandler}>New</button></div>
                    </div>
                    <div class="tabs" style={{ marginLeft: '0px' }}>
                           <div class={status === 'true' ? "activetab" : "tab"} onClick={() => {setStatus('true');setActive(true);setCountA(1);localStorage.setItem('activePrd', 'true');localStorage.removeItem('prdTrackUrl')}}>Active</div>
                           <div class={status === 'false' ? "activetab" : "tab"} onClick={() => {setStatus('false');setActive(false);setCountA(1);localStorage.setItem('activePrd', 'false');localStorage.removeItem('prdTrackUrl')}}>Not-Active</div>
                        </div>
                        {view==='grid' &&  <div className='user-container'>
                    {product.length===0 && <h4 style={{paddingLeft:'40%',paddingTop:'5%'}}>No results found.</h4>}
                        {product.map((prdt) => (
                            <div className='user-box' key={prdt.id} onClick={()=>{navigate(`/product/${prdt.id}`);localStorage.setItem('prdPageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}))}}>

                                <figure class="snip1559" style={{border:'3px solid green'}}>
                                    <div className='card-in'>
                                    <div class="profile-image"><img src={prdt.images.length>0? prdt.images[0].image:DefultProduct}/></div>
                                    <figcaption>
                                    <p style={{fontWeight:'700'}}>{prdt.name} {prdt.weight} {findUnit(prdt.unit)}</p>
                                       
                                        <p style={{color:'blue',fontWeight:'600'}}>&#8377;{prdt.price}</p>
                                    
                                        
                                    </figcaption>
                                    </div>
                                    
                                </figure>
                                

                            </div>
                        ))}

                    </div>}

                    {view==='list' &&  
                    <div class="table-content">
                            <table >
                                <thead>
                                    <td>Name</td>
                                    <td>Price</td>
                                  
                                 

                                </thead>
                                {!loading && <tbody>
                                    {product.map((product) => (
                                        <tr key={product.id} onClick={()=>{navigate(`/product/${product.id}`);localStorage.setItem('prdPageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}))}}>
                                            <td>{product.name} {product.weight} {findUnit(product.unit)}</td>
                                            <td>&#8377;{product.price}</td>

                                        </tr>
                                    ))}


                                </tbody>}
                            </table>
                        </div>
                        
                        }
                    
                            
                        </div>
                        <div className="switchbutton">
                                
                                {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {product.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                            </div>
                    </div>
                </div>
           

    )
}
export default Product;
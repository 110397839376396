import React, { useState, useContext,useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function AddPayment({ onClose,forReload }) {

    const auth = useContext(AuthContext)
    const [payment, setPayment] = useState({
        shop: "",
        amount: "",
    });
    const [error,setError]=useState([])
    const [dropDownShop, setDropDownShop] = useState([])
    const [shopSearch, setShopSearch] = useState('')
    const [value, setValue] = useState("Select a Shop")
    const [show, setShow] = useState(false)

    const notify = () => toast.success("Payment Created Successfully!", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setPayment({
            ...payment, [e.target.name]: e.target.value
        });
    }

    const handleToggle = (e) => {
        e.target.focus();
        setShow(!show);
    }

    const handleChangeShop = (id, name) => {
        setPayment({
            ...payment, shop: id
        });
        setValue(name)
        setShow(false)
    }

    useEffect(()=>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=shop&limit=1000&offset=0&name=${shopSearch}&route=`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop for dropdown', response.data.results)
            setDropDownShop(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    },[shopSearch])

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(payment)

        const payload = {
            shop: payment.shop,
            amount: payment.amount,
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`sales/payment/create/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setPayment(
                {
                    shop: "",
                    amount: "",
                }
            )
            notify();
            onClose()
            forReload();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)
            })




    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Create Payment</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Shop*</span><span style={{color:'red',fontWeight:'400'}}>{error.shop?error.shop:''}</span></div>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={value}
                                        className="dropdown"
                                        onClick={handleToggle}
                                    />
                                </label>
                                <div hidden={!show} className="dropdown-list" >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setShopSearch(e.target.value)} style={{position:'fixed',width:'406px'}} />
                                    <ul style={{paddingTop:'40px'}}  >
                                        {dropDownShop.map((DShop) => (
                                            <li className="option" onClick={() => handleChangeShop(DShop.id, DShop.name)} key={DShop.id}>{DShop.name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                       
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Amount*</span><span style={{color:'red',fontWeight:'400'}}>{error.amount?error.amount:''}</span></div>
                            <input type="number" placeholder="Enter Amount" name="amount" value={payment.amount} onChange={handleChange} required />
                        </div>
                    </div>
                    {/* <center><span style={{ color: "red" }}>{error}</span></center> */}
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddPayment;
import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function AddCreditPayment({ creditId, onClose, forReload }) {

    const auth = useContext(AuthContext)
    const [creditPayment, setCreditPayment] = useState({
        amount: '',
        payment_date: ''
    })
    const [error, setError] = useState([])

    const notify = () => toast.success("Payment Created Successfully!", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setCreditPayment({
            ...creditPayment, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(creditPayment)

        const payload = {
            credit_allotment: creditId,
            amount: creditPayment.amount,
            payment_date:parseInt((new Date(creditPayment.payment_date).getTime() / 1000).toFixed(0)) 
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `credits/payments/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setCreditPayment(
                {
                    amount: '',
                    payment_date: ''
                }
            )
            notify();
            onClose()
            forReload();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)
            })

    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Create Payment</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >
                        <div className="input-box">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}><span className="details">Amount*</span><span style={{ color: 'red', fontWeight: '400' }}>{error.amount ? error.amount : ''}</span></div>
                            <input type="number" placeholder="Enter Amount" name="amount" value={creditPayment.amount} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Date</span><span style={{color:'red',fontWeight:'400'}}>{error.payment_date?error.payment_date:''}</span></div>
                            <input type="date"  placeholder="Enter date" name="payment_date" value={creditPayment.payment_date} onChange={handleChange} required />
                        </div>
                    </div>
                    {/* <center><span style={{ color: "red" }}>{error}</span></center> */}
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddCreditPayment;
import React, { useState, useContext,useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../shared/context/authContext";

function EditShopBill({ onClose,bill, setRefresh  }) {

    const auth = useContext(AuthContext)
    const [error,setError]=useState()
    const [dropDownShop, setDropDownShop] = useState([])
    const [shopSearch, setShopSearch] = useState('')

    useEffect(()=>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=shop&limit=1000&offset=0&name=${shopSearch}&route=`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop for dropdown', response.data.results)
            setDropDownShop(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    },[shopSearch])

    const[shopId,setShopId] = useState()
    const handleChangeShop = (id, name) => {
        setShopId(id)
        setShopPosition(name)
        setShowShop(false)
    }
    const [shopPosition, setShopPosition] = useState("Select a Shop")
    const [showShop, setShowShop] = useState(false)
    const handleToggleShop = (e) => {
        e.target.focus();
        setShowShop(!showShop);
    }
    
    const notify = () => toast.success("Great! Shop Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
    const handleSubmit=(e)=>{
        e.preventDefault();

        const payload = {
            shop:shopId
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/bill/update/${bill}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            notify();
            onClose(false)
            setRefresh();
            setShopId('')

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{height: '300px',width:'700px'}}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Select Shop</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={()=>onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >


                    <div className="input-box" style={{ width:'700px' }}>
                           


                            <div >
                                <label className="arrow" >
                                    <textarea 
                                        readOnly
                                        type="button"
                                        value={shopPosition}
                                        className="dropdown"
                                        onClick={handleToggleShop}
                                    />
                                </label>
                                <div hidden={!showShop} className="dropdown-list" style={{ width:'656px' }} >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setShopSearch(e.target.value)} style={{ width:'650px' }} />
                                    <ul>
                                        {dropDownShop.map((DShop) => (
                                            <li className="option" onClick={() => handleChangeShop(DShop.id, DShop.name)} key={DShop.id}>{DShop.name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                   
                    <center><span style={{ color: "red" }}>{error}</span></center>
                    <div className="button" >
                        <input type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default EditShopBill;
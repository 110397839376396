import React from "react";
import './Loading.css'
import Spinner from '../images/Spinner.gif'
function Loading(){
return(
<div class="wrapper">
          <img src={Spinner} alt="Loading" class="img-loader"
          height='50px' width='50px'/>
        </div>
)
}
export default Loading;

import './addUser.css';


function ViewNote({ onClose,note}) {

   

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: 'auto' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Note</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={()=>onClose('')}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <div style={{marginTop:'10px'}}>
                    <p>{note}</p>
                </div>
            </div>
        </div>
    );
}
export default ViewNote;
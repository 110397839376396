import React, {useState,useContext} from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../common/Loading";

import ImageUpload from "../../common/components/ImageUpload";
import { AuthContext } from "../../shared/context/authContext";

function AddPhoto({onClose,product}) {

  const [loading, setLoading] = useState(false);
  const auth = useContext(AuthContext)

  const [productImage, setProductImage] = useState("");
  const onProductImageAdd = (img) => {
    setProductImage(img);
  }

  const notify = () => toast.success("Great! Photo Added Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    var formdata = new FormData();
    formdata.append("product",product );
    formdata.append("image", productImage);
   
    
    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_URL+`sales/product/image/create/`,
      data: formdata,
      headers: {
        'bearer': auth.token,
        'user-id': auth.userId,
        'Content-Type': 'multipart/form-data'
      },
    }).then((response) => {
      console.log(response);

      setProductImage("");
      notify();
      onClose()
      setLoading(false)
    }
    )
      .catch((error) => {
        console.log('error', error.response.data)
        setLoading(false)
      })




  }

  return (
    <div className="dialoguePage">
      {loading && <Loading/>}
      <div className="dialogueBox" style={{ height: '300px',width:'700px' }}>
        <div className="head">
        <h2 style={{color:"#003cff"}}>Add Photo</h2>
        <span style={{color:"red",fontSize:"20px",cursor:"pointer"}} onClick={()=>onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
        </div>
      <form>
                <div className="user-details">
                 
                  <div className="input-box" style={{ width:'700px' }}>
                  <span className="details">Photo</span>
                    <div className="imageSelect ">
                  <ImageUpload imageAdd={onProductImageAdd} text="Select Product Photo"/>
                  </div>
                  </div>
                </div>
                
                <div className="button" >
                  <input type="submit" value="Add" onClick={handleSubmit}/>
                </div>
              </form>
      </div>
    </div>
  );
}
export default AddPhoto;
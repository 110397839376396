import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../shared/context/authContext'
import './viewUser.css'
import MainHeader from '../../common/components/MainHeader';
import { useParams } from 'react-router-dom'
import Sidebar from '../../common/components/Sidebar';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import Loading from '../../common/Loading';
import AddPhoto from '../popupForms/AddPhoto';
import capitalize from '../../common/Capitalize';
import { useNavigate } from 'react-router-dom';
import ConfirmPopUp from '../../common/components/ConfirmPopUp';

const ViewProduct = () => {

    let navigate = useNavigate()
    const [dialogue, setDialogue] = useState(false)
    const auth = useContext(AuthContext)
    const productId = useParams().productId
    const [loading, setLoading] = useState(true);
    const [newPhoto, setNewPhoto] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [product, setProduct] = useState({
        name: '',
        price: '',
        gst_percentage: '',
        weight: '',
        unit: '',
        super_market_price:'',
        wholesale_price:'',
        distributor_price:'',
        retail_price:'',
        case_count:''
    })

    const handleChange = (e) => {
        setProduct({
            ...product, [e.target.name]: e.target.value
        });
    }

    const notify = () => toast.success("Great! Product Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
    const updateShop = (e) => {
        e.preventDefault();

        const payload = {
            name: capitalize(product.name),
            price: product.price,
            gst_percentage: product.gst_percentage,
            weight: product.weight,
            unit: product.unit,
            super_market_price:product.super_market_price?product.super_market_price:0,
            wholesale_price:product.wholesale_price?product.wholesale_price:0,
            distributor_price:product.distributor_price?product.distributor_price:0,
            retail_price:product.retail_price?product.retail_price:0,
            case_count:product.case_count
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `sales/product/update/${productId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            notify();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const deleteImage = (id) => {
        console.log(id)
        axios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_URL + `sales/product/image/delete/${id}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('product', response)
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/product-details/${productId}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('product', response)
            setProduct(response.data);
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, [newPhoto, refresh]
    )

    const actionProduct = (status) => {


        const payload = {
            is_active: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `sales/product/update/${productId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }
    const openImage = (img) => {
        var top = window.screen.height - 700;
        top = top > 0 ? top / 2 : 0;

        var left = window.screen.width - 1000;
        left = left > 0 ? left / 2 : 0;

        var uploadWin = window.open(img, "location", "width=1000,height=700" + ",top=" + top + ",left=" + left);
        uploadWin.moveTo(left, top);
        uploadWin.focus();
    }
    const deleteProduct = () => {


        const payload = {
            is_delete: true
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `sales/product/update/${productId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            navigate(`/product`)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    return (
        <div>

            <ToastContainer />
            {newPhoto && <AddPhoto onClose={setNewPhoto} product={productId} />}
            <Sidebar />
            <div class="content">
                {loading && <Loading />}
                <MainHeader />
                <div class="main-container">
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Product :</span>
                        <input style={{ color: 'darkblue', fontSize: '24px', fontWeight: '700', border: 'none', padding: '5px', width: "800px" }} type="text" value={product.name} name="name" onChange={handleChange} required />
                    </h2>
                    <button className='button-31' style={{ background: 'green', color: 'white',width:'70px',height:'30px' }} onClick={updateShop}  >Save</button>
                    <button className='button-31' style={{ background: '#FF0000', color: 'white',height:'30px' }} onClick={()=>setDialogue(true)}  ><i class="fa-solid fa-trash"></i> Delete</button>
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'space-between',background:'lightgreen',marginTop:'5px', alignItems: 'center', padding: '10px'}}>
                    <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Price:</span>
                            &#8377;<input style={{ fontSize: '24px',background:'lightgreen', fontWeight: '700', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "100px" }} type="text" value={product.price} name="price" onChange={handleChange} required />
                        </h2>
                        <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Distributor Price:</span>
                            &#8377;<input style={{ fontSize: '24px', fontWeight: '700',background:'lightgreen', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "100px" }} type="text" value={product.distributor_price} name="distributor_price" onChange={handleChange} required />
                        </h2>
                        <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Wholesale Price:</span>
                            &#8377;<input style={{ fontSize: '24px', fontWeight: '700',background:'lightgreen', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "100px" }} type="text" value={product.wholesale_price} name="wholesale_price" onChange={handleChange} required />
                        </h2>
                        <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Supermarket Price:</span>
                            &#8377;<input style={{ fontSize: '24px', fontWeight: '700',background:'lightgreen', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "100px" }} type="text" value={product.super_market_price} name="super_market_price" onChange={handleChange} required />
                        </h2>
                        
                        <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Retail Price:</span>
                            &#8377;<input style={{ fontSize: '24px', fontWeight: '700',background:'lightgreen', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "100px" }} type="text" value={product.retail_price} name="retail_price" onChange={handleChange} required />
                        </h2>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', marginBottom: '10px' }}>
                        <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Weight:</span>
                            <input style={{ fontSize: '24px', fontWeight: '700', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "80px" }} type="text" value={product.weight} name="weight" onChange={handleChange} required />
                        </h2>
                        <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Case count:</span>
                            <input style={{ fontSize: '24px', fontWeight: '700', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "80px" }} type="text" value={product.case_count} name="case_count" onChange={handleChange} required />
                        </h2>
                        <div>
                            <span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Unit:</span>
                            <select style={{ width: '120px', border: 'none' }} className='dropdown' name="unit" value={product.unit} onChange={handleChange} >

                                <option value="201">Liter</option>
                                <option value="301">Gram</option>
                                <option value="401">Milliliter</option>
                                <option value="101">Kilogram</option>
                            </select></div>
                        {/* <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>Price:</span>
                            &#8377;<input style={{ fontSize: '24px', fontWeight: '700', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "100px" }} type="text" value={product.price} name="price" onChange={handleChange} required />
                        </h2> */}
                        <h2><span style={{ fontWeight: '400', fontSize: '17px', marginRight: '5px' }}>GST%:</span>
                            <input style={{ fontSize: '24px', fontWeight: '700', border: 'none', padding: '5px 4px', marginLeft: '3px', width: "40px" }} type="text" value={product.gst_percentage} name="gst_percentage" onChange={handleChange} required />
                        </h2>

                        <div><span style={{ fontWeight: '600' }}>Active </span>
                            <div className="toggle-container">

                                <div className="toggle-switch" onClick={() => actionProduct(product.is_active)} >
                                    <input readOnly type="checkbox" className="checkbox" checked={product.is_active}
                                        name='label' />
                                    <label className="label" >
                                        <span className="inner" />
                                        <span className="switch" />
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '0px' }}>
                        <div className="input-box" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                            Delivered: {product.purchase ? product.purchase.delivered.sum : ''}
                        </div>
                        <div className="input-box" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                            Pending: {product.purchase ? product.purchase.pending.sum : ''}
                        </div>
                        <div className="input-box" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                            Total Sale: {product.purchase ? product.purchase.total_sale.sum : ''}
                        </div>
                    </div>
                    <div className='user-container'>
                        {product.images && product.images.length === 0 && <p style={{ paddingLeft: '35%', paddingTop: '5%' }}>Add images using the below '<span style={{ fontWeight: '700', fontSize: '25px' }}>+</span>' button</p>}
                        {product.images && product.images.map((prdt) => (
                            <div className='user-box' key={prdt.id} >
                                <figure class="product-item" style={{ background: 'white' }}>
                                    <div class="product-image">
                                        {/* <a href={prdt.image} target="_blank"> */}
                                            <img src={prdt.image} onClick={() => openImage(prdt.image)} />
                                        {/* </a> */}
                                    </div>
                                    <button onClick={() => deleteImage(prdt.id)} style={{ width: '70%', padding: ' 5px', marginBottom: '5px', border: '2px solid red', borderRadius: '3px' }}><i style={{ color: 'red' }} class="fa-solid fa-trash"></i></button>
                                </figure>
                            </div>
                        ))}

                    </div>
                </div>
                <div className='circle' onClick={() => setNewPhoto(true)}><i class="fa-solid fa-plus"></i></div>
            </div>
            {dialogue && <ConfirmPopUp deleteText='Delete' onDialog={setDialogue} onAction={deleteProduct} />}
        </div>
    )
}
export default ViewProduct
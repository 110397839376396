import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import { ToastContainer } from 'react-toastify';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import AddOrder from './popupForms/AddOrder';
import UpdateOrder from './popupForms/UpdateOrder';
import OrderApprove from './popupForms/OrderApprove';
import { getCurrentDate } from '../common/GetCurrentDate';
import { useNavigate } from 'react-router-dom';
import Loading from '../common/Loading';
import ConfirmPopUp from '../common/components/ConfirmPopUp';


const Order = () => {

    const [dialogue, setDialogue] = useState(false)
    const [orderToDelete, setOrderToDelete] = useState()

    let navigate = useNavigate()
    const auth = useContext(AuthContext)
    const [msg, setMsg] = useState()
    const [loading, setLoading] = useState(true);
    const [approveForm, setApproveForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);
    const [newOrder, setNewOrder] = useState(false)
    const [order, setOrder] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [idForApproval, setIdForApproval] = useState("");
    const [approval, setApproval] = useState(false);
    const [status, setStatus] = useState(localStorage.getItem('statusNum') || '101')
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();
    const [dateOrdering, setDateOrdering] = useState(localStorage.getItem('dateOrderingForOrder') || '');
    const [dateSearch, setDateSearch] = useState('null');
    const [dateValue, setDateValue] = useState();
    const [dateForSearch, setDateForSearch] = useState();
    const [refresh, setRefresh] = useState(false);

    const [dateField, setDateField] = useState(localStorage.getItem('status') || 'date')

    const [filterDate, setFilterDate] = useState({
        // date: getCurrentDate(),
        // date_lt: '',
        // date_gt: '',
        // delivered_date: '',
        // delivered_date_lt: "",
        // delivered_date_gt: "",
        executive: '',
        salesman: '',
        route: ''
    })
    const [dropDownRoute, setDropDownRoute] = useState([])
    const [routeSearch, setRouteSearch] = useState('')
    const [showRoute, setShowRoute] = useState(false)
    const [valueRoute, setValueRoute] = useState("Select a Route")
    const handleToggleRoute = (e) => {
        e.target.focus();
        setShowRoute(!showRoute);
    }
    const handleChangeRoute = (id, name) => {
        setFilterDate({
            ...filterDate, route: id
        });
        setValueRoute(name)
        setShowRoute(false)
    }

    const [dropDownSalesman, setDropDownSalesman] = useState([])
    const [salesmanSearch, setSalesmanSearch] = useState('')
    const [showSalesman, setShowSalesman] = useState(false)
    const [valueSalesman, setValueSalesman] = useState("Select a Salesman")
    const handleToggleSalesman = (e) => {
        e.target.focus();
        setShowSalesman(!showSalesman);
    }
    const handleChangeSalesman = (id, name) => {
        setFilterDate({
            ...filterDate, salesman: id
        });
        setValueSalesman(name)
        setShowSalesman(false)
    }

    const [dropDownExecutive, setDropDownExecutive] = useState([])
    const [executiveSearch, setExecutiveSearch] = useState('')
    const [showExecutive, setShowExecutive] = useState(false)
    const [valueExecutive, setValueExecutive] = useState("Select an Executive")
    const handleToggleExecutive = (e) => {
        e.target.focus();
        setShowExecutive(!showExecutive);
    }
    const handleChangeExecutive = (id, name) => {
        setFilterDate({
            ...filterDate, executive: id
        });
        setValueExecutive(name)
        setShowExecutive(false)
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/model/list/?model=route&limit=1000&offset=0&name=${routeSearch}&type=201`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('route for dropdown', response.data.results)
            setDropDownRoute(response.data.results)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [routeSearch])
    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/model/list/?model=user&limit=1000&offset=0&name=${executiveSearch}&type=201`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('executive for dropdown', response.data.results)
            setDropDownExecutive(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [executiveSearch])

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/model/list/?model=user&limit=1000&offset=0&name=${salesmanSearch}&type=301`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('executive for dropdown', response.data.results)
            setDropDownSalesman(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [salesmanSearch])

    const [filterOpen, setFilterOpen] = useState(false)

    let notify;

    const showApproveForm = (e, id) => {
        e.stopPropagation()
        e.preventDefault();
        setApproveForm(true)
        setIdForApproval(id)
        notify = () => toast.success(`Order Approved Successfully`, { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
    }
    const showUpdateForm = (e, id) => {
        e.stopPropagation()
        setUpdateForm(true)
        setIdForApproval(id)
    }
    const actionDelete = (e, orderId) => {
        e.stopPropagation()
        e.preventDefault();

        setOrderToDelete(orderId)
        setDialogue(true)
    }

    const forReject = (id) => {

        notify = () => toast.success(`Order Rejected Successfully`, { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
        const payload = {
            status: '401'
        }
        actionOrder(id, payload)

    }

    const actionOrder = (id, payload) => {

        console.log(id, payload)

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `sales/order/update/${id}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setApproval(!approval)
            setApproveForm(false)
            notify();
        }
        )
            .catch((error) => {
                console.log('error', error.response)

            })
    }
    const formHandler = () => {
        setNewOrder(!newOrder);
    }

    useEffect(() => {
        setCountA(1);
        setLoading(true)
        const storedOrderUrl = localStorage.getItem('orderTrackUrl');
        const storedOrderData = JSON.parse(localStorage.getItem('orderPageDetails'));
        if (storedOrderData && storedOrderUrl) {
            setCountA(storedOrderData.countA)
            setCountB(storedOrderData.countB)

            axios({
                method: 'get',
                url: storedOrderUrl,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                console.log(response)
                setOrder(response.data.results);
                setNext(response.data.next);
                setPrevious(response.data.previous);
                setCount(response.data.count)
                setLoading(false)
                setPrevCountB(response.data.results.length)
            }
            )
                .catch((error) => {
                    console.log('error', error.response.data)

                })
        }

        else {
            axios({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + `sales/order/list/?route=${filterDate.route ? filterDate.route : ''}&executive=${filterDate.executive ? filterDate.executive : ''}&salesman=${filterDate.salesman ? filterDate.salesman : ''}&${dateField}=${getCurrentDate()}&status=${status}&ordering=${dateOrdering}&${dateSearch}=${dateForSearch}`,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {

                console.log('order', response)
                setOrder(response.data.results);
                setNext(response.data.next);
                setPrevious(response.data.previous);
                setLoading(false)
                setCount(response.data.count)
                setCountB(response.data.results.length)
            }
            )
                .catch((error) => {
                    console.log('error', error.response.data)

                })
        }
    }, [status, filterDate, approval, dateField, dateForSearch, refresh])

    const onSwitchPage = (url, action) => {
        localStorage.removeItem('orderPageDetails')
        localStorage.setItem('orderTrackUrl', url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setOrder(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)


            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }
    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ' ' + month + ' ' + year;
        return time;
    }

    const clearFilter = () => {
        setFilterOpen(!filterOpen)
        setFilterDate({
            ...filterDate, executive: '', salesman: '', route: ''
        });
        setValueExecutive('Select an Executive')
        setValueSalesman('Select a Salesman')
        setValueRoute('Select a Route')

    }
    const changeDate = (e) => {
        setDateSearch('date')
        let selectedDate = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
        setDateForSearch(selectedDate)
        setDateValue(e.target.value)
    }

    return (
        <div className='full'>

            {filterOpen &&
                <div style={{ paddingRight: '20px', position: 'fixed', top: '125px', right: '38px', height: '220px', width: '300px', backgroundColor: 'white', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}>
                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                        <span style={{ marginRight: '10px' }} >Executive : </span>
                        <div >
                            <label className="filter-arrow">
                                <textarea
                                    readOnly
                                    type="button"
                                    value={valueExecutive}
                                    className='filter-dropdown'
                                    onClick={handleToggleExecutive}
                                />
                            </label>
                            <div hidden={!showExecutive} className='filter-dropdown-list'>
                                <input className='filter-search' type='text' placeholder="Type to Search..." onChange={(e) => setExecutiveSearch(e.target.value)} />
                                <ul   >
                                    {/* <li className="option" onClick={() => handleChangeExecutive(null,'Select Executive')}></li> */}

                                    {dropDownExecutive.map((DExecutive) => (
                                        <li className="option" onClick={() => handleChangeExecutive(DExecutive.id, DExecutive.full_name)} key={DExecutive.id}>{DExecutive.full_name} </li>

                                    ))}



                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                        <span style={{ marginRight: '10px' }}>Salesman : </span>
                        <div >
                            <label className="filter-arrow">
                                <textarea
                                    readOnly
                                    type="button"
                                    value={valueSalesman}
                                    className='filter-dropdown'
                                    onClick={handleToggleSalesman}
                                />
                            </label>
                            <div hidden={!showSalesman} className='filter-dropdown-list' >
                                <input type='text' className='filter-search' placeholder="Type to Search..." onChange={(e) => setSalesmanSearch(e.target.value)} />
                                <ul   >

                                    {dropDownSalesman.map((DExecutive) => (
                                        <li className="option" onClick={() => handleChangeSalesman(DExecutive.id, DExecutive.full_name)} key={DExecutive.id}>{DExecutive.full_name} </li>

                                    ))}



                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                        <span style={{ marginRight: '10px' }}>  Route : </span>
                        <div >
                            <label className="filter-arrow">
                                <textarea
                                    readOnly
                                    type="button"
                                    value={valueRoute}
                                    className='filter-dropdown'
                                    onClick={handleToggleRoute}
                                />
                            </label>
                            <div hidden={!showRoute} className='filter-dropdown-list' >
                                <input type='text' className='filter-search' placeholder="Type to Search..." onChange={(e) => setRouteSearch(e.target.value)} />
                                <ul   >

                                    {dropDownRoute.map((DRoute) => (
                                        <li className="option" onClick={() => handleChangeRoute(DRoute.id, DRoute.name)} key={DRoute.id}>{DRoute.name} </li>

                                    ))}



                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '10px' }}>
                        <button onClick={clearFilter} style={{ background: 'grey', color: 'white', padding: '4px 10px', borderRadius: '5px', borderColor: 'grey', marginRight: '0px' }}>Clear Filter</button>
                    </div>
                </div>}
            {approveForm && <OrderApprove onClose={setApproveForm} orderId={idForApproval} approveOrder={actionOrder} />}
            {updateForm && <UpdateOrder onClose={setUpdateForm} id={idForApproval} refreshPage={() => setRefresh(!refresh)} />}
            {newOrder && <AddOrder onClose={formHandler} refreshPage={() => setRefresh(!refresh)} />}
            <Sidebar name="order" />
            <ToastContainer />
            <div class="content">
                {loading && <Loading />}
                <MainHeader />

                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginBottom: "10px" }}>Order Details</h3>

                        <button className='new-button' onClick={formHandler}>New</button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <div class="tabs" style={{ marginLeft: '0px' }}>
                            <div class={status === '101' ? "activetab" : "tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setStatus('101'); setDateField('date'); setCountA(1); localStorage.setItem('status', 'date'); localStorage.setItem('statusNum', '101'); localStorage.removeItem('orderTrackUrl') }}>Orders </div>
                            <div class={status === '201' ? "activetab" : "tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setStatus('201'); setDateField('delivered_date'); setCountA(1); localStorage.setItem('status', 'delivered_date');; localStorage.setItem('statusNum', '201'); localStorage.removeItem('orderTrackUrl') }}>Delivery </div>
                            <div class={status === '301' ? "activetab" : "tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setStatus('301'); setDateField('null'); setCountA(1); setDateOrdering('-date'); localStorage.setItem('status', 'null'); localStorage.setItem('dateOrderingForOrder', '-date'); localStorage.setItem('statusNum', '301'); localStorage.removeItem('orderTrackUrl') }}>Completed </div>
                            <div class={status === '401' ? "activetab" : "tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setStatus('401'); setDateField('null'); setCountA(1); setDateOrdering('-date'); localStorage.setItem('status', 'null'); localStorage.setItem('dateOrderingForOrder', '-date'); localStorage.setItem('statusNum', '401'); localStorage.removeItem('orderTrackUrl') }}>Cancelled </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                            <div class="search">
                                <input type="date" value={dateValue} onChange={changeDate} />
                            </div>
                            <button className='filter' onClick={() => setFilterOpen(!filterOpen)}>
                                <i class="fa-solid fa-filter"></i> Filters
                            </button>
                        </div>
                    </div>

                    <div class="tables">
                        {status === '101' &&
                            <div >
                                <button class={dateField === 'date_lt' ? "active-filter-tab" : "filter-tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setDateField('date_lt'); setCountA(1); setDateOrdering('-date'); localStorage.setItem('dateOrderingForOrder', '-date'); localStorage.setItem('status', 'date_lt'); localStorage.removeItem('orderTrackUrl') }}>Executed </button>
                                <button class={dateField === 'date' ? "active-filter-tab" : "filter-tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setDateField('date'); setCountA(1); localStorage.setItem('status', 'date'); localStorage.removeItem('orderTrackUrl') }}>Ongoing </button>
                                <button class={dateField === 'date_gt' ? "active-filter-tab" : "filter-tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setDateField('date_gt'); setCountA(1); setDateOrdering('date'); localStorage.setItem('dateOrderingForOrder', 'date'); localStorage.setItem('status', 'date_gt'); localStorage.removeItem('orderTrackUrl') }}>Upcoming</button>
                            </div>
                        }
                        {status === '201' &&
                            <div >
                                <button class={dateField === 'delivered_date_lt' ? "active-filter-tab" : "filter-tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setDateField('delivered_date_lt'); setCountA(1); setDateOrdering('-delivered_date'); localStorage.setItem('dateOrderingForOrder', '-delivered_date'); localStorage.setItem('status', 'delivered_date_lt'); localStorage.removeItem('orderTrackUrl') }}>Executed </button>
                                <button class={dateField === 'delivered_date' ? "active-filter-tab" : "filter-tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setDateField('delivered_date'); setCountA(1); localStorage.setItem('status', 'delivered_date'); localStorage.removeItem('orderTrackUrl') }}>Ongoing </button>
                                <button class={dateField === 'delivered_date_gt' ? "active-filter-tab" : "filter-tab"} onClick={() => { setDateValue(''); setDateSearch('null'); setDateField('delivered_date_gt'); setCountA(1); setDateOrdering('delivered_date'); localStorage.setItem('dateOrderingForOrder', 'delivered_date'); localStorage.setItem('status', 'delivered_date_gt'); localStorage.removeItem('orderTrackUrl') }}>Upcoming</button>
                            </div>
                        }
                        <div class="table-content">

                            <table >
                                <thead>
                                    <td>Route</td>
                                    {(status === '101' || status === '301') && <td>Executive</td>}
                                    {(status === '201' || status === '301') && <td>Salesman</td>}
                                    <td>Order Date</td>
                                    {status === '101' && <td>Action</td>}
                                    {(status === '201' || status === '301') && <td>Delivery Date</td>}

                                </thead>
                                {!loading &&
                                    <tbody>
                                        {order.map((order) => (
                                            <tr key={order.id} onClick={() => { ((dateField === 'date_gt' && order.type === 101) || status === '401') ? console.log('hai') : navigate(`/order/${order.id}`); localStorage.setItem('orderPageDetails', JSON.stringify({ 'countA': countA, 'countB': countB, 'count': count })) }}>
                                                <td>{order.route ? order.route.name : 'No Route'}</td>
                                                {(status === '101' || status === '301') && <td>{order.executive.full_name}</td>}
                                                {(status === '201' || status === '301') && <td>{order.salesman ? order.salesman.full_name : ''}</td>}
                                                <td>{timeConverter(order.date)}</td>
                                                {(status === '201' || status === '301') && <td style={{ color: order.delivered_date ? "" : 'red' }}>{order.type === 101 ? (order.delivered_date ? timeConverter(order.delivered_date) : 'empty order') : ''}</td>}
                                                {status === '101' &&
                                                    order.type === 201 ? <td></td> :
                                                    <td>{(dateField === 'date_lt' || dateField === 'date') && <div>
                                                        {order.info.unverified > 0 ? <div className='if-unverified-shop'>
                                                            <button className='button-31' style={{ border: '1px solid grey', padding: '2px 35px', background: 'white' }} onMouseLeave={() => setMsg()} onMouseEnter={() => setMsg(order.id)}><i style={{ color: 'red' }} class="fa-solid fa-question"></i></button>
                                                            {msg === order.id && <div className='unverified-msg'><span style={{ fontWeight: 'bold' }}>{order.info.unverified}</span> unverified shops in this order.</div>}
                                                        </div> : <div>
                                                            <button disabled={!(order.info.bill_count > 0)} className='button-31' style={{ padding: '2px 10px', background: 'green' }} onClick={(e) => showApproveForm(e, order.id)}><i style={{ color: 'white' }} class="fa-sharp fa-solid fa-check"></i></button>




                                                            <button className='button-31' style={{ padding: '2px 10px', background: 'rgb(212, 7, 7)', marginLeft: '5px' }} onClick={(e) => actionDelete(e, order.id)} ><i style={{ color: 'white' }} class="fa-sharp fa-solid fa-close"></i></button>

                                                            {dateField === 'date' &&
                                                                <button className='button-31' style={{ border: '1px solid grey', padding: '2px 15px', background: 'white', marginLeft: '5px' }} onClick={(e) => showUpdateForm(e, order.id)}><i style={{ color: 'darkblue' }} class="fa-solid fa-pen"></i></button>
                                                            }
                                                        </div>}</div>}
                                                        {dateField === 'date_gt' &&
                                                            <div>
                                                                <button className='button-31' style={{ border: '1px solid grey', padding: '2px 15px', background: 'white' }} onClick={(e) => showUpdateForm(e, order.id)}><i style={{ color: 'darkblue' }} class="fa-solid fa-pen"></i></button>
                                                            </div>}
                                                    </td>}
                                            </tr>
                                        ))}

                                    </tbody>}
                            </table>
                        </div>
                        <div className="switchbutton">

                            {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {order.length !== 0 && <div style={{ paddingLeft: '30px' }}>Showing {countA}-{countB} of {count}</div>}
                        </div>
                    </div>
                </div>
            </div>

            {dialogue && <ConfirmPopUp onDialog={setDialogue} onAction={() => forReject(orderToDelete)} />}
        </div>

    )
}
export default Order;
import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import { useNavigate} from 'react-router-dom';
import Loading from '../common/Loading';
import timeConverter from '../common/TimeConverter';
import Dropdown from '../common/components/DropDown';


const Bill = () => {

    const [filterOpen, setFilterOpen] = useState(false)
    let navigate=useNavigate()
    const auth = useContext(AuthContext)
    const [bill, setBill] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [creator, setCreator] = useState('')
    const [dropDownUser, setDropDownUser] = useState([])
    const [userSearch, setUserSearch] = useState('')
    const [shop, setShop] = useState('')
    const [dropDownShop, setDropDownShop] = useState([])
    const [shopSearch, setShopSearch] = useState('')
    const [date, setDate] = useState();
    const [forDate, setForDate] = useState('');
    const [filter,setFilter]=useState(false)
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();
    const [searchBill, setSearchBill] = useState("");

    const changeDate =(e)=>{
        let selectedDate = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
        setForDate(selectedDate);
        setDate(e.target.value)
    }

    useEffect(() => {
        setCountA(1)
        const storedBillUrl = localStorage.getItem('billTrackUrl');
        const storedBillData = JSON.parse(localStorage.getItem('billPageDetails'));
        if (storedBillData && storedBillUrl) {
          setCountA(storedBillData.countA)
          setCountB(storedBillData.countB) 

            axios({
                method: 'get',
                url: storedBillUrl,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                console.log(response)
                setBill(response.data.results);
                setNext(response.data.next);
                setCount(response.data.count)
                setPrevious(response.data.previous);
                setLoading(false)
                setPrevCountB(response.data.results.length)
            }
            )
                .catch((error) => {
                    console.log('error', error.response.data)
    
                })
        }


        else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/bill/list/?order=&shop=${shop}&date=${forDate}&user=${creator}&search=${searchBill}&ordering=-created_on`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('bill', response)
            setBill(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }}, [filter,searchBill,refresh])

    const downloadBill = (e,billId,num,shop) => {
        e.stopPropagation()
        axios({
            method: 'get',
            url:process.env.REACT_APP_BACKEND_URL+`sales/bill/download/${billId}/`,
            responseType: 'blob',
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/docx'
            },
        }).then((response) => {
            console.log(response)
            setRefresh(!refresh);
            const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${shop}-${num}.docx`);
    document.body.appendChild(link);
    link.click();
            
        }
        )
            .catch((error) => {
                console.log('error', error)

            })
            
    }

    const onSwitchPage = (url,action) => {
       
        localStorage.setItem('billTrackUrl',url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setBill(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)
                localStorage.setItem('billPageDetails',JSON.stringify({'countA':countA+10,'countB':countB+response.data.results.length,'count':count}))
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
                localStorage.setItem('billPageDetails',JSON.stringify({'countA':countA-10,'countB':countB- prevCountB,'count':count}))
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }
    const deliveryStatus = (status) => {
        if (status === 101) {
            return 'Pending'
        }
        else if (status === 201) {
            return 'Delivered'
        }
        else if (status === 301) {
            return 'Cancelled'
        }
    }



    
    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&type=201`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('user for dropdown', response.data.results)
            setDropDownUser(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=shop&limit=1000&offset=0&name=${shopSearch}&ordering=name`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop for dropdown', response.data.results)
            setDropDownShop(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [shopSearch])

    const applyFilter=()=>{
        setFilter(!filter)
        setFilterOpen(!filterOpen)
        setCountA(1)
    }
    const clearFilter=()=>{
        setFilter(!filter)
        setFilterOpen(!filterOpen)
        setCreator('')
        setForDate('')
        setShop('')
        setCountA(1)
    }


    return (
        <div className='full'>
         
            <Sidebar name="bill"/>
            <div class="content">
            {loading && <Loading/>}
                <MainHeader />

                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginBottom: "10px" }}>Bill Details</h3>
                        <div class="search" onClick={() => { localStorage.removeItem('billTrackUrl'); localStorage.removeItem('billPageDetails') }}>
                            <input type="text" placeholder="search here..." onChange={(e)=>setSearchBill(e.target.value)}/>
                            <label for="search"><i class="fas fa-search"></i></label>
                        </div>
                        
                        <div onClick={() => { localStorage.removeItem('billTrackUrl'); localStorage.removeItem('billPageDetails') }}><button className='filter'    onClick={() => setFilterOpen(!filterOpen)}>
                                <i class="fa-solid fa-filter"></i> Filters
                            </button></div>
                    </div>
                    <div class="tables">
                        <div class="table-content">
                            <table >
                                <thead>
                                    
                                    <td>Shop</td>
                                    <td>Executive Name</td>
                                    <td>Bill No.</td>
                                    <td>Delivered Date</td>
                                    <td>Generated On</td>
                                    <td>Total Amount</td>
                                    
                                    <td>Action</td>
                                    

                                </thead>
                                <tbody>
                                {bill.map((bill) => (
                                    <tr key={bill.id} onClick={()=>{navigate(`/bill/${bill.id}`)}}>
                                        
                                        <td><span style={{background:bill.is_active?"green":'red'}} className='dot'></span> {bill.shop.name}</td>
                                        <td>{bill.executive.full_name}</td>
                                        <td style={{color:!bill.bill_number && 'red'}}>{bill.bill_number?bill.bill_number:'not generated'}</td>
                                        <td style={{color:bill.delivered_on?'blue':'darkorange'}}>{bill.delivered_on ? timeConverter(bill.delivered_on) : deliveryStatus(bill.delivery)}</td>
                                        <td>{timeConverter(bill.created_on)}</td>
                                        <td>&#8377; {bill.total_amount}</td>
                                        <td >
                                            <button className='button-31' style={{background:'darkblue',padding:'2px 10px'}} disabled={!bill.download_button} onClick={(e)=>downloadBill(e,bill.id,timeConverter(bill.created_on),bill.shop.name)}>
                                                <i style={{color:'white'}} class="fa-solid fa-file-export"></i>
                                                </button></td>
                                    </tr>
                        ))}

                                </tbody>
                            </table>
                        </div>
                        <div className="switchbutton">
                               
                                {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {bill.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                            </div>
                    </div>
                </div>
            </div>

            {filterOpen &&
                <div style={{ paddingRight: '20px', position: 'fixed', top: '80px', right: '38px', height: '210px', width: '300px', backgroundColor: 'white', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}>
                  
                   <Dropdown dropdownFor='User' dropdownElements={dropDownUser} setId={setCreator} dropdownSearch={setUserSearch} />
                   <Dropdown dropdownFor='Shop' dropdownElements={dropDownShop} setId={setShop} dropdownSearch={setShopSearch} />
                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}>Date : </span>
                    <input className='filter-dropdown' type='date' style={{width:'62%'}} value={date} onChange={changeDate}/>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '10px' }}>
                        <button onClick={clearFilter} style={{background:'grey',color:'white',padding:'4px 10px',borderRadius:'5px',borderColor:'grey',marginRight: '20px' }}>Clear</button>
                        <button onClick={applyFilter} style={{background:'blue',color:'white',padding:'4px 10px',borderRadius:'5px',borderColor:'blue'}}>Apply</button>
                        </div>
                </div>}

        </div>

    )
}
export default Bill;
import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import AddRoute from './popupForms/AddRoute';
import Loading from '../common/Loading';
import { useNavigate} from 'react-router-dom';


const SaleRoute = () => {

    const auth = useContext(AuthContext)
    let navigate=useNavigate()

    const [searchRoute, setSearchRoute] = useState("");
    const [loading, setLoading] = useState(true);
    const [newRoute, setNewRoute] = useState(false)
    const [route, setRoute] = useState([])
    const [reLoad, setReLoad] = useState(true);
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();

    const formHandler = () => {
        setNewRoute(!newRoute);
    }
    useEffect(() => {
        setCountA(1)
        const storedRouteUrl = localStorage.getItem('routeTrackUrl');
        const storedRouteData = JSON.parse(localStorage.getItem('routePageDetails'));
        if (storedRouteData && storedRouteUrl) {
          setCountA(storedRouteData.countA)
          setCountB(storedRouteData.countB) 

            axios({
                method: 'get',
                url: storedRouteUrl,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                console.log(response)
                setRoute(response.data.results);
                setNext(response.data.next);
                setCount(response.data.count)
                setPrevious(response.data.previous);
                setLoading(false)
                setPrevCountB(response.data.results.length)
            }
            )
                .catch((error) => {
                    console.log('error', error.response.data)
    
                })
        }
        else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/route/list/?name=${searchRoute}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('route', response)
            setRoute(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }}, [searchRoute,reLoad])

    const onSwitchPage = (url,action) => {
        localStorage.removeItem('routePageDetails')
        localStorage.setItem('routeTrackUrl',url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setRoute(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    return (
        <div className='full'>
           
             {newRoute && <AddRoute onClose={formHandler} forReload={()=>setReLoad(!reLoad)}/>}
            <Sidebar name="route"/>
            <ToastContainer />
            <div class="content">
            {loading && <Loading/>}
                <MainHeader />

                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082",marginBottom: "10px" }}>Route Details</h3>
                        <div class="search" onClick={()=>localStorage.removeItem('routeTrackUrl')}>
                            <input type="text" placeholder="search here..." onChange={(e)=>setSearchRoute(e.target.value)} />
                            <label for="search"><i class="fas fa-search"></i></label>
                        </div>
                        <button className='button-31' onClick={formHandler}>New</button>
                    </div>
                    <div class="tables">
                        <div class="table-content">
                            <table >
                                <thead>
                                    <td>Name</td>
                                    <td>Start</td>
                                    <td>End</td>
                                    

                                </thead>
                                <tbody>
                                {route.map((route) => (
                                    <tr key={route.id} onClick={()=>{navigate(`/route/${route.id}`);localStorage.setItem('routePageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}));localStorage.removeItem('viewRouteShopTrackUrl')}}>
                                        
                                        <td>{route.name}</td>
                                        <td>{route.start}</td>
                                        <td>{route.end}</td>
                                     
                                    </tr>
                        ))}

                                </tbody>
                            </table>
                        </div>
                        <div className="switchbutton">
                                
                                {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {route.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                            </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default SaleRoute;
import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function AddCredit({ onClose, forReload }) {

    const todaysDate = () =>{
        var today,dd,mm,yy;
        today=new Date()
        dd = today.getDate();
        mm = today.getMonth()+1
        yy = today.getFullYear()
        dd<10?dd='0'+dd:dd=dd
        mm<10?mm='0'+mm:mm=mm
        return yy+'-'+mm+'-'+dd
    }
    const forDueDate = () =>{
        var myDate,dd,mm,yy;
        myDate = new Date(new Date().getTime()+(16*24*60*60*1000));
        dd = myDate.getDate();
        mm = myDate.getMonth()+1
        yy = myDate.getFullYear()
        dd<10?dd='0'+dd:dd=dd
        mm<10?mm='0'+mm:mm=mm
        return yy+'-'+mm+'-'+dd
    }
    var myDate = new Date(new Date().getTime()+(5*24*60*60*1000));

    const auth = useContext(AuthContext)
    const [credit, setCredit] = useState({

        customer: "",
        amount_allotted: null,
        date_allotted: todaysDate(),
        due_date: forDueDate(),
        note: ""
    });
    const [error, setError] = useState([])
    const [dropDownUser, setDropDownUser] = useState([])
    const [userSearch, setUserSearch] = useState('')
    const [value, setValue] = useState("Select a User")
    const [show, setShow] = useState(false)

    const notify = () => toast.success("Credit Created Successfully!", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setCredit({
            ...credit, [e.target.name]: e.target.value
        });
    }

    const handleToggle = (e) => {
        e.target.focus();
        setShow(!show);
    }

    const handleChangeUser = (id, name) => {
        setCredit({
            ...credit, customer: id
        });
        setValue(name)
        setShow(false)
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('user for dropdown', response.data.results)
            setDropDownUser(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(credit)

        const payload = {
            customer: credit.customer,
            amount_allotted: credit.amount_allotted,
            date_allotted: parseInt((new Date(credit.date_allotted).getTime() / 1000).toFixed(0)),
            due_date:  parseInt((new Date(credit.due_date).getTime() / 1000).toFixed(0)),
            note: credit.note
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `credits/credit-allotments/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setCredit(
                {
                    customer: "",
                    amount_allotted: null,
                    date_allotted: '',
                    due_date: '',
                    note: ""
                }
            )
            notify();
            onClose()
            forReload();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)
            })




    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '450px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Create Credit</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}> <span className="details">User*</span><span style={{ color: 'red', fontWeight: '400' }}>{error.customer ? error.customer : ''}</span></div>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={value}
                                        className="dropdown"
                                        onClick={handleToggle}
                                    />
                                </label>
                                <div hidden={!show} className="dropdown-list" >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setUserSearch(e.target.value)} style={{ position: 'fixed', width: '406px' }} />
                                    <ul style={{ paddingTop: '40px' }}  >
                                        {dropDownUser.map((DUser) => (
                                            <li className="option" onClick={() => handleChangeUser(DUser.id, DUser.full_name)} key={DUser.id}>{DUser.full_name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="input-box">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}><span className="details">Amount Allotted*</span><span style={{ color: 'red', fontWeight: '400' }}>{error.amount_allotted ? error.amount_allotted : ''}</span></div>
                            <input type="number" placeholder="Enter Amount" name="amount_allotted" value={credit.amount_allotted} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Date Allotted</span><span style={{color:'red',fontWeight:'400'}}>{error.date_allotted?error.date_allotted:''}</span></div>
                            <input type="date"  placeholder="Enter date alotted" name="date_allotted" value={credit.date_allotted} onChange={handleChange} required />
                        </div>
                    
                    
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Due Date</span><span style={{color:'red',fontWeight:'400'}}>{error.due_date?error.due_date:''}</span></div>
                            <input type="date"  placeholder="Enter due date" name="due_date" value={credit.due_date} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Note*</span><span style={{color:'red',fontWeight:'400'}}>{error.note?error.note:''}</span></div>
                            <input type="text" placeholder="Enter Note" name="note" value={credit.note} onChange={handleChange} required />
                        </div>
                    </div>
                    {/* <center><span style={{ color: "red" }}>{error}</span></center> */}
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddCredit;
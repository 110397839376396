import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function EditOrderDate({ onClose, odrId, forRefresh }) {

    const auth = useContext(AuthContext)
    const [error, setError] = useState()
    const [deliveryDate, setDeliveryDate] = useState(false)

    const disabledDate = () =>{
        var today,dd,mm,yy;
        today=new Date()
        dd = today.getDate();
        mm = today.getMonth()+1
        yy = today.getFullYear()
        dd<10?dd='0'+dd:dd=dd
        mm<10?mm='0'+mm:mm=mm
        return yy+'-'+mm+'-'+dd
    }
 

    const notify = () => toast.success("Great! Order Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            date: parseInt((new Date(deliveryDate).getTime() / 1000).toFixed(0))
        }
        
        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/order/update/${odrId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            forRefresh()
            notify();
            onClose('')
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px', width: '700px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Select Order Date</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >


                        <div className="input-box" style={{ width: '700px' }}>


                       
                            <span className="details"></span>
                            <input type="date" min={disabledDate()} name="delivered_date"  onChange={(e)=>setDeliveryDate(e.target.value)} required />
                   
                          

                        </div>
                    </div>

                    <center><span style={{ color: "red" }}>{error}</span></center>
                    <div className="button" >
                        <input type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default EditOrderDate;
import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import AddCredit from './popupForms/AddCredit';
import Loading from '../common/Loading';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from '../common/components/DropDown';
import AddCreditPayment from './popupForms/AddCreditPayment';
import ShowChildPayment from './popupForms/ShowChildPayment';
import ConfirmPopUp from '../common/components/ConfirmPopUp';
import { toast } from "react-toastify";
import EditCredit from './popupForms/EditCredit';



const Credit = () => {

    const [creditId, setCreditId] = useState(null)
    const [paymentId, setPaymentId] = useState(null)
    const [note, setNote] = useState(null)
    const [childPayments, setChildPayments] = useState(null)
    const [dialogue, setDialogue] = useState(false)
    const [deleteCreditId, setDeleteCreditId] = useState()
    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState(true);
    const [newCredit, setNewCredit] = useState(false)
    const [credit, setCredit] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [reLoad, setReLoad] = useState(true);
    const [creator, setCreator] = useState('')
    const [dropDownUser, setDropDownUser] = useState([])
    const [userSearch, setUserSearch] = useState('')


    const [dueDatePassed, setDueDatePassed] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();

    const formHandler = () => {
        setNewCredit(!newCredit);
    }

    useEffect(() => {
        setCountA(1)
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `credits/credit-allotments/?customer=${creator}&completed=${completed}&due_date_passed=${dueDatePassed}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('credit', response)
            setCredit(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, [ reLoad, creator,completed,dueDatePassed])

    const onSwitchPage = (url, action) => {
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setCredit(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)


            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('user for dropdown', response.data.results)
            setDropDownUser(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])

    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ' ' + month + ' ' + year;
        return time;
    }
    const deleteCredit = () => {
        let notify = () => toast.success("Great! Credit deleted Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

        axios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_URL + `credits/credit-allotments/${deleteCreditId}/`,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setReLoad(!reLoad)
            })

    }


    return (
        <div className='full'>
            {creditId!==null && <EditCredit creditId={creditId} onClose={setCreditId} forReLoad={()=>setReLoad(!reLoad)}/>}
            {childPayments !== null && <ShowChildPayment payments={childPayments} forReLoad={()=>setReLoad(!reLoad)} note={note} onClose={() => setChildPayments(null)} />}
            {paymentId !== null && <AddCreditPayment creditId={paymentId} onClose={() => setPaymentId(null)} forReload={() => setReLoad(!reLoad)} />}
            {newCredit && <AddCredit onClose={formHandler} forReload={() => setReLoad(!reLoad)} />}
            <Sidebar name="credit" />
            <ToastContainer />
            <div class="content">
                {loading && <Loading />}
                <MainHeader />

                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginBottom: "10px" }}>Credit Details</h3>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center",marginTop: '12px',marginRight:'15px',background:'yellow',padding:'5px',borderRadius:'2px' }}>Due Date Passed : 
                                <input style={{marginLeft:'5px'}} type="radio" class="radio" onChange={()=>setDueDatePassed(true)} checked={dueDatePassed?true:false} />
                                <label>True</label>
                                <input style={{marginLeft:'5px'}} type="radio" class="radio" onChange={()=>setDueDatePassed(false)} checked={!dueDatePassed?true:false} />
                                <label>False</label>
                            </div>
                            <div style={{ display: "flex", alignItems: "center",marginTop: '12px',marginRight:'15px',background:'lightgreen',padding:'5px',borderRadius:'2px' }}>Completed : 
                                <input style={{marginLeft:'5px'}} type="radio" class="radio" onChange={()=>setCompleted(true)} checked={completed?true:false} name="completed" value="true" id="completed" />
                                <label>True</label>
                                <input style={{marginLeft:'5px'}} type="radio" class="radio" onChange={()=>setCompleted(false)} checked={!completed?true:false} name="completed" value="false" id="completed" />
                                <label>False</label>
                            </div>
                            <Dropdown dropdownFor='User' dropdownElements={dropDownUser} setId={setCreator} dropdownSearch={setUserSearch} />
                            <button style={{ marginTop: '12px',marginLeft:'15px' }} className='button-31' onClick={formHandler}>New</button>
                        </div>
                    </div>

                    <div class="tables">
                        <div class="table-content">
                            <table >
                                <thead>
                                    <td>Employee</td>
                                    <td>Amount Allotted</td>
                                    <td>Date Allotted</td>
                                    <td>Due Date</td>
                                    <td>Paid Amount</td>
                                    <td>Balance Amount</td>


                                    <td>Actions</td>


                                </thead>
                                <tbody>
                                    {credit.map((credit) => (
                                        <tr key={credit.id}>
                                            <td style={{ fontWeight: '500' }}>{credit.customer.full_name}</td>
                                            <td style={{ fontWeight: '650', color: 'darkgreen' }}>&#8377; {credit.amount_allotted}</td>
                                            <td style={{ fontWeight: '500' }}>{timeConverter(credit.date_allotted)}</td>
                                            <td style={{ fontWeight: '500' }}>{timeConverter(credit.due_date)}</td>
                                            <td style={{ fontWeight: '650' }}>&#8377; {credit.total_payment ? credit.total_payment : '0'}</td>
                                            <td style={{ fontWeight: '650' }}>&#8377; {credit.amount_allotted - credit.total_payment}</td>

                                            <td><i onClick={() => setPaymentId(credit.id)} style={{ cursor: 'pointer', color: 'white', background: 'darkgreen' }} class="fa-solid fa-plus"></i>
                                                <i onClick={() => { setDialogue(true); setDeleteCreditId(credit.id) }} style={{ cursor: 'pointer', color: 'white', marginLeft: '10px', fontSize: '15px', background: 'red' }} class="fa-solid fa-trash"></i>
                                                <i onClick={()=>setCreditId(credit.id)} style={{ cursor: 'pointer', color: 'white', marginLeft: '10px', fontSize: '15px', background: 'blue' }} class="fa-solid fa-pen"></i>
                                                <i onClick={() => { setChildPayments(credit.credit_payments); setNote(credit.note) }} style={{ cursor: 'pointer', color: 'black', marginLeft: '10px', background: 'white' }} class="fa-solid fa-chevron-right"></i></td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        <div className="switchbutton">

                            {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {credit.length !== 0 && <div style={{ paddingLeft: '30px' }}>Showing {countA}-{countB} of {count}</div>}
                        </div>
                    </div>
                </div>
            </div>
            {dialogue && <ConfirmPopUp deleteText='Delete' onDialog={setDialogue} onAction={deleteCredit} />}
        </div>

    )
}
export default Credit;
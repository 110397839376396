import './sidebar.css'
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import axios from 'axios'
import { AuthContext } from '../../shared/context/authContext';
import CLogo1 from '../../images/CLogo1.png'

const Sidebar = ({ name }) => {

    let navigate = useNavigate()
    const auth = useContext(AuthContext)

    const handleLogout = () => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`/accounts/logout/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': storedData.token,
                'user-id': storedData.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log("logout Successfully", response);
            auth.logout();
            navigate('/');
        }).catch((error) => {
            console.log('error', error.response.data)
        })
    }

    return (
        <div class="sidebar" style={{visibility:auth.mobileView,zIndex:auth.mobileView==='true'?'10':'0'}}>
            <div class="logo">
                <img src={CLogo1} height='80px' width='60px' />
                <h2 style={{ lineHeight: '25px', color: "darkblue", marginBottom: '15px', paddingLeft: '5px' }}>PAS Company</h2>
                {auth.mobileView==='true' && <p style={{color:'red'}} onClick={()=>auth.setMobileView('hidden')}>x</p>}
            </div>
            <ul><Link to={`/`} style={{ color: 'black' }}>
                <li class={(name === 'employee') ? "active" : ""} onClick={() => { localStorage.removeItem('activeEmp'); localStorage.removeItem('empPageDetails'); localStorage.removeItem('empTrackUrl') }}>
                    <a>
                        <i class="fa-sharp fa-solid fa-users"></i>
                        <span>Employees</span>
                    </a>
                </li></Link>
                <Link to={`/checkin`} style={{ color: 'black' }}>
                    <li class={(name === 'checkin') ? "active" : ""} >
                        <a >
                            <i class="fa-solid fa-mobile"></i>
                            <span>Check-In</span>
                        </a>
                    </li></Link>
                <Link to={`/product`} style={{ color: 'black' }}>
                    <li class={(name === 'product') ? "active" : ""} onClick={() => { localStorage.removeItem('activePrd'); localStorage.removeItem('prdPageDetails'); localStorage.removeItem('prdTrackUrl') }}>
                        <a >
                            <i class="fa-solid fa-boxes-stacked"></i>
                            <span>Products</span>
                        </a>
                    </li></Link>
                <Link to={`/vehicle`} style={{ color: 'black' }}>
                    <li class={(name === 'vehicle') ? "active" : ""} >
                        <a >
                            <i class="fa-solid fa-truck"></i>
                            <span>Vehicle</span>
                        </a>
                    </li></Link>
                <Link to={`/route`} style={{ color: 'black' }}>
                    <li class={(name === 'route') ? "active" : ""} onClick={() => { localStorage.removeItem('shopStatus'); localStorage.removeItem('activeShop'); localStorage.removeItem('verifyShop'); localStorage.removeItem('routePageDetails'); localStorage.removeItem('routeTrackUrl') }} >
                        <a >
                            <i class="fa-solid fa-route"></i>
                            <span>Routes</span>
                        </a>
                    </li></Link>
                <Link to={`/shop`} style={{ color: 'black' }}>
                    <li class={(name === 'shop') ? "active" : ""} 
                    onClick={() => { localStorage.removeItem('shopStatus'); 
                    localStorage.removeItem('activeShop');
                     localStorage.removeItem('verifyShop');
                      localStorage.removeItem('shopPageDetails'); 
                      localStorage.removeItem('shopTrackUrl');
                      localStorage.removeItem('viewShopBillTrackUrl');
                            localStorage.removeItem('viewShopBillPageDetails')
                       }}>
                        <a >
                            <i class="fa-solid fa-shop"></i>
                            <span>Shops</span>
                        </a>
                    </li></Link>
                <Link to={`/order`} style={{ color: 'black' }}>
                    <li class={(name === 'order') ? "active" : ""}
                        onClick={() => {
                            localStorage.removeItem('statusNum');
                            localStorage.removeItem('status');
                            localStorage.removeItem('orderPageDetails');
                            localStorage.removeItem('orderTrackUrl');
                            localStorage.removeItem('viewOrderVisitedTrackUrl');
                            localStorage.removeItem('viewOrderVisitedPageDetails');
                            localStorage.removeItem('viewOrderDeliveredTrackUrl');
                            localStorage.removeItem('viewOrderDeliveredPageDetails')
                            localStorage.removeItem('viewSalesDeliveredTrackUrl');
                            localStorage.removeItem('viewSalesDeliveredPageDetails')
                        }}>
                        <a >
                            <i class="fa-solid fa-cart-flatbed-suitcase"></i>
                            <span>Orders</span>
                        </a>
                    </li></Link>
                <Link to={`/bill`} style={{ color: 'black' }}>
                    <li class={(name === 'bill') ? "active" : ""} onClick={() => { localStorage.removeItem('billTrackUrl'); localStorage.removeItem('billPageDetails') }}>
                        <a >
                            <i class="fa-sharp fa-solid fa-file-invoice-dollar"></i>
                            <span>Bills</span>
                        </a>
                    </li></Link>
                <Link to={`/payment`} style={{ color: 'black' }}>
                    <li class={(name === 'payment') ? "active" : ""} >
                        <a >
                            <i class="fa-solid fa-credit-card"></i>
                            <span>Receipts</span>
                        </a>
                    </li></Link>
                <Link to={`/credit`} style={{ color: 'black' }}>
                    <li class={(name === 'credit') ? "active" : ""} >
                        <a >
                        <i class="fa-solid fa-handshake-angle"></i>
                            <span>Credit</span>
                        </a>
                    </li></Link>

            </ul>
            <li className='logout' style={{ position: 'fixed', bottom: '2%', width: '238px' }} onClick={handleLogout}>
                <a >
                    <i class="fa-solid fa-right-from-bracket"></i>
                    <span>Logout</span>
                </a>
            </li>
        </div>
    )

}

export default Sidebar;
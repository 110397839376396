import React, { useState, useContext,useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function DownloadPerformance({ onClose,userId }) {

    const auth = useContext(AuthContext)
    const [downloadParams, setDownloadParams] = useState({
        start_date: "",
        end_date:''
    });
    const [error,setError]=useState([])
    
    const handleChange = (e) => {
        setDownloadParams({
            ...downloadParams, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        const date1 =new Date(downloadParams.start_date)
        const date2 =new Date(downloadParams.end_date)
        if(date1<=date2){
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `accounts/user/performance/download/?user=${userId}&start_date=${downloadParams.start_date}&end_date=${downloadParams.end_date}`,
            responseType: 'blob',
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/docx'
            },
        }).then((response) => {
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Performance-${userId}.docx`);
            document.body.appendChild(link);
            link.click();
            onClose();

        }
        )
            .catch((error) => {
                console.log('error', error)

            })
        }
        else{
            setError({compare:'Start date cannot be greater than End date'}) 
        }
    }
    const disabledDate = () =>{
        var today,dd,mm,yy;
        today=new Date()
        dd = today.getDate();
        mm = today.getMonth()+1
        yy = today.getFullYear()
        dd<10?dd='0'+dd:dd=dd
        mm<10?mm='0'+mm:mm=mm
        return yy+'-'+mm+'-'+dd
    }


    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Choose a Period</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Start Date</span><span style={{color:'red',fontWeight:'400'}}>{error.start_date?error.start_date:''}</span></div>
                            <input type="date" max={disabledDate()} placeholder="Enter Start date" name="start_date" value={downloadParams.start_date} onChange={handleChange} required />
                        </div>
                    
                    
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">End Date</span><span style={{color:'red',fontWeight:'400'}}>{error.end_date?error.end_date:''}</span></div>
                            <input type="date" max={disabledDate()} placeholder="Enter End date" name="end_date" value={downloadParams.end_date} onChange={handleChange} required />
                        </div>
                    </div>
                    <center><span style={{ color: "red" }}>{error.compare}</span></center>
                    <div className="button" >
                        <input type="submit" value="Download" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default DownloadPerformance;
import React, { useState, useContext } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../shared/context/authContext";
import ImageUpload from "../../common/components/ImageUpload";
import Loading from "../../common/Loading";
import capitalize from "../../common/Capitalize";

function AddVehicle({ onClose,forRefresh }) {

    const [loading, setLoading] = useState(false);
    const auth = useContext(AuthContext)
    const [error,setError]=useState([])
    const [vehicle, setVehicle] = useState({
        name: "",
        number: "",
        insurance_date:'',
        pollution_date:'',
        registration_date:'',
        fitness_date:'',
        tax_date:'',
        permit_date:''

        
    });
    const [vehicle_pic, setVehicleImage] = useState(null);
  const onProImageAdd = (img) => {
    setVehicleImage(img);
  }

    const notify = () => toast.success("Great! Vehicle Added Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setVehicle({
            ...vehicle, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        
    var formdata = new FormData();
    formdata.append("name", capitalize(vehicle.name));
    formdata.append("number", vehicle.number);
    formdata.append("insurance_date", parseInt((new Date(vehicle.insurance_date).getTime() / 1000).toFixed(0)));
    formdata.append("pollution_date", parseInt((new Date(vehicle.pollution_date).getTime() / 1000).toFixed(0)));

    formdata.append("registration_date", parseInt((new Date(vehicle.registration_date).getTime() / 1000).toFixed(0)));
    formdata.append("fitness_date", parseInt((new Date(vehicle.fitness_date).getTime() / 1000).toFixed(0)));
    formdata.append("tax_date", parseInt((new Date(vehicle.tax_date).getTime() / 1000).toFixed(0)));
    formdata.append("permit_date", parseInt((new Date(vehicle.permit_date).getTime() / 1000).toFixed(0)));
    vehicle_pic && formdata.append("image", vehicle_pic);

    axios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL+`sales/vehicle/create/`,
        data: formdata,
        headers: {
          'bearer': auth.token,
          'user-id': auth.userId,
          'Content-Type': 'multipart/form-data'
        },
      }).then((response) => {
        console.log(response);
        setVehicle(
            {
                name: "",
                number: ""
            }
        )
        setVehicleImage('')
        notify();
        onClose()
        setLoading(false)
        forRefresh()
      }
      )
        .catch((error) => {
          console.log('error', error.response.data)
          setError(error.response.data)
          setLoading(false)
        })
  
  
  


    }

    return (
        <div className="dialoguePage">
            {loading && <Loading/>}
            <div className="dialogueBox" style={{ height: '620px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Add Vehicle</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Name*</span><span style={{color:'red',fontWeight:'400'}}>{error.name?error.name:''}</span></div>
                            <input type="text" placeholder="Enter Name" name="name" value={vehicle.name} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Number*</span><span style={{color:'red',fontWeight:'400'}}>{error.number?error.number:''}</span></div>
                            <input type="text" placeholder="Enter Number" name="number" value={vehicle.number} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Registration Date*</span><span style={{color:'red',fontWeight:'400'}}>{error.registration_date?error.registration_date:''}</span></div>
                            <input type="date" placeholder="Enter Registration Date" name="registration_date" value={vehicle.registration_date} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Fitness Valid Upto*</span><span style={{color:'red',fontWeight:'400'}}>{error.fitness_date?error.fitness_date:''}</span></div>
                            <input type="date" placeholder="Enter Fitness Valid Upto" name="fitness_date" value={vehicle.fitness_date} onChange={handleChange} required />
                        </div>

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Tax Valid Upto*</span><span style={{color:'red',fontWeight:'400'}}>{error.tax_date?error.tax_date:''}</span></div>
                            <input type="date" placeholder="Enter Tax Valid Upto" name="tax_date" value={vehicle.tax_date} onChange={handleChange} required />
                        </div>
                        
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Insurance Valid Upto*</span><span style={{color:'red',fontWeight:'400'}}>{error.insurance_date?error.insurance_date:''}</span></div>
                            <input type="date" placeholder="Enter Insurance Valid Upto" name="insurance_date" value={vehicle.insurance_date} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">PUCC Valid Upto*</span><span style={{color:'red',fontWeight:'400'}}>{error.pollution_date?error.pollution_date:''}</span></div>
                            <input type="date" placeholder="Enter PUCC Valid Upto" name="pollution_date" value={vehicle.pollution_date} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Permit Valid Upto*</span><span style={{color:'red',fontWeight:'400'}}>{error.permit_date?error.permit_date:''}</span></div>
                            <input type="date" placeholder="Enter Permit Valid Upto" name="permit_date" value={vehicle.permit_date} onChange={handleChange} required />
                        </div>
                        
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Photo*</span><span style={{color:'red',fontWeight:'400'}}>{error.image?error.image:''}</span></div>
                    <div className="imageSelect ">
                  <ImageUpload imageAdd={onProImageAdd} text={vehicle_pic?"Change Vehicle Photo":"Select Vehicle Photo"}/>
                  </div>
                  </div>
                    </div>
                   
                    {/* <center><span style={{ color: "red" }}>{error}</span></center> */}
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddVehicle;
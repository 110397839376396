import React, { useState, useContext } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function EditPassword({ onClose, userId}) {

    const auth = useContext(AuthContext)
    const [error,setError]=useState([])
    const [errorPass,setErrorPass]=useState()
    const [user, setUser] = useState({
        password: "",
        cnfrm_password: "",
      });
    const [showCnfPswd,setShowCnfPswd]=useState(false)
    const [showPswd,setShowPswd]=useState(false)

    const notify = () => toast.success("Great! Password Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setUser({
          ...user, [e.target.name]: e.target.value
        });
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        setError([])
        setErrorPass('')
        if(user.password===user.cnfrm_password){
        const payload = {
            password:user.password
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `accounts/user-details/${userId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'multipart/form-data'
            },
        }).then((response) => {
            console.log(response);



            notify();
            onClose();
        }
        )
            .catch((error) => {
                console.log('error', error.response)
                setError(error.response.data)
            })

        }
        else{
            setErrorPass("Password Mismatch")
        }


    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px', width: '700px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Update Password</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details">
                    <div className="input-box">
                  <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Password*</span><span style={{color:'red',fontWeight:'400'}}>{error.password?error.password:''}</span></div>

                  <div className="password-container-user">
          <input style={{boxShadow:'none',outline:'none',border:'none',background:'white'}} type={showPswd?'text':'password'} className="input" value={user.password} placeholder="Enter Password" name="password" onChange={handleChange} />
          {!showPswd?<i onClick={()=>setShowPswd(true)} class="fa-sharp fa-solid fa-eye"></i>:
          <i onClick={()=>setShowPswd(false)} class="fa-solid fa-eye-slash"></i>}
          
          </div>

                    {/* <input  type="password" placeholder="Enter Password" name="password" value={user.password} onChange={handleChange}  required /> */}
                  </div>

                  <div className="input-box">
                    <span className="details"> Confirm Password*</span>


                    <div className="password-container-user">
          <input style={{boxShadow:'none',outline:'none',border:'none',background:'white'}} type={showCnfPswd?'text':'password'} className="input" value={user.cnfrm_password} name="cnfrm_password"   placeholder="Enter Password" onChange={handleChange} />
          {!showCnfPswd?<i onClick={()=>setShowCnfPswd(true)} class="fa-sharp fa-solid fa-eye"></i>:
          <i onClick={()=>setShowCnfPswd(false)} class="fa-solid fa-eye-slash"></i>}
          
          </div>


                    {/* <input type="text" placeholder="Enter Password" name="cnfrm_password" value={user.cnfrm_password} onChange={handleChange}  required /> */}
                  </div>
                       
                    </div>
                    <center><span style={{ color: "red" }}>{errorPass}</span></center>
                    <div className="button" >
                        <input type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default EditPassword;
import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import AddPayment from './popupForms//AddPayment';
import Loading from '../common/Loading';
import timeConverter from '../common/TimeConverter';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from '../common/components/DropDown';
import ConfirmPopUp from '../common/components/ConfirmPopUp';



const Payment = () => {

    let notify;

    const [status, setStatus] = useState(true)
    const [dialogue, setDialogue] = useState(false)
    const [paymentToDelete, setPaymentToDelete] = useState()
    const [filterOpen, setFilterOpen] = useState(false)
    const auth = useContext(AuthContext)
    const [searchPayment, setSearchPayment] = useState("");
    const [loading, setLoading] = useState(true);
    const [newPayment, setNewPayment] = useState(false)
    const [payment, setPayment] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [reLoad, setReLoad] = useState(true);
    const [creator, setCreator] = useState('')
    const [dropDownUser, setDropDownUser] = useState([])
    const [userSearch, setUserSearch] = useState('')
    const [userRole, setUserRole] = useState('')
    const dropDownUserRole = [
        { id: 101, full_name: 'Admin' },
        { id: 201, full_name: 'Executive' },
        { id: 301, full_name: 'Salesman' },
        { id: 401, full_name: 'Driver' }
    ]

    const [shop, setShop] = useState('')
    const [dropDownShop, setDropDownShop] = useState([])
    const [shopSearch, setShopSearch] = useState('')
    const [date, setDate] = useState();
    const [forDate, setForDate] = useState('');
    const [filter,setFilter]=useState(false)
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();

    const formHandler = () => {
        setNewPayment(!newPayment);
    }

    const changeDate =(e)=>{
        let selectedDate = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
        setForDate(selectedDate);
        setDate(e.target.value)
    }

    useEffect(() => {
        setCountA(1)
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/payment/list/?shop=${shop}&search=${searchPayment}&creator=${creator}&user_type=${userRole}&is_active=${status}&date=${forDate}&ordering=-created_on`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('payment', response)
            setPayment(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, [searchPayment, reLoad,filter,status])

    const onSwitchPage = (url,action) => {
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setPayment(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const onDelete = (paymentId) => {
        notify = () => toast.success("Great! Payment Rejected Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
        
        axios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_URL+`sales/payment/delete/${paymentId}/`,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setReLoad(!reLoad)
            })
    }

    const onVerify = (e, paymentId) => {
        e.stopPropagation()
        notify = () => toast.success("Great! Payment Approved Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
        const payload = {
            is_active: true,
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/payment/update/${paymentId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }



    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&type=${userRole}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('user for dropdown', response.data.results)
            setDropDownUser(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch,userRole])

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=shop&limit=1000&offset=0&name=${shopSearch}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop for dropdown', response.data.results)
            setDropDownShop(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [shopSearch])

    const applyFilter=()=>{
        setFilter(!filter)
        setFilterOpen(!filterOpen)
        setCountA(1)
    }
    const clearFilter=()=>{
        setFilter(!filter)
        setFilterOpen(!filterOpen)
        setCreator('')
        setForDate('')
        setShop('')
        setUserRole('')
        setCountA(1)
    }
    const actionDelete=(e,paymentId)=>{
        e.stopPropagation()
        
        setPaymentToDelete(paymentId)
        setDialogue(true)
    }

    return (
        <div className='full'>

            {newPayment && <AddPayment onClose={formHandler} forReload={()=>setReLoad(!reLoad)} />}
            <Sidebar name="payment" />
            <ToastContainer />
            <div class="content">
                {loading && <Loading />}
                <MainHeader />

                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginBottom: "10px" }}>Receipt Details</h3>
                        <div class="search">
                            <input type="text" placeholder="search here..." onChange={(e) => setSearchPayment(e.target.value)} />
                            <label for="search"><i class="fas fa-search"></i></label>
                        </div>
                        <div>
                            <button className='filter' onClick={() => setFilterOpen(!filterOpen)}>
                                <i class="fa-solid fa-filter"></i> Filters
                            </button>
                            <button className='button-31' onClick={formHandler}>New</button>
                        </div>
                    </div>
                    <div class="tabs" style={{ marginLeft: '0px' }}>
                        <div class={status === true ? "activetab" : "tab"} onClick={() => { setStatus(true)}}>Approved</div>
                        <div class={status === false ? "activetab" : "tab"} onClick={() => { setStatus(false)}}>Pending</div>
                    </div>
                    <div class="tables">
                        <div class="table-content">
                            <table >
                                <thead>
                                    <td>Shop</td>
                                    <td>Amount</td>
                                    <td>Date & Time</td>
                                    <td>Created By</td>

                                    <td>Action</td>


                                </thead>
                                <tbody>
                                    {payment.map((payment) => (
                                        <tr key={payment.id}>
                                            <td style={{ fontWeight: '500' }}>{payment.shop.name}</td>
                                            <td style={{ fontWeight: '650', color: 'darkgreen' }}>&#8377; {payment.amount}</td>
                                            <td>{timeConverter(payment.time)}</td>
                                            <td style={{ fontWeight: '500' }}>{payment.creator.full_name}</td>
                                            <td>
                                                {payment.is_active ? <span style={{ color: 'blue' }}>Approved</span> : <div> <button className='button-31' style={{padding:'2px 10px',background:'green'}} onClick={(e) => onVerify(e, payment.id)}><i style={{ color: 'white' }} class="fa-sharp fa-solid fa-check" ></i></button>
                                                    <button onClick={((e) => actionDelete(e, payment.id))} className='button-31' style={{padding:'2px 10px',background:'rgb(212, 7, 7)', marginLeft: '5px'}}><i style={{ color: 'white' }} class="fa-sharp fa-solid fa-close"></i></button>
                                                </div>}


                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        <div className="switchbutton">
                                
                                {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {payment.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                            </div>
                    </div>
                </div>
            </div>


            {filterOpen &&
                <div style={{ paddingRight: '20px', position: 'fixed', top: '80px', right: '110px', height: '260px', width: '300px', backgroundColor: 'white', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}>
                   <Dropdown dropdownFor='User Role' dropdownElements={dropDownUserRole} setId={setUserRole}  />
                   <Dropdown dropdownFor='User' dropdownElements={dropDownUser} setId={setCreator} dropdownSearch={setUserSearch} />
                   <Dropdown dropdownFor='Shop' dropdownElements={dropDownShop} setId={setShop} dropdownSearch={setShopSearch} />
                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}>Date : </span>
                    <input className='filter-dropdown' type='date' style={{width:'62%'}} value={date} onChange={changeDate}/>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '10px' }}>
                        <button onClick={clearFilter} style={{background:'grey',color:'white',padding:'4px 10px',borderRadius:'5px',borderColor:'grey',marginRight: '20px' }}>Clear</button>
                        <button onClick={applyFilter} style={{background:'blue',color:'white',padding:'4px 10px',borderRadius:'5px',borderColor:'blue'}}>Apply</button>
                        </div>
                </div>}


                {dialogue && <ConfirmPopUp onDialog={setDialogue} onAction={()=>onDelete(paymentToDelete)} />}
        </div>

    )
}
export default Payment;
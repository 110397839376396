import './mainHeader.css'
import { useContext,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../shared/context/authContext';
import axios from 'axios'

const MainHeader = () => {


    const auth = useContext(AuthContext)
    let navigate = useNavigate()

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1&offset=0&name=&type=23`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log("hai",response)
        }
        )
            .catch((error) => {
                console.log('error', error)
                auth.logout();
            navigate('/');

            })


    },[])


    
  
    return (
        <nav>
            {/* <div class="notification ">
                <i class="fa-regular fa-bell"></i>
                <span class="badge">5</span>
            </div>
            <div class="profile-img">
                <img src="https://xsgames.co/randomusers/assets/avatars/male/46.jpg" alt="profile img" />
            </div> */}
            {/* <p className='logout' onClick={handleLogout}>
                <i class="fa-solid fa-right-from-bracket"></i>
                <span >Logout</span>
            </p> */}
            
        </nav>
    )
}

export default MainHeader;
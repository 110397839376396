import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../shared/context/authContext'
import './viewUser.css'
import MainHeader from '../../common/components/MainHeader';
import { useParams } from 'react-router-dom'
import Sidebar from '../../common/components/Sidebar';
import axios from 'axios';
import timeConverter from '../../common/TimeConverter';
import Loading from '../../common/Loading';
import AddPurchase from '../popupForms/AddPurchase';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EditShopBill from '../popupForms/EditShopBill';
import EditUnloading from '../popupForms/EditUnloading';
import EditPurchase from '../popupForms/EditPurchase';
const ViewBill = () => {

    const auth = useContext(AuthContext)
    let navigate = useNavigate()
    const billId = useParams().billId
    const [loading, setLoading] = useState(true);
    const [editShop, setEditShop] = useState(false);
    const [editPurchase, setEditPurchase] = useState(false);
    const [purchaseId, setPurchaseId] = useState('');
    const [newPurchase, setNewPurchase] = useState(false);
    const [editUnload, setEditUnload] = useState(false);
    const [stockProduct, setStockProduct] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [bill, setBill] = useState({
    })

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/bill-details/${billId}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('bill', response)
            setBill(response.data);
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })

    }, [refresh]
    )
    const deliveryStatus = (status) => {
        if (status === 101) {
            return 'Delivery Pending'
        }
        else if (status === 201) {
            return 'Delivery Completed'
        }
        else if (status === 301) {
            return 'Delivery Cancelled'
        }
    }

    const actionPurchaseGst = (status,purchaseId) => {
        const payload = {
            gst_status: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/purchase/update/${purchaseId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const actionBill = (status) => {


        const payload = {
            gst: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `sales/bill/update/${billId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const actionBillActive = (billId, status) => {
        const payload = {
            is_active: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `sales/bill/update/${billId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })

    }

    const deletePurchase = (id) => {
        let notify = () => toast.success("Great! Purchase deleted Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

        axios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_URL + `sales/purchase/delete/${id}/`,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setRefresh(!refresh)
            })

    }
    const forEditPurchase = (id) => {
        setPurchaseId(id)
        setEditPurchase(true);
    }

    const downloadBill = (e, billId, num, shop,gst) => {
        e.stopPropagation()
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/bill/download/${billId}/?gst_status=${gst}`,
            responseType: 'blob',
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/docx'
            },
        }).then((response) => {
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${shop}-${num}.docx`);
            document.body.appendChild(link);
            link.click();
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error)

            })
    }

    const findUnit = (value) => {
        if (value === 101) {
            return 'KG'
        }
        else if (value === 201) {
            return 'LTR'
        }
        else if (value === 301) {
            return 'GM'
        }
        else if (value === 401) {
            return 'ML'
        }
    }

    return (
        <div>

            <Sidebar />
            <div class="content">
                {loading && <Loading />}
                <MainHeader />
                <ToastContainer />
                {newPurchase && <AddPurchase orderId={bill.order} orderType={bill.order_type} onClose={setNewPurchase} bill={billId} setRefresh={() => setRefresh(!refresh)} shopId={bill.shop.id}/>}
                {editPurchase && <EditPurchase productId={stockProduct} orderId={bill.order} onClose={setEditPurchase} purchaseId={purchaseId} setRefresh={() => setRefresh(!refresh)} />}
                {editShop && <EditShopBill onClose={setEditShop} bill={billId} setRefresh={() => setRefresh(!refresh)} />}
                {editUnload && <EditUnloading onClose={()=>setEditUnload(false)} billId={billId} setRefresh={() => setRefresh(!refresh)} />}



                <div class="table-main-header">
                    <div class="bill-content"><span>Invoice No : <span style={{ fontWeight: '600', fontSize: '18px' }}>{bill.bill_number}</span></span>
                        <span>Date :<span style={{ fontWeight: '600', fontSize: '18px' }}> {bill.bill_generated_on ? timeConverter(bill.bill_generated_on) : ''}</span> </span>
                    </div>

                    <div><span style={{ fontWeight: '600' }}>Active: </span>
                        <div className="toggle-container">

                            <div className="toggle-switch" onClick={() => actionBillActive(bill.id, bill.is_active)}>
                                <input type="checkbox" className="checkbox" checked={bill.is_active}
                                    name='label' />
                                <label className="label" >
                                    <span className="inner" />
                                    <span className="switch" />
                                </label>
                            </div>
                        </div>

                    </div>


                    {/* <div><span style={{ fontWeight: '600' }}>GST: </span> <div className="toggle-container"><div className="toggle-switch" onClick={() => actionBill(bill.gst)}>
                        <input readOnly type="checkbox" className="checkbox" checked={bill.gst}
                            name='label' />
                        <label className="label" >
                            <span className="inner" />
                            <span className="switch" />
                        </label>
                    </div></div>
                    </div> */}
                    <div class="bill-content">
                    <span style={{ fontWeight: '600', fontSize: '17px', color: bill.delivery === 201 ? 'darkgreen' : 'darkorange' }}> {deliveryStatus(bill.delivery)}</span>
                    <button onClick={() => navigate(`/order/${bill.order}`)} className="button-31" style={{marginTop:'2px' }}>Order Details <i class="fa-solid fa-caret-right"></i></button>

                    </div>

                    <div class="bill-content">
                        <span>Ord No : <span style={{ fontWeight: '600', fontSize: '18px' }}>{bill.id}</span></span>
                        <span>Ord Date : <span style={{ fontWeight: '600', fontSize: '18px' }}>{bill.created_on ? timeConverter(bill.created_on) : ''}</span></span>
                    </div>
                </div>

                <div class="main-container">

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <span style={{display:'flex'}}>Shop : <span style={{ fontSize: '18px', fontWeight: '600',display:'flex',flexDirection:'column',marginLeft:'5px' }}><span>{bill ? bill.shop && bill.shop.name : ''}</span><span style={{fontWeight:'400'}}>{bill ? bill.shop && bill.shop.address : ''}</span><span style={{fontSize:'17px',fontWeight:'400'}}>{bill ? bill.shop && bill.shop.phone : ''}</span></span></span>
                            <i onClick={() => setEditShop(true)} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i>
                        </div>


                        <div  style={{display:'flex',flexDirection:'column'}}>
                        <div>
                        <button className='button-31' style={{marginRight:'4px',background:'white',color:'blue' }} onClick={() => setNewPurchase(true)}><i class="fa-solid fa-plus"></i> Add Purchase</button>
                        <button className='button-31' disabled={!bill.download_button} style={{marginRight:'4px',background:'green' }} onClick={(e) => downloadBill(e, bill.id, timeConverter(bill.created_on), bill.shop.name,1)}>
                            <i style={{ color: 'white' }} class="fa-solid fa-file-export"></i> GST
                        </button>
                        <button className='button-31' disabled={!bill.download_button} style={{ marginRight:'3px',background:'red' }} onClick={(e) => downloadBill(e, bill.id, timeConverter(bill.created_on), bill.shop.name,2)}>
                            <i style={{ color: 'white' }} class="fa-solid fa-file-export"></i> <strike>GST</strike>
                        </button>


                       
                        <button className='button-31' disabled={!bill.download_button} style={{background:'blue' }}  onClick={(e) => downloadBill(e, bill.id, timeConverter(bill.created_on), bill.shop.name,'')}>
                            <i style={{ color: 'white' }} class="fa-solid fa-file-export"></i> Bill
                        </button>
                        </div>
                        <div>
                        <div style={{marginTop:'10px'}}>Unloading Charge : <span style={{ fontWeight: '700' }}>{bill.unloading_charge}</span><i onClick={()=>setEditUnload(true)}  style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i></div>
                        </div>
                        </div>


                    </div>
                    <hr style={{ marginTop: '10px' }} />
                    <h3 style={{ color: "#060082", marginTop: "10px" }}>Sales Details</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '10px' }}>
                        <table >
                            <thead>
                                <td>Product</td>
                                <td>Quantity</td>
                                <td>Price</td>
                                <td>Discount</td>
                                <td>Amount</td>
                                <td>GST %</td>
                                <td>GST</td>
                                <td>Total Amount</td>
                                <td>Action</td>

                            </thead>
                            <tbody>
                                {bill.purchase && bill.purchase.map((purchase) => (
                                    <tr key={purchase.id} style={{ cursor: 'default' }}>
                                        <td>{purchase.product.name} {purchase.product.weight} {findUnit(purchase.product.unit)}</td>
                                        <td>{purchase.quantity}</td>
                                        <td>&#8377; {purchase.price}</td>
                                        <td>&#8377; {purchase.discount}</td>
                                        <td>&#8377; {purchase.info.amount}</td>
                                        <td>{purchase.info.gst_per}%</td>
                                        <td style={{display:'flex'}}>



                                            <div className="toggle-container" style={{marginTop:'-5px',marginRight:'5px'}}>

                                                <div className="toggle-switch" onClick={(e) => actionPurchaseGst(purchase.gst_status,purchase.id)}>
                                                    <input readOnly type="checkbox" className="checkbox" checked={purchase.gst_status}
                                                        name='label' />
                                                    <label className="label" >
                                                        <span className="inner" />
                                                        <span className="switch" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div>&#8377; {purchase.info.gst_amount}</div>




                                        </td>
                                        <td>&#8377; {purchase.total_amount}</td>
                                        <td><i onClick={() => {forEditPurchase(purchase.id);setStockProduct(purchase.product.id)}} style={{ cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i>
                                            <i onClick={() => deletePurchase(purchase.id)} style={{ cursor: 'pointer', color: 'red' }} class="fa-solid fa-trash"></i></td>

                                    </tr>
                                ))}
                                {bill.purchase && bill.purchase.length > 0 &&
                                    <tr style={{ borderTop: '2px solid black' }}>
                                        <td></td>
                                        <td style={{ fontWeight: '700' }}>TOTAL</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td style={{ color: 'red' }}>
                                            <div class="total-header"><span>Net Taxable amount</span></div>
                                            <div class="total-header"><span>Tax amount</span></div>
                                            <div class="total-header"><span>Total</span></div>
                                            <div class="total-header"><span>Rounded</span></div>
                                            <div class="total-header"><span>Final Amount</span></div>
                                        </td>
                                        <td>

                                            <div class="total-header"><span style={{ fontWeight: '700' }}>: {bill.taxable_amount}</span></div>
                                            <div class="total-header"><span style={{ fontWeight: '700' }}>: {bill.total_gst}</span></div>
                                            <div class="total-header"><span style={{ fontWeight: '700' }}>: {bill.total_amount}</span></div>
                                            <div class="total-header"><span style={{ fontWeight: '700' }}>: {bill.rounded}</span></div>
                                            <div class="total-header"><span style={{ fontWeight: '700' }}>: {bill.final_amount}</span></div>
                                        </td>
                                    </tr>}

                            </tbody>
                        </table>

                    </div>

                </div>
            </div>
           
        </div>


    )

}
export default ViewBill
import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../shared/context/authContext";

function EditShopRoute({ onClose, shopId, forRefresh }) {

    const auth = useContext(AuthContext)
    const [error, setError] = useState()
    const [dropDownRoute, setDropDownRoute] = useState([])
    const [routeSearch, setRouteSearch] = useState('')
    const [value, setValue] = useState("Select a Route")
    const [show, setShow] = useState(false)
    const [routeId, setRouteId] = useState()

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=route&limit=1000&offset=0&name=${routeSearch}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('route for dropdown', response.data.results)
            setDropDownRoute(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [routeSearch])

    const handleChangeRoute = (id, name) => {
        setRouteId(id)
        setValue(name)
        setShow(false)
    }

    const handleToggle = (e) => {
        e.target.focus();
        setShow(!show);
    }

    const notify = () => toast.success("Great! Shop Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            route: routeId
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/update/${shopId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            forRefresh()
            notify();
            onClose()
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px', width: '700px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Select Route</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => onClose()}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >


                        <div className="input-box" style={{ width: '700px' }}>



                           <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={value}
                                        className="dropdown"
                                        onClick={handleToggle}
                                    />
                                </label>
                                <div hidden={!show} className="dropdown-list" style={{ width: '656px' }} >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setRouteSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownRoute.map((DRoute) => (
                                            <li className="option" onClick={() => handleChangeRoute(DRoute.id, DRoute.name)} key={DRoute.id}>{DRoute.name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <center><span style={{ color: "red" }}>{error}</span></center>
                    <div className="button" >
                        <input type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default EditShopRoute;
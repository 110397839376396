import './addUser.css';
import { useState, useEffect, useContext } from 'react';
import capitalize from '../../common/Capitalize';
import ConfirmPopUp from '../../common/components/ConfirmPopUp';
import axios from 'axios';
import { toast } from "react-toastify";
import { AuthContext } from '../../shared/context/authContext';

function ShopChildPayment({ onClose,payments,note,forReLoad }) {

    const [dialogue, setDialogue] = useState(false)
    const [deletePaymentId, setDeletePaymentId] = useState()
    const auth = useContext(AuthContext)

    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = (date<10?`0${date}`:date) + '/' + month + '/' + year;
        return time;
    }

    const deletePayment = () => {
        let notify = () => toast.success("Great! Payment deleted Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

        axios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_URL + `credits/payments/${deletePaymentId}/`,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            onClose();
            forReLoad();
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                onClose()
            })

    }


    return (
        <div className="dialoguePage" >
            <div className="dialogueBox" style={{ height: 'auto',width:'600px' }}>
                <div className="head" style={{marginBottom:'15px'}}>
                    <h2 style={{ color: "#003cff" }}>Payment details</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <span style={{marginBottom:'10px',background:'#ffd561',padding:'5px',fontWeight:'500'}}>{capitalize(note)}</span>
                    <ul style={{listStyleType:'none',padding:'5px 0',maxHeight:'300px',overflow: 'auto'}}>
                    {payments.length===0 &&<span>No data found.</span>}
                        {payments.map((payment) => (
                            <div style={{padding:'2px 0'}}>
                            <li style={{fontWeight:'500',fontSize:'18px'}} key={payment.id}>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <span><i style={{fontSize:'10px',opacity:'70%'}} class="fa-solid fa-circle"></i> {timeConverter(payment.payment_date)}</span> <span style={{color:'darkgreen',fontWeight:'bold',paddingRight:'10px'}}>&#8377; {payment.amount}
                                    <i onClick={() => { setDialogue(true); setDeletePaymentId(payment.id) }} style={{ cursor: 'pointer', color: 'red', marginLeft: '10px', fontSize: '15px'}} class="fa-solid fa-trash"></i></span>
                                
                                </div>
                                </li>
                            <hr style={{marginBottom:'10px'}}/>
                            </div>
                        ))}

                    </ul>
                
            </div>
            {dialogue && <ConfirmPopUp deleteText='Delete' onDialog={setDialogue} onAction={deletePayment} />}
        </div>
    );
}
export default ShopChildPayment;
import React, { useEffect, useRef,useState } from 'react';
import './ImageUpload.css';

const ImageUpload = ({imageAdd,text}) => {
  const filePickerRef = useRef();
  const  [file,setFile] = useState();
  const  [previewUrl,setPeviewUrl] = useState();
  const  [isValid,setIsValid] = useState(false);

  useEffect(()=>{
    if(!file){
        return
    }
    const fileReader = new FileReader();
    fileReader.onload = () =>{
        setPeviewUrl(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  },[file])

  const pickedHandler = event => {
    let pickedFile;
    let fileIsValid = isValid
    if(event.target.files && event.target.files.length === 1){
        pickedFile = event.target.files[0];
        setFile(pickedFile);
        setIsValid(true)
        fileIsValid = true
    }else{
        setIsValid(false)
        fileIsValid = false
    }
    imageAdd(pickedFile)
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className="form-control">
        <div className='image-upload center'>
        <div type="button" className='select-button' onClick={pickImageHandler}>{text}</div>
        <div className="image-upload__preview">
          {previewUrl && <img src={previewUrl} alt="Preview" />}
        </div>
        
      </div>
      <input
        ref={filePickerRef}
        style={{ display: 'none' }}
        type="file"
        accept='.jpg,.jpeg'
        onChange={pickedHandler}
      />
      
    </div>
  );
};

export default ImageUpload;

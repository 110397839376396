import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import AddUser from './popupForms/AddUser';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import ImageViewer from '../common/components/ImageViewer';
import propic from '../images/propic.jpg'
import Loading from '../common/Loading';
import { Link } from 'react-router-dom';

const Employee = () => {

    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState(true);
    const [searchUser, setSearchUser] = useState("");
    const [newUser, setNewUser] = useState(false)
    const [image, setImage] = useState({
        img: "",
        name: ""
    })
    const [showImage, setShowImage] = useState(false)
    const [refresh,setRefresh] = useState(false)
    const [employee, setEmployee] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [status, setStatus] = useState(localStorage.getItem('activeEmp')  || 'true')
    const [active, setActive] = useState(localStorage.getItem('activeEmp')  || true  )
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();

  
    const formHandler = () => {
        setNewUser(!newUser);
    }
    useEffect(() => {
        setCountA(1)
        setLoading(true)
        const storedEmpUrl = localStorage.getItem('empTrackUrl');
        const storedEmpData = JSON.parse(localStorage.getItem('empPageDetails'));
        if (storedEmpData && storedEmpUrl) {
          setCountA(storedEmpData.countA)
         

            axios({
                method: 'get',
                url: storedEmpUrl,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                console.log(response)
                setEmployee(response.data.results);
                setNext(response.data.next);
                setCount(response.data.count)
                setPrevious(response.data.previous);
                setLoading(false)
                setCountB(storedEmpData.countA+response.data.results.length-1) 
                setPrevCountB(response.data.results.length)
            }
            )
                .catch((error) => {
                    console.log('error', error.response.data)
    
                })
        }
        else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`accounts/user/list/?name=${searchUser}&type=&is_active=${active}&limit=12&ordering=name`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('employee', response)
            setEmployee(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }}, [searchUser,refresh,active])

    const onSwitchPage = (url,action) => {
        localStorage.removeItem('empPageDetails')
        localStorage.setItem('empTrackUrl',url)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setEmployee(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 12)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 12)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const findUserType=(type)=>{
        if(type===201){
            return 'Executive'
        }
        else if(type===301){
            return 'Salesman'
        }
        else if(type===401){
            return 'Driver'
        }
    }

    return (
        <div className='full'>
           
            {newUser && <AddUser onClose={formHandler} forRefresh={()=>setRefresh(!refresh)}/>}
            {showImage && <ImageViewer image={image.img} name={image.name} onClose={setShowImage} />}
            <Sidebar name="employee" />
            <ToastContainer />
            <div class="content">
            {loading && <Loading />}
                <MainHeader />


                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginLeft: "0px", marginBottom: "10px" }}>Employee Details</h3>
                        <div class="search" onClick={() => { localStorage.removeItem('activeEmp'); localStorage.removeItem('empPageDetails'); localStorage.removeItem('empTrackUrl') }}>
                            <input type="text"  placeholder="search here..." onChange={(e)=>setSearchUser(e.target.value)} />
                            <label for="search"><i class="fas fa-search"></i></label>
                        </div>
                        <button className='button-31' onClick={formHandler}>New</button>
                    </div>
                    <div class="tabs" style={{ marginLeft: '0px' }}>
                           <div class={status === 'true' ? "activetab" : "tab"} onClick={() => {setStatus('true');setActive(true);setCountA(1);localStorage.setItem('activeEmp', 'true');localStorage.setItem('statusNum', '101');localStorage.removeItem('empTrackUrl') }}>Active</div>
                           <div class={status === 'false' ? "activetab" : "tab"} onClick={() => {setStatus('false');setActive(false);setCountA(1);localStorage.setItem('activeEmp', 'false');localStorage.removeItem('empTrackUrl')}}>Not-Active</div>
                        </div>
                    <div className='user-container'>
                        {employee.length===0 && <h4 style={{paddingLeft:'40%',paddingTop:'5%'}}>No results found.</h4>}
                        {employee.map((emp) => (
                            <div className='user-box' key={emp.id} >
                                <Link to={`/user/${emp.id}`} onClick={()=>{localStorage.removeItem('EmpOrder');localStorage.removeItem('EmpType');localStorage.setItem('empPageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}))}}>
                                <figure class="snip1559" style={{border:'3px solid green'}}>
                                    <div className='card-in'>
                                    <div class="profile-image"><img src={emp.profile_pic ? emp.profile_pic : propic} /></div>
                                    <figcaption>
                                        <p style={{fontSize:'17px',fontWeight:'700'}}><span style={{fontSize:'12px',fontWeight:'400'}}>Name : </span>{emp.first_name} {emp.last_name}</p>
                                        <p style={{fontSize:'15px',fontWeight:'500'}}><span style={{fontSize:'12px',fontWeight:'400'}}>Mob : </span>{emp.phone_number}</p>
                                        <p style={{marginLeft:'59px',marginTop:'5px',color:'darkblue',fontSize:'15px'}}>{findUserType(emp.type)}</p>
                                    </figcaption>
                                    </div>
                                   
                                </figure>
                                </Link>

                            </div>
                        ))}

                    </div>
                    
                            
                        </div>
                        <div className="switchbutton">
                                
                                {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {employee.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                            </div>
                    </div>
                </div>
           

    )
}
export default Employee;
import React from "react";
import './ConfirmPopUp.css';
function ConfirmPopUp({ onDialog, onAction , deleteText}) {
  return (
    <div className="confirm-dialoguePage" onClick={() => onDialog(false)}>
      <div className="confirm-dialogueBox">
        <h3 className="confirm-DialogueTitle">{deleteText?deleteText:'Reject?'}</h3>
        <p className="confirm-DialoguePara">Are you sure about this Action?</p>
        <div className="confirm-dialogueButtons">
          <button className="confirm-DialogueCancel" onClick={() => onDialog(false)}>Cancel</button>
          <button className="confirm-DialogueLogout" onClick={onAction}>{deleteText?deleteText:'Reject'}</button>
        </div>
      </div>
    </div>
  );
}
export default ConfirmPopUp;
import React, { useState, useContext,useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../shared/context/authContext";
import ImageUpload from "../../common/components/ImageUpload";
import Loading from "../../common/Loading";
import capitalize from "../../common/Capitalize";

function EditVehicle({ onClose,VehicleId }) {

    const [loading, setLoading] = useState(false);
    const [activeReg, setActiveReg] = useState(false);
    const [activePer, setActivePer] = useState(false);
    const [activeTax, setActiveTax] = useState(false);
    const [activeFit, setActiveFit] = useState(false);
    const [activeIns, setActiveIns] = useState(false);
    const [activePol, setActivePol] = useState(false);
    const auth = useContext(AuthContext)
    const [error,setError]=useState()
    const [vehicle, setVehicle] = useState({
        name: "",
        number: "",
        insurance_date:'',
        pollution_date:'',
        registration_date:'',
        fitness_date:'',
        tax_date:'',
        permit_date:''
        
    });
    const [vehicle_pic, setVehicleImage] = useState("");
  const onProImageAdd = (img) => {
    setVehicleImage(img);
  }

    const notify = () => toast.success("Great! Vehicle Edited Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });


    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/vehicle-details/${VehicleId}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('vehicle', response)
            setVehicle(response.data);
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, []
    )

    const handleChange = (e) => {
        setVehicle({
            ...vehicle, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        
    var formdata = new FormData();
    formdata.append("name",capitalize(vehicle.name));
    formdata.append("number", vehicle.number);
    vehicle_pic && formdata.append("image", vehicle_pic);
    activeIns && formdata.append("insurance_date", parseInt((new Date(vehicle.insurance_date).getTime() / 1000).toFixed(0)));
    activePol && formdata.append("pollution_date", parseInt((new Date(vehicle.pollution_date).getTime() / 1000).toFixed(0)));

    activeTax && formdata.append("tax_date", parseInt((new Date(vehicle.tax_date).getTime() / 1000).toFixed(0)));
    activePer && formdata.append("permit_date", parseInt((new Date(vehicle.permit_date).getTime() / 1000).toFixed(0)));
    activeReg && formdata.append("registration_date", parseInt((new Date(vehicle.registration_date).getTime() / 1000).toFixed(0)));
    activeFit && formdata.append("fitness_date", parseInt((new Date(vehicle.fitness_date).getTime() / 1000).toFixed(0)));

    axios({
        method: 'patch',
        url: process.env.REACT_APP_BACKEND_URL+`sales/vehicle/update/${VehicleId}/`,
        data: formdata,
        headers: {
          'bearer': auth.token,
          'user-id': auth.userId,
          'Content-Type': 'multipart/form-data'
        },
      }).then((response) => {
        console.log(response);
        setVehicle(
            {
                name: "",
                number: ""
            }
        )
      
        notify();
        onClose('')
        setLoading(false)
      }
      )
        .catch((error) => {
          console.log('error', error.response.data)
          setError("Please Select a Photo")
          setLoading(false)
        })
  
  
  


    }

    return (
        <div className="dialoguePage">
            {loading && <Loading/>}
            <div className="dialogueBox" style={{ height: '620px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Update Vehicle</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={()=>onClose('')}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                            <span className="details">Name</span>
                            <input type="text" placeholder="Enter Name" name="name" value={vehicle.name} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Number</span>
                            <input type="text" placeholder="Enter Number" name="number" value={vehicle.number} onChange={handleChange} required />
                        </div>

                        <div className="input-box">
                        <span className="details">Registration Date</span>
                            <input type="date" placeholder="Enter Registration Date" name="registration_date" value={vehicle.registration_date} onClick={()=>setActiveReg(true)} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <span className="details">Fitness Valid Upto</span>
                            <input type="date" placeholder="Enter Fitness Valid Upto" name="fitness_date" value={vehicle.fitness_date} onClick={()=>setActiveFit(true)} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <span className="details">Tax Valid Upto</span>
                            <input type="date" placeholder="Enter Tax Valid Upto" name="tax_date" value={vehicle.tax_date} onClick={()=>setActiveTax(true)} onChange={handleChange} required />
                        </div>

                        <div className="input-box">
                        <span className="details">Insurance Valid Upto</span>
                            <input type="date" placeholder="Enter Insurance Valid Upto" name="insurance_date" value={vehicle.insurance_date} onClick={()=>setActiveIns(true)} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <span className="details">PUCC Valid Upto</span>
                            <input type="date" placeholder="Enter PUCC Valid Upto" name="pollution_date" value={vehicle.pollution_date} onClick={()=>setActivePol(true)} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <span className="details">Permit Valid Upto</span>
                            <input type="date" placeholder="Enter Permit Valid Upto" name="permit_date" value={vehicle.permit_date} onClick={()=>setActivePer(true)} onChange={handleChange} required />
                        </div>
                        
                        <div className="input-box">
                  <span className="details">Photo</span>
                    <div className="imageSelect ">
                  <ImageUpload imageAdd={onProImageAdd} text="Select Vehicle Photo"/>
                  </div>
                  </div>
                    </div>
                   
                    <center><span style={{ color: "red" }}>{error}</span></center>
                    <div className="button" >
                        <input type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default EditVehicle;
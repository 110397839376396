import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import { ToastContainer } from 'react-toastify';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import AddShop from './popupForms/AddShop';
import Loading from '../common/Loading';
import { useNavigate } from 'react-router-dom';
import ConfirmPopUp from '../common/components/ConfirmPopUp';


const Shop = () => {

    let navigate = useNavigate()

    const [dialogue, setDialogue] = useState(false)
    const [shopToDelete, setShopToDelete] = useState()

    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState(true);
    const [searchShop, setSearchShop] = useState("");
    const [newShop, setNewShop] = useState(false)
    const [shop, setShop] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [status, setStatus] = useState(localStorage.getItem('shopStatus') || 'active')
    const [active, setActive] = useState(localStorage.getItem('activeShop') || true)
    const [verify, setVerify] = useState(localStorage.getItem('verifyShop') || true)
    const [reLoad, setReLoad] = useState(true);
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();
    const [refresh,setRefresh] = useState(false)


    let notify;

    const formHandler = () => {
        setNewShop(!newShop);
    }
    useEffect(() => {
        setCountA(1)
        setLoading(true)
        const storedShopUrl = localStorage.getItem('shopTrackUrl');
        const storedShopData = JSON.parse(localStorage.getItem('shopPageDetails'));
        if (storedShopData && storedShopUrl) {
          setCountA(storedShopData.countA)
         

            axios({
                method: 'get',
                url: storedShopUrl,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                console.log(response)
                setShop(response.data.results);
                setNext(response.data.next);
                setCount(response.data.count)
                setPrevious(response.data.previous);
                setLoading(false)
                setCountB(storedShopData.countA+response.data.results.length-1) 
                setPrevCountB(response.data.results.length)
            }
            )
                .catch((error) => {
                    console.log('error', error.response.data)
    
                })
        }
else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/list/?name=${searchShop}&route=&order=&status=&is_active=${active}&verified=${verify}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop', response)
            setShop(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setCount(response.data.count)
            setCountB(response.data.results.length)
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }}, [refresh, searchShop, active, verify, reLoad])

    const onSwitchPage = (url, action) => {
        localStorage.removeItem('shopPageDetails')
        localStorage.setItem('shopTrackUrl',url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setShop(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }


    const onDelete = (shopId) => {
        notify = () => toast.success("Great! Shop Rejected Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
        
        axios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/delete/${shopId}/`,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setReLoad(!reLoad)
            })
    }

    // const actionShop = (e, shopId, status) => {
    //     e.stopPropagation()
    //     console.log(shopId, status)
    //     const payload = {
    //         is_active: !status
    //     }

    //     axios({
    //         method: 'patch',
    //         url: process.env.REACT_APP_BACKEND_URL+`sales/shop/update/${shopId}/`,
    //         data: payload,
    //         headers: {
    //             'bearer': auth.token,
    //             'user-id': auth.userId,
    //             'Content-Type': 'application/json'
    //         },
    //     }).then((response) => {
    //         console.log(response);
    //         setReLoad(!reLoad)

    //     }
    //     )
    //         .catch((error) => {
    //             console.log('error', error.response.data)

    //         })
    // }

    const onVerify = (e, shopId) => {
        e.stopPropagation()
        notify = () => toast.success("Great! Shop Approved Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
        const payload = {
            is_active: true,
            verified: true

        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/update/${shopId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }
    const actionDelete=(e,shopId)=>{
        e.stopPropagation()
        
        setShopToDelete(shopId)
        setDialogue(true)
    }

    return (
        <div className='full'>

            {newShop && <AddShop onClose={formHandler} forRefresh={()=>setRefresh(!refresh)}/>}
            <Sidebar name="shop" />
            <ToastContainer />
            <div class="content">
                {loading && <Loading />}
                <MainHeader />

                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginBottom: "10px" }}>Shop Details</h3>
                        <div class="search" onClick={()=>localStorage.removeItem('shopTrackUrl') }>
                            <input type="text" placeholder="search here..." onChange={(e) => setSearchShop(e.target.value)} />
                            <label ><i class="fas fa-search"></i></label>
                        </div>
                        <button className='button-31' onClick={formHandler}>New</button>
                    </div>
                    <div class="tabs" style={{ marginLeft: '0px' }}>
                        <div class={status === 'active' ? "activetab" : "tab"} onClick={() => { setStatus('active'); setActive(true); setVerify(true);setCountA(1);localStorage.setItem('activeShop', 'true');localStorage.setItem('verifyShop', 'true');localStorage.setItem('shopStatus', 'active');localStorage.removeItem('shopTrackUrl') }}>Active</div>
                        <div class={status === 'notactive' ? "activetab" : "tab"} onClick={() => { setStatus('notactive'); setActive(false); setVerify(true);setCountA(1);localStorage.setItem('activeShop', 'false');localStorage.setItem('verifyShop', 'true');localStorage.setItem('shopStatus', 'notactive');localStorage.removeItem('shopTrackUrl') }}>Not-Active</div>
                        <div class={status === 'notverified' ? "activetab" : "tab"} onClick={() => { setStatus('notverified'); setActive(true); setVerify(false);setCountA(1);localStorage.setItem('activeShop', 'true');localStorage.setItem('verifyShop', 'false');localStorage.setItem('shopStatus', 'notverified');localStorage.removeItem('shopTrackUrl') }}>Not-Verified</div>
                    </div>
                    <div class="tables">
                        <div class="table-content">
                            <table >
                                <thead>
                                    <td>Name</td>
                                    <td>Route</td>
                                    <td>Location</td>
                                    <td>Phone</td>
                                    {status === 'notverified' &&  <td>Action</td>}

                                </thead>
                                {!loading && <tbody>
                                    {shop.map((shop) => (
                                        <tr key={shop.id} onClick={() => {navigate(`/shop/${shop.id}`);localStorage.removeItem('ShopViewTab');localStorage.setItem('shopPageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}))}}>
                                            <td>{shop.name}</td>
                                            <td>{shop.route_name}</td>
                                            <td>{shop.location}</td>
                                            <td>{shop.phone}</td>

                                            <td>
                                                {/* {verify && <div className="toggle-container">

                                                    <div className="toggle-switch" onClick={(e) => actionShop(e, shop.id, shop.is_active)}>
                                                        <input readOnly type="checkbox" className="checkbox" checked={shop.is_active}
                                                            name='label' />
                                                        <label className="label" >
                                                            <span className="inner" />
                                                            <span className="switch" />
                                                        </label>
                                                    </div>
                                                </div>} */}
                                                {status === 'notverified' && <div> <button className='button-31' style={{padding:'1px',width:'35px',background:'green'}} onClick={(e) => onVerify(e, shop.id)}><i style={{ color: 'white' }} class="fa-sharp fa-solid fa-check" ></i></button>
                                                    <button onClick={(e) => actionDelete(e, shop.id)} className='button-31' style={{padding:'1px',width:'35px',background:'rgb(212, 7, 7)', marginLeft: '5px'}}><i style={{ color: 'white' }} class="fa-sharp fa-solid fa-close"></i></button>
                                                </div>}
                                            </td>

                                        </tr>
                                    ))}


                                </tbody>}
                            </table>
                        </div>
                        
                       
                            <div className="switchbutton">
                               
                                {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {shop.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                            </div>
                    </div>
                </div>
            </div>
            {dialogue && <ConfirmPopUp onDialog={setDialogue} onAction={()=>onDelete(shopToDelete)} />}
        </div>

    )
}
export default Shop;
import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../shared/context/authContext";

function OrderApprove({ onClose,approveOrder,orderId }) {

    const auth = useContext(AuthContext)
    
    const [dropDownSalesman, setDropDownSalesman] = useState([])
    
    const [userSearch, setUserSearch] = useState('')
    
    const [valueSalesman, setValueSalesman] = useState("Select a Salesman")

    const [dropDownVehicle, setDropDownVehicle] = useState([])
    
    const [vehicleSearch, setVehicleSearch] = useState('')
    
    const [valueVehicle, setValueVehicle] = useState("Select a Vehicle")

    const [dropDownDriver, setDropDownDriver] = useState([])
    
    const [driverSearch, setDriverSearch] = useState('')
    
    const [valueDriver, setValueDriver] = useState("Select a Driver")
    
    const [showSalesman, setShowSalesman] = useState(false)

    const [showVehicle, setShowVehicle] = useState(false)
    const [showDriver, setShowDriver] = useState(false)

    const [order, setOrder] = useState({
        status: "201",
        saleman: "",
        delivered_date: "",
        vehicle:null,
        driver:null
    });

    const notify = () => toast.success("Great! Order Approved Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });


    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&type=301&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('Salesman for dropdown', response.data.results)
            setDropDownSalesman(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${driverSearch}&type=401&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('Driver for dropdown', response.data.results)
            setDropDownDriver(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [driverSearch])

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/vehicle/list/?shop=&search=${vehicleSearch}&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('Vehicle for dropdown', response.data.results)
            setDropDownVehicle(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [vehicleSearch])

 
    const handleToggleSalesman = (e) => {
        e.target.focus();
        setShowSalesman(!showSalesman);
    }
    const handleToggleDriver = (e) => {
        e.target.focus();
        setShowDriver(!showDriver);
    }
    const handleToggleVehicle = (e) => {
        e.target.focus();
        setShowVehicle(!showVehicle);
    }
    const handleChange = (e) => {
        setOrder({
            ...order, [e.target.name]: e.target.value
        });
    }

    const handleChangeSalesman = (id, name) => {
        setOrder({
            ...order, saleman: id
        });
        setValueSalesman(name)
        setShowSalesman(false)
    }

    const handleChangeDriver = (id, name) => {
        setOrder({
            ...order, driver: id
        });
        setValueDriver(name)
        setShowDriver(false)
    }

    const handleChangeVehicle = (id, name) => {
        setOrder({
            ...order, vehicle: id
        });
        setValueVehicle(name)
        setShowVehicle(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const payload = {
            status:'201',
            salesman: order.saleman,
            vehicle:order.vehicle,
            driver:order.driver,
            delivered_date: parseInt((new Date(order.delivered_date).getTime() / 1000).toFixed(0))
        }
        approveOrder(orderId,payload)
        notify()
       
    }

    const disabledDate = () =>{
        var today,dd,mm,yy;
        today=new Date()
        dd = today.getDate();
        mm = today.getMonth()+1
        yy = today.getFullYear()
        dd<10?dd='0'+dd:dd=dd
        mm<10?mm='0'+mm:mm=mm
        return yy+'-'+mm+'-'+dd
    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '400px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Order Approve</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={()=>onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                            <span className="details">Salesman</span>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={valueSalesman}
                                        className="dropdown"
                                        onClick={handleToggleSalesman}
                                    />
                                </label>
                                <div hidden={!showSalesman} className="dropdown-list" >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setUserSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownSalesman.map((DSalesman) => (
                                            <li className="option" onClick={() => handleChangeSalesman(DSalesman.id, DSalesman.full_name)} key={DSalesman.id}>{DSalesman.full_name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className="input-box">
                            <span className="details">Vehicle</span>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={valueVehicle}
                                        className="dropdown"
                                        onClick={handleToggleVehicle}
                                    />
                                </label>
                                <div hidden={!showVehicle} className="dropdown-list" >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setVehicleSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownVehicle.map((DVehicle) => (
                                            <li className="option" onClick={() => handleChangeVehicle(DVehicle.id, DVehicle.name)} key={DVehicle.id}>{DVehicle.name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="input-box">
                            <span className="details">Driver</span>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={valueDriver}
                                        className="dropdown"
                                        onClick={handleToggleDriver}
                                    />
                                </label>
                                <div hidden={!showDriver} className="dropdown-list" >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setDriverSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownDriver.map((Driver) => (
                                            <li className="option" onClick={() => handleChangeDriver(Driver.id, Driver.full_name)} key={Driver.id}>{Driver.full_name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="input-box">
                            <span className="details">Delivery Date</span>
                            <input type="date" min={disabledDate()} name="delivered_date" value={order.delivered_date} onChange={handleChange} required />
                        </div>
                    </div>

                    <div className="button"  >
                        <input disabled={( order.saleman && order.delivered_date )===''} style={{background:'darkgreen'}} type="submit" value="Approve" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default OrderApprove;
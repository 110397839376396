import React, { useState, useContext, useEffect, useRef } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../shared/context/authContext";
import Loading from "../../common/Loading";

function UpdateShopPosition({ onClose,shopId,route,refresh }) {

    const [optradio, setOptradio] = useState(null)
    const [loading, setLoading] = useState(false);
    const [error,setError]=useState([])

    const [shopSearch, setShopSearch] = useState('')
    const [dropDownShop, setDropDownShop] = useState([])
    
    const auth = useContext(AuthContext)

    useEffect(()=>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=shop&limit=1000&offset=0&name=${shopSearch}&route=${route}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop for dropdown', response.data.results)
            setDropDownShop(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    },[ shopSearch])

    

    const notify = () => toast.success("Great! Position Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

   
    const[positionValue,setPositionValue] = useState(null)
    const handleChangeShop = (position, name) => {
        setPositionValue(position)
        setShopPosition(name)
        setShowShop(false)
    }

    const [shopPosition, setShopPosition] = useState("Select a Shop")
    const [showShop, setShowShop] = useState(false)
    const handleToggleShop = (e) => {
        e.target.focus();
        setShowShop(!showShop);
    }

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();

        let position
        if (optradio === 'End') {
            position = 0
        }
        else if (optradio === 'Begining') {
            position = 1
        }
        else if (optradio === 'After') {
            position = positionValue+1
        }
        else if (optradio === 'Before') {
            position = positionValue
        }
        console.log(position)

        const payload = {

            position: position,
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/update/${shopId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            onClose(false)
            refresh();
            notify();
            setLoading(false)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)
                setLoading(false)

            })

    }

    return (
        <div className="dialoguePage">
            {loading && <Loading/>}
            <div className="dialogueBox" style={{ height: "350px",width:'700px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Update Shop Position</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={()=>onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <h4 style={{ marginTop: "5px" }}>Where do you want to Update?</h4>
                <div className="radio-option">
                    <div className="radio-item">
                        <label>
                            <input type="radio" name="optradio" value='Begining' onChange={(e)=>setOptradio(e.target.value)} />At Begining
                        </label>
                    </div>

                    <div className="radio-item">
                        <label >
                            <input type="radio" name="optradio" value='End' onChange={(e)=>setOptradio(e.target.value)} />At End
                        </label>
                    </div>

                    <div className="radio-item">
                        <label >
                            <input type="radio" name="optradio" value='Before' onChange={(e)=>setOptradio(e.target.value)} />Before a Shop
                        </label>
                    </div>

                    <div className="radio-item">
                        <label >
                            <input type="radio" name="optradio" value='After' onChange={(e)=>setOptradio(e.target.value)} />After a Shop
                        </label>
                    </div>
                </div>

                <form>
                    <div className="user-details">
                    
                        <div className="input-box" style={{ width: '100%' }}>
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Before/After</span><span style={{color:'red',fontWeight:'400'}}>{error.position?error.position:''}</span></div>


                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={shopPosition}
                                        className="dropdown"
                                        onClick={handleToggleShop}
                                        disabled={(optradio === "Before" || optradio === 'After')?false:true}
                                    />
                                </label>
                                <div hidden={!showShop} className="dropdown-list"  style={{width:'94%'}}>
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setShopSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownShop.map((DShop) => (
                                            <li className="option" onClick={() => handleChangeShop(DShop.position, DShop.name)} key={DShop.id}>{DShop.name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>

                        </div>


                    </div>
                    <div className="button" >
                        <input disabled={optradio===null || ((optradio==='After' ||optradio==='Before'  )&& positionValue===null)} style={{background:'darkgreen'}} type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default UpdateShopPosition;
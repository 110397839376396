import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function AddStock({ onClose, orderId, forRefresh}) {

    const auth = useContext(AuthContext)
    const [error, setError] = useState([])
    const [productSearch, setProductSearch] = useState('')
    const [valueProduct, setValueProduct] = useState("Select a Product")
    const [dropdownProduct, setDropdownProduct] = useState([])
    const [showProduct, setShowProduct] = useState(false)
    const [stock, setStock] = useState({
        product: "",
        quantity: '',
        order: orderId
    })

    const notify = () => toast.success("Great! Purchase Added Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setStock({
            ...stock, [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=product&limit=1000&offset=0&search=${productSearch}&ordering=price&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('product for dropdown', response.data.results)
            setDropdownProduct(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [productSearch])

    const handleToggleProduct = (e) => {
        e.target.focus();
        setShowProduct(!showProduct);
    }
    const handleChangeProduct = (DPrdt) => {
        setStock({...stock, product: DPrdt.id})
        setValueProduct(DPrdt.name+" "+DPrdt.weight+" "+findUnit(DPrdt.unit))
        setShowProduct(false)
        
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = stock
        console.log(payload)

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`sales/stock/create/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            notify();
            onClose(false)
            forRefresh();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)
            })

    }
    const findUnit=(value)=>{
        if(value===101){
            return 'KG'
        }
        else if(value===201){
            return 'LTR'
        }
        else if(value===301){
            return 'GM'
        }
        else if(value===401){
            return 'ML'
        }
    }




    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: 'fit-content'}}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Add Stock</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>

            <form>
            <div className="user-details" >
            <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Product*</span><span style={{color:'red',fontWeight:'400'}}>{error.product?error.product:''}</span></div>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={valueProduct}
                                        className="dropdown"
                                        onClick={handleToggleProduct}
                                    />
                                </label>
                                <div hidden={!showProduct} className="dropdown-list"  >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setProductSearch(e.target.value)} />
                                    <ul   >
                                        {dropdownProduct.map((DPrdt) => (
                                            <li className="option" onClick={() => handleChangeProduct(DPrdt)} key={DPrdt.id}>{DPrdt.name} {DPrdt.weight} {findUnit(DPrdt.unit)}  </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
         
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Quantity*</span><span style={{color:'red',fontWeight:'400'}}>{error.quantity?error.quantity:''}</span></div>
                            <input type="number" placeholder="Enter Quantity" name="quantity" value={stock.quantity} onChange={handleChange} required />
                        </div>
  
                    </div>
                  
                    <center><span style={{ color: "red" }}>{error!==null && (error.non_field_errors?"Product already Added":'')}</span></center>
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddStock;
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../shared/context/authContext'
import './viewUser.css'
import MainHeader from '../../common/components/MainHeader';
import { useParams } from 'react-router-dom'
import Sidebar from '../../common/components/Sidebar';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ImageViewer from '../../common/components/ImageViewer'
import axios from 'axios';
import Loading from '../../common/Loading';
import EditPhoto from '../popupForms/EditPhoto';
import EditPassword from '../popupForms/EditPassword';
import { useNavigate } from 'react-router-dom';
import capitalize from '../../common/Capitalize';
import AddExpense from '../popupForms/AddExpense';
import { getCurrentDate } from '../../common/GetCurrentDate';
import ConfirmPopUp from '../../common/components/ConfirmPopUp';
import DownloadPerformance from '../popupForms/DownloadPerformance';
import getTime from '../../common/GetTime';

const ViewUser = () => {

    const todaysDate = () => {
        var today, dd, mm, yy;
        today = new Date()
        dd = today.getDate();
        mm = today.getMonth() + 1
        yy = today.getFullYear()
        dd < 10 ? dd = '0' + dd : dd = dd
        mm < 10 ? mm = '0' + mm : mm = mm
        return yy + '-' + mm + '-' + dd
    }

    const openMap = (lat, lon) => {
        const url = `https://www.google.com/maps?q=${lat},${lon}`;
        var top = window.screen.height - 600;
        top = top > 0 ? top / 2 : 0;

        var left = window.screen.width - 800;
        left = left > 0 ? left / 2 : 0;

        var uploadWin = window.open(url, "location", "width=800,height=600" + ",top=" + top + ",left=" + left);
        uploadWin.moveTo(left, top);
        uploadWin.focus();
    }

    const [expenseDate, setExpenseDate] = useState(todaysDate());
    const current = new Date();
    let navigate = useNavigate()
    const auth = useContext(AuthContext)
    const [addExpense, setAddExpense] = useState(false)
    const [dialogue, setDialogue] = useState(false)
    const [downloadPerfmc, setDownloadPerfmc] = useState(false)
    const [enableEdit, setEnableEdit] = useState(false)
    const [updatePhoto, setUpdatePhoto] = useState(false)
    const [updatePassword, setUpdatePassword] = useState(false)
    const [forUpdateAadhar, setForUpdateAadhar] = useState(false)
    const [tab, setTab] = useState(localStorage.getItem('EmpOrder') || 'details')
    const userId = useParams().userId
    const [loading, setLoading] = useState(true);
    const [showAadhar, setShowAadhar] = useState(false)
    const [showPhoto, setShowPhoto] = useState(false)
    const [performance, setPerformance] = useState()
    const [reLoad, setReLoad] = useState(true);
    const [order, setOrder] = useState([]);
    const [expense, setExpense] = useState([]);
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();
    const [filterOpen, setFilterOpen] = useState(false)
    const [filter, setFilter] = useState(false)
    const [routeId, setRouteId] = useState('')

    const [date, setDate] = useState();
    const [forDate, setForDate] = useState('');
    const changeDate = (e) => {
        let selectedDate = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
        setForDate(selectedDate);
        setDate(e.target.value)
    }

    const [dropDownRoute, setDropDownRoute] = useState([])
    const [routeSearch, setRouteSearch] = useState('')
    const [showRoute, setShowRoute] = useState(false)
    const [valueRoute, setValueRoute] = useState("Select a Route")
    const handleToggleRoute = (e) => {
        e.target.focus();
        setShowRoute(!showRoute);
    }
    const handleChangeRoute = (id, name) => {
        setRouteId(id)
        setValueRoute(name)
        setShowRoute(false)
    }

    useEffect(() => {
        const storedOrderUrl = localStorage.getItem('viewUserOrderTrackUrl');
        const storedOrderData = JSON.parse(localStorage.getItem('viewUserOrderPageDetails'));
        if (tab === 'order') {

            if (storedOrderData && storedOrderUrl) {
                setCountA(storedOrderData.countA)
                setCountB(storedOrderData.countB)

                axios({
                    method: 'get',
                    url: storedOrderUrl,
                    headers: {
                        //  'Authorization': `bearer ${token}`,
                        'bearer': auth.token,
                        'user-id': auth.userId,
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    console.log(response)
                    setOrder(response.data.results);
                    setNext(response.data.next);
                    setCount(response.data.count)
                    setPrevious(response.data.previous);
                    setLoading(false)
                    setPrevCountB(response.data.results.length)
                }
                )
                    .catch((error) => {
                        console.log('error', error.response.data)

                    })
            }


            else {
                forOrder();
            }
        }

    }, [filter])

    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ' ' + month + ' ' + year;
        return time;
    }

    const notify = () => toast.success("Great! Employee Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


    const [performCalender, setPerformCalender] = useState({
        month: current.getMonth() + 1,
        year: current.getFullYear(),
    })
    const [employee, setEmployee] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        username: '',
        type: ''
    })
    const handleChange = (e) => {
        setEmployee({
            ...employee, [e.target.name]: e.target.value
        });
    }

    const handleDateChange = (e) => {
        setPerformCalender({
            ...performCalender, [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `accounts/user-details/${userId}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('employee', response)
            setEmployee(response.data);
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })

    }, [reLoad, updatePhoto, forUpdateAadhar]
    )
    const actionUser = (status) => {
        const payload = {
            is_active: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `accounts/user-details/${userId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }


    const onUpdate = () => {


        const payload = {
            username: employee.username,
            first_name: capitalize(employee.first_name),
            last_name: capitalize(employee.last_name),
            email: employee.email,
            phone_number: employee.phone_number
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `accounts/user-details/${userId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)
            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `accounts/user/performance/?user=${userId}&year=${performCalender.year}&month=${performCalender.month}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('Performance', response)
            setPerformance(response.data);

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })

    }, [performCalender]
    )

    const findUserType = (type) => {
        if (type == 201) {
            return 'executive'
        }
        else if (type == 301) {
            return 'salesman'
        }
        else if (type == 401) {
            return 'driver'
        }
    }

    const forOrder = () => {
        setLoading(true)
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/order/list/?route=${routeId}&${findUserType(localStorage.getItem('EmpType') ? localStorage.getItem('EmpType') : employee.type)}=${userId}&ordering=-date&date=${forDate}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('order', response)
            setOrder(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const onSwitchPage = (url, action) => {
        localStorage.removeItem('viewUserOrderPageDetails')
        localStorage.setItem('viewUserOrderTrackUrl', url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setOrder(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)


            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const expenseDetails = (url, action) => {
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setExpense(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            setCount(response.data.count)
            if (action === 'start') {
                setCountA(1)
                setCountB(response.data.results.length)


            }
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)


            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/model/list/?model=route&limit=1000&offset=0&name=${routeSearch}&type=201`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('route for dropdown', response.data.results)
            setDropDownRoute(response.data.results)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [routeSearch])

    const applyFilter = () => {
        setFilter(!filter)
        setFilterOpen(!filterOpen)
        setCountA(1)
    }
    const clearFilter = () => {
        setFilter(!filter)
        setFilterOpen(!filterOpen)
        setForDate('')
        setRouteId('')
        setValueRoute('Select a Route')
        setDate('')
        setCountA(1)

    }

    const findOrderStatus = (ordId) => {
        if (ordId === 101) {
            return 'Ongoing Order'
        }
        else if (ordId === 201) {
            return 'Ongoing Delivery'
        }
        else if (ordId === 301) {
            return 'Completed Order'
        }
        else if (ordId === 401) {
            return 'Cancelled Order'
        }
    }
    const findColor = (ordId) => {
        if (ordId === 101) {
            return 'blue'
        }
        else if (ordId === 201) {
            return 'darkorange'
        }
        else if (ordId === 301) {
            return 'green'
        }
        else if (ordId === 401) {
            return 'red'
        }
    }
    const [updatedExpense,setUpdatedExpense]=useState({})
    const [updatedExpenseId,setUpdatedExpenseId]=useState()
    const changeExpenseValue = (e,id) =>{
        const updatedData = expense.map((item)=>
        item.id ===id?{...item,[e.target.name]:e.target.value}:item)
        setExpense(updatedData)
        setUpdatedExpense({...updatedExpense,[e.target.name]:e.target.value})
    }

    const handleUpdateExpense = (e) =>{
        e.preventDefault();
        setEnableEdit(false)
        setUpdatedExpenseId()
        const payload = updatedExpense
        console.log('updated',payload)
        
        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/expense/update/${updatedExpenseId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const deleteExpense = (id) => {
        let notify = () => toast.success("Great! Expense deleted Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

        axios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_URL + `sales/expense/delete/${id}/`,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            notify();
            expenseDetails(process.env.REACT_APP_BACKEND_URL + `sales/expense/list/?user=${userId}&date=${getCurrentDate()}`, 'start')
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
            })

    }
    const deleteUser = () => {
        const payload = {
            is_delete: true
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `accounts/user-details/${userId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            navigate(`/`)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }


    return (
        <div>

            <Sidebar />
            <div class="content">
                {loading && <Loading />}
                <ToastContainer />
                {addExpense && <AddExpense onClose={() => setAddExpense(false)} forReload={() => setReLoad(!reLoad)} userId={userId}
                refresh={()=> expenseDetails(process.env.REACT_APP_BACKEND_URL + `sales/expense/list/?user=${userId}&date=${getCurrentDate()}`, 'start')} />}
                {showPhoto && <ImageViewer onClose={setShowPhoto} name='Profile Photo' image={employee.profile_pic} />}
                <MainHeader />
                {updatePhoto && <EditPhoto onClose={setUpdatePhoto} userId={userId} name='Photo' />}
                {updatePassword && <EditPassword onClose={setUpdatePassword} userId={userId} />}
                {loading && <Loading />}
                <div class="main-container">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', marginBottom: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}><img src={employee.profile_pic} style={{ borderRadius: '50%', boxShadow: '0 0 0.5em' }} height='100px' width='100px' onClick={() => setShowPhoto(true)} />
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                    <h2 style={{ marginLeft: '20px' }}>{employee.first_name} {employee.last_name} </h2>

                                    <div className="toggle-container" style={{ marginTop: '-5px', marginLeft: '20px' }}>

                                        <div className="toggle-switch" onClick={(e) => actionUser(employee.is_active)} >
                                            <input readOnly type="checkbox" className="checkbox" checked={employee.is_active}
                                                name='label' />
                                            <label className="label" >
                                                <span className="inner" />
                                                <span className="switch" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <span onClick={() => setUpdatePhoto(true)} style={{ cursor: 'pointer', color: 'blue', fontWeight: '600' }}>Edit photo <i style={{ color: 'blue' }} class="fa-solid fa-pen"></i></span>
                            </div>
                        </div>
                        <div>
                        <button className='button-31' onClick={() => setUpdatePassword(true)}>Change Password</button>
                        <button className='button-31' onClick={()=>setDialogue(true)} style={{background:'#FF0000',marginLeft:'10px'}} > <i class="fa-solid fa-trash"></i> Delete Employee</button>
                        </div>
                    </div>
                    <hr />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <div class="tabs" style={{ marginTop: '20px', marginLeft: '0px' }}>
                            <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab === 'details' ? "activetab" : "tab"} onClick={() => { setTab('details'); setCountA(1) }} >Personal Details</div>
                           <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab === 'perform' ? "activetab" : "tab"} onClick={() => { setTab('perform'); setCountA(1) }} >Performance Details </div>
                            {employee.type !== 401 && <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab === 'order' ? "activetab" : "tab"} onClick={() => { setTab('order'); setCountA(1); forOrder(); localStorage.setItem('EmpOrder', 'order'); localStorage.setItem('EmpType', employee.type); localStorage.removeItem('viewUserOrderPageDetails'); localStorage.removeItem('viewUserOrderTrackUrl') }} >Order Details </div>}
                            <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab === 'expense' ? "activetab" : "tab"} onClick={() => { setTab('expense'); setCountA(1); expenseDetails(process.env.REACT_APP_BACKEND_URL + `sales/expense/list/?user=${userId}&date=${getCurrentDate()}`, 'start') }} >Expenses</div>
                        
                            <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab === 'log' ? "activetab" : "tab"} onClick={() => { setTab('log');expenseDetails(process.env.REACT_APP_BACKEND_URL + `accounts/location/list/?user=${userId}&created_on=${expenseDate}`, 'start') }}>Location Log</div>
                           
                        </div>

                        <div style={{ position: 'relative' }}>
                            {tab === 'details' && <button className='button-31' style={{ background: 'green', color: 'white' }} onClick={onUpdate} >Save</button>}
                            {tab === 'expense' && <button className='button-31' style={{ background: 'blue', color: 'white' }} onClick={() => setAddExpense(true)} >Add Expense</button>}
                            {tab === 'order' && <button className='filter' onClick={() => { setFilterOpen(!filterOpen); localStorage.removeItem('viewUserOrderTrackUrl') }}>
                                <i class="fa-solid fa-filter"></i> Filters
                            </button>}

                            {filterOpen &&
                                <div style={{ paddingRight: '20px', position: 'absolute', top: '38px', right: '15px', height: '160px', width: '300px', backgroundColor: 'white', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}>

                                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                                        <span style={{ marginRight: '10px' }}>  Route : </span>
                                        <div >
                                            <label className="filter-arrow">
                                                <textarea
                                                    readOnly
                                                    type="button"
                                                    value={valueRoute}
                                                    className='filter-dropdown'
                                                    onClick={handleToggleRoute}
                                                />
                                            </label>
                                            <div hidden={!showRoute} className='filter-dropdown-list' >
                                                <input type='text' className='filter-search' placeholder="Type to Search..." onChange={(e) => setRouteSearch(e.target.value)} />
                                                <ul   >

                                                    {dropDownRoute.map((DRoute) => (
                                                        <li className="option" onClick={() => handleChangeRoute(DRoute.id, DRoute.name)} key={DRoute.id}>{DRoute.name} </li>

                                                    ))}



                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                                        <span style={{ marginRight: '10px' }}>Date : </span>
                                        <input className='filter-dropdown' type='date' style={{ width: '62%' }} value={date} onChange={changeDate} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                                        <button onClick={clearFilter} style={{ background: 'grey', color: 'white', padding: '4px 10px', borderRadius: '5px', borderColor: 'grey', marginRight: '20px' }}>Clear</button>
                                        <button onClick={applyFilter} style={{ background: 'blue', color: 'white', padding: '4px 10px', borderRadius: '5px', borderColor: 'blue' }}>Apply</button>
                                    </div>
                                </div>}


                        </div>
                    </div>


                    {/* <h3 style={{ color: "#060082", marginTop: "10px" }}>Personal Details</h3> */}
                    {tab === 'details' && <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px' }}>

                        <div className="input-box">
                            <span className="details">First Name</span>
                            <input type="text" value={employee.first_name} name="first_name" onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Last Name</span>
                            <input type="text" value={employee.last_name} name="last_name" onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">UserName</span>
                            <input type="text" value={employee.username} name="username" onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Email</span>
                            <input type="text" value={employee.email} name="email" onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Phone Number</span>
                            <input type="text" value={employee.phone_number} name="phone_number" onChange={handleChange} required />
                        </div>



                        <div className="input-box">
                            <span className="details">Role</span>
                            <input type="text" value={findUserType(employee.type)} readOnly name="type" onChange={handleChange} required />
                        </div>
                        {employee.driving_lc &&
                            <div className="input-box">
                                <span className="details">Licence Number</span>
                                <input type="text" value={employee.driving_lc} readOnly name="driving_lc" onChange={handleChange} required />
                            </div>
                        }
                        <div className="input-box">
                            <span className="details">Aadhar</span>
                            <div className='aadhar' onClick={() => setShowAadhar(true)}> <i class="fa-solid fa-file"></i> Click here to View</div>
                        </div>
                        {employee.driving_lc &&

                            <div className="input-box">

                            </div>
                        }


                        {showAadhar && <ImageViewer onClose={setShowAadhar} name='Aadhar Photo' image={employee.aadhar} userId={userId} setForUpdateAadhar={() => setForUpdateAadhar(!forUpdateAadhar)} />}
                    </div>}
                    {/* <hr />
                    <h3 style={{ color: "#060082", marginTop: "10px" }}>User Performance</h3> */}
                    {tab === 'perform' && <div>

                    {employee.type === 201 && 
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px' }}>
                            <div className="input-box-performance" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                                Orders : <span style={{ fontWeight: '600', fontSize: '20px' }}> {performance ? performance.data.orders : ''}</span>
                            </div>
                            <div className="input-box-performance" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                                Bills : <span style={{ fontWeight: '600', fontSize: '20px' }}> {performance ? performance.data.shop_bills : ''} </span>
                            </div>
                            <div className="input-box-performance" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                                Bill Amount : <i class="fa-solid fa-indian-rupee-sign"></i><span style={{ fontWeight: '600', fontSize: '20px' }}> {performance ? performance.data.bill_amount : ''} </span>
                            </div>
                            <div className="input-box-performance" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                                Total Expense : <i class="fa-solid fa-indian-rupee-sign"></i><span style={{ fontWeight: '600', fontSize: '20px' }}> {performance ? performance.data.Total_expenses : ''} </span>
                            </div>
                            <div className="input-box-performance" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                                Balance Amount : <i class="fa-solid fa-indian-rupee-sign"></i><span style={{ fontWeight: '600', fontSize: '20px' }}> {performance ? (performance.data.bill_amount)-(performance.data.Total_expenses) : ''} </span>
                            </div>
                            
                        </div>}

                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <div><span>Month : </span>
                                    <select style={{ padding: '5px', width: '80px' }} onChange={handleDateChange} name="month" value={performCalender.month}>
                                        {months.map((month, i) => (
                                            <option key={i} value={i + 1}>{month}</option>
                                        ))}
                                    </select></div>
                                <div style={{ marginLeft: '20px' }}><span>Year : </span><input style={{ padding: '5px', width: '80px' }} type="number" value={performCalender.year} name="year" onChange={handleDateChange} /></div>
                            </div>
                            <button onClick={()=>setDownloadPerfmc(true)} style={{background:'white',color:'black'}} className='button-31'><i class="fa-solid fa-download"></i> Download</button>
                            <table >

                                <thead>
                                    <td>Date</td>
                                    {employee.type === 201 && <> <td>Bills</td>

                                    <td>Amount</td>
                                    <td>First Bill</td>
                                    <td>Last Bill</td></>}
                                    <td>Collected Amount</td>
                                    <td>Expense</td>

                                </thead>
                                <tbody>
                                    {performance && performance.data && performance.data.daily_performance && performance.data.daily_performance.map((performance) => (
                                        <tr  >
                                            <td>{performance.date}</td>
                                            {employee.type === 201 && <><td>{performance.bills}</td>
                                            <td>&#8377; {performance.amount ? performance.amount : '0'}</td>
                                            <td>{performance.log_in}</td>
                                            <td>{performance.log_out}</td></>}
                                            <td>&#8377; {performance.collected ? performance.collected : '0'}</td>
                                            <td>&#8377; {performance.expense ? performance.expense : '0'}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                        </div>
                    </div>}


                    {tab === 'order' && <div>


                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '10px' }}>
                            <table >

                                <thead>

                                    <td>Route</td>
                                    <td>Date</td>
                                    <td>Status</td>
                                </thead>
                                <tbody>
                                    {order.map((order) => (
                                        <tr onClick={() => { navigate(`/order/${order.id}`); localStorage.setItem('viewUserOrderPageDetails', JSON.stringify({ 'countA': countA, 'countB': countB, 'count': count })) }} >
                                            {/* <tr> */}
                                            <td>{order.route ? order.route.name : 'No Route'}</td>
                                            <td>{timeConverter(order.date)}</td>
                                            <td style={{ color: findColor(order.status) }}>{findOrderStatus(order.status)}</td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                        </div>

                        <hr />
                        <div className="switchbutton">
                            {(previous || next) && <div>

                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {order.length !== 0 && <div style={{ paddingLeft: '30px' }}>Showing {countA}-{countB} of {count}</div>}
                        </div>
                    </div>}

                    {tab === 'expense' && <div>
                        <div class="search" >
                            <input type="date" value={expenseDate}
                                onChange={
                                    (e) => {
                                        expenseDetails(process.env.REACT_APP_BACKEND_URL + `sales/expense/list/?user=${userId}&date=${parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))}`,
                                            'start'
                                        );
                                        setExpenseDate(e.target.value)
                                    }
                                } />

                        </div>
                        <table >

                            <thead>

                                <td>Date</td>
                                <td>Amount</td>
                                <td>Note</td>
                                <td>Action</td>
                            </thead>
                            <tbody>
                                {expense.map((expense) => (
                                    <tr key={expense.id} style={{height:'10px'}}>
                                        <td>{timeConverter(expense.created_on)}</td>
                                        <td><div>&#8377;<input readOnly={(enableEdit===true && expense.id===updatedExpenseId)?false:true} style={{width:'70px',fontSize:'16px',border:enableEdit && expense.id===updatedExpenseId?'1px solid black':'none',background:'transparent',padding:'5px',outline:'none'}} name='amount' type='number' onChange={(e)=>{changeExpenseValue(e,expense.id)}} value={expense.amount}/></div> </td>
                                        <td><textarea rows={1} readOnly={(enableEdit===true && expense.id===updatedExpenseId)?false:true} style={{fontSize:'16px',border:enableEdit && expense.id===updatedExpenseId?'1px solid black':'none',resize:'none',background:'transparent',padding:'5px',outline:'none',width:'100%'}} name='note' type='text' onChange={(e)=>{changeExpenseValue(e,expense.id)}} value={expense.note}/></td>
                                        <td style={{width:'100px'}}> 
                                        {updatedExpenseId!==null && expense.id!==updatedExpenseId?<i style={{ cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen" onClick={()=>{setEnableEdit(true);setUpdatedExpense({});setUpdatedExpenseId(expense.id)}}></i>:
                                        <i style={{cursor: 'pointer',color: 'green',fontSize:'20px',fontWeight:'900' }} class="fa-sharp fa-solid fa-check" onClick={handleUpdateExpense}></i>}
                                         <i style={{paddingLeft:'25px', cursor: 'pointer', color: 'red' }} class="fa-solid fa-trash" onClick={()=>deleteExpense(expense.id)}></i></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <hr />
                        <div className="switchbutton">
                            {(previous || next) && <div>

                                <button disabled={previous === null} className="nextbtn" onClick={() => expenseDetails(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => expenseDetails(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {expense.length !== 0 && <div style={{ paddingLeft: '30px' }}>Showing {countA}-{countB} of {count}</div>}
                        </div>
                    </div>}

                    {tab === 'log' && <div>
                        <div class="search" >
                            <input type="date" value={expenseDate}
                                onChange={
                                    (e) => {
                                        expenseDetails(process.env.REACT_APP_BACKEND_URL + `accounts/location/list/?user=${userId}&created_on=${e.target.value}`,
                                            'start'
                                        );
                                        setExpenseDate(e.target.value)
                                    }
                                } />

                        </div>
                        <table >

                            <thead>

                                <td>Time</td>
                                <td>Location</td>
                            </thead>
                            <tbody>
                                {expense.map((expense) => (
                                    <tr key={expense.id} style={{height:'10px'}}>
                                        <td><td>{getTime(expense.created_on)}</td></td>
                                        <td><td><button onClick={(e) =>{e.stopPropagation();openMap(expense.latitude,expense.longitude)}} className='button-31' style={{ border: '1px solid grey',padding:'0px 5px',background:'white',color:'black' }}><i style={{color:'blue'}} class="fa-solid fa-location-dot"></i>Open map</button></td></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <hr />
                        <div className="switchbutton">
                            {(previous || next) && <div>

                                <button disabled={previous === null} className="nextbtn" onClick={() => expenseDetails(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => expenseDetails(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {expense.length !== 0 && <div style={{ paddingLeft: '30px' }}>Showing {countA}-{countB} of {count}</div>}
                        </div>
                    </div>}

                </div>
            </div>


            {dialogue && <ConfirmPopUp deleteText='Delete' onDialog={setDialogue} onAction={deleteUser} />}
            {downloadPerfmc && <DownloadPerformance onClose={()=>setDownloadPerfmc(false)} userId={userId}/>}
        </div>

    )

}
export default ViewUser
import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function AddPurchase({ onClose, bill, setRefresh, shopId, orderType, orderId }) {

    const auth = useContext(AuthContext)
    const [error, setError] = useState([])
    const [selectedPrdt, setSelectedPrdt] = useState()
    const [balance, setBalance] = useState('')
    const [buttonCheck,setButtonCheck]=useState('price')
    const [lastPrice, setLastPrice] = useState('empty')
    const [productSearch, setProductSearch] = useState('')
    const [valueProduct, setValueProduct] = useState("Select a Product")
    const [dropdownProduct, setDropdownProduct] = useState([])
    const [showProduct, setShowProduct] = useState(false)
    const [purchase, setPurchase] = useState({
        bill: bill,
        product: "",
        quantity: '',
        price: '',
        discount: ''
    })

    const notify = () => toast.success("Great! Purchase Added Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setPurchase({
            ...purchase, [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        if(orderType===101){
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=product&limit=1000&offset=0&search=${productSearch}&ordering=price&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('product for dropdown', response.data.results)
            setDropdownProduct(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
        }
        else{
            axios({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL+`sales/stock/list/?search=${productSearch}&order=${orderId}`,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
    
                console.log('product for dropdown', response.data.results)
                setDropdownProduct(response.data.results)
            }
            )
                .catch((error) => {
                    console.log('error', error.response.data)
    
                })
        }

    }, [productSearch])

    const handleToggleProduct = (e) => {
        e.target.focus();
        setShowProduct(!showProduct);
    }
    const handleChangeProduct = (DPrdt, stock) => {
        setPurchase({...purchase, price: DPrdt.price, product: DPrdt.id})
        setValueProduct(DPrdt.name+" "+DPrdt.weight+" "+findUnit(DPrdt.unit))
        setShowProduct(false)
        findLastPrice(DPrdt.id);
        setSelectedPrdt(DPrdt)
        
        setButtonCheck('price')
        setBalance(stock.balance)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = purchase
        console.log(payload)
        if (purchase.quantity>balance){
            console.log('error')
        }
        else{
        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`sales/purchase/create/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setPurchase(
                {
                    product: "",
                    quantity: '',
                    price: '',
                    discount: ''
                }
            )
            notify();
            onClose(false)
            setRefresh();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)
            })
        }

    }
    const findUnit=(value)=>{
        if(value===101){
            return 'KG'
        }
        else if(value===201){
            return 'LTR'
        }
        else if(value===301){
            return 'GM'
        }
        else if(value===401){
            return 'ML'
        }
    }

    const findLastPrice=(id)=>{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/last-price/?product=${id}&shop=${shopId}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('price', response.data.data.last_price)
            setLastPrice(response.data.data.last_price)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const handleChangePriceType =(e)=>{
        if(e.target.value==='price'){
            setPurchase({...purchase, price: selectedPrdt.price})
            setButtonCheck('price')
        }
        else if(e.target.value==='wholesale_price'){
            setPurchase({...purchase, price: selectedPrdt.wholesale_price?selectedPrdt.wholesale_price:''})
            setButtonCheck('wholesale_price')
        }
        else if(e.target.value==='supermarket_price'){
            setPurchase({...purchase, price: selectedPrdt.super_market_price?selectedPrdt.super_market_price:''})
            setButtonCheck('supermarket_price')
        }
        else if(e.target.value==='distributor_price'){
            setPurchase({...purchase, price: selectedPrdt.distributor_price?selectedPrdt.distributor_price:''})
            setButtonCheck('distributor_price')
        }
        else if(e.target.value==='retail_price'){
            setPurchase({...purchase, price: selectedPrdt.retail_price?selectedPrdt.retail_price:''})
            setButtonCheck('retail_price')
        }
    }


    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '500px',width:'1030px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Create Purchase</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>

            <form>
            <div className="user-details" >
            <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Product*</span><span style={{color:'red',fontWeight:'400'}}>{error.product?error.product:''}</span></div>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={valueProduct}
                                        className="dropdown"
                                        onClick={handleToggleProduct}
                                    />
                                </label>
                                <div hidden={!showProduct} className="dropdown-list"  style={{width:'475px'}}>
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setProductSearch(e.target.value)} />
                                    <ul   >
                                        {dropdownProduct.map((DPrdt) => (
                                            <li className="option" onClick={() => handleChangeProduct(orderType===201?DPrdt.product:DPrdt, DPrdt)} key={orderType===201?DPrdt.product.id:DPrdt.id}>{orderType===201?DPrdt.product.name:DPrdt.name} {orderType===201?DPrdt.product.weight:DPrdt.weight} {findUnit(orderType===201?DPrdt.product.unit:DPrdt.unit)}  </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
            </div>
            </form>


                <div className="radio-option">
                    <div className="radio-item">
                        <label>
                            <input disabled={selectedPrdt?false:true} checked={buttonCheck==='price'?true:false} type="radio" name="optradio" value='price' onChange={handleChangePriceType} />Price: &#8377;{selectedPrdt && selectedPrdt.price}
                        </label>
                    </div>
                    <div className="radio-item">
                        <label >
                            <input disabled={selectedPrdt && selectedPrdt.distributor_price?false:true} checked={buttonCheck==='distributor_price'?true:false} type="radio" name="optradio" value='distributor_price' onChange={handleChangePriceType} />Distributor Price: &#8377;{selectedPrdt && selectedPrdt.distributor_price}
                        </label>
                    </div>

                    <div className="radio-item">
                        <label >
                            <input disabled={selectedPrdt && selectedPrdt.wholesale_price?false:true} checked={buttonCheck==='wholesale_price'?true:false} type="radio" name="optradio" value='wholesale_price' onChange={handleChangePriceType} />Wholesale Price: &#8377;{selectedPrdt && selectedPrdt.wholesale_price}
                        </label>
                    </div>

                    <div className="radio-item">
                        <label >
                            <input disabled={selectedPrdt && selectedPrdt.super_market_price?false:true} checked={buttonCheck==='supermarket_price'?true:false} type="radio" name="optradio" value='supermarket_price' onChange={handleChangePriceType} />Supermarket Price: &#8377;{selectedPrdt && selectedPrdt.super_market_price}
                        </label>
                    </div>
                    
                    <div className="radio-item">
                        <label >
                            <input disabled={selectedPrdt && selectedPrdt.retail_price?false:true} checked={buttonCheck==='retail_price'?true:false} type="radio" name="optradio" value='retail_price' onChange={handleChangePriceType} />Retail Price: &#8377;{selectedPrdt && selectedPrdt.retail_price}
                        </label>
                    </div>
                </div>
                <form>
                    <div className="user-details" >


                       

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Price*</span><span style={{color:'red',fontWeight:'400'}}>{error.price?error.price:''}</span></div>
                            <input type="number" placeholder="Enter Price" name="price" value={purchase.price} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Quantity*</span><span style={{color:'red',fontWeight:'400'}}>{error.quantity?error.quantity:''}{selectedPrdt && orderType===201?`Quantity should not be greater than ${balance}`:''}</span></div>
                            <input type="number" placeholder="Enter Quantity" name="quantity" value={purchase.quantity} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Discount*</span><span style={{color:'red',fontWeight:'400'}}>{error.discount?error.discount:''}</span></div>
                            <input type="number" placeholder="Enter Discount" name="discount" value={purchase.discount} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Total</span><span style={{color:'red',fontWeight:'400'}}>{error.discount?error.discount:''}</span></div>
                            <input readOnly type="number" placeholder="Enter Discount" name="discount" value={purchase.quantity*purchase.price-purchase.discount} onChange={handleChange} required />
                        </div>

                    </div>
                    {lastPrice!=='empty' &&<center>Last billed price: {lastPrice?'':'No price history'} <span style={{ color: "green",fontWeight:'bold' }}>{lastPrice}</span></center>}

                    <center><span style={{ color: "red" }}>{error!==null && (error.non_field_errors?"Product already Purchased":'')}</span></center>
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddPurchase;
import { useEffect,useState } from "react";
import EditPhoto from "../../pages/popupForms/EditPhoto";

const ImageViewer = ({image,onClose,name,userId,setForUpdateAadhar}) =>{
    const [updateAadhar, setUpdateAadhar] = useState(false)
    useEffect(()=>{
        console.log(image)
    })
return(
    <div className="dialoguePage">
        {updateAadhar && <EditPhoto onClose={setUpdateAadhar} userId={userId} name='Aadhar' setForUpdateAadhar={setForUpdateAadhar}/>}
        <div className="dialogueBox" style={{width:'960px',height:'670px'}}>
        <div className="head">
        <h2 style={{color:"#003cff"}}>{name}</h2>
        <div>
        {name==='Aadhar Photo' && <span onClick={() => setUpdateAadhar(true)} style={{marginRight:'20px',cursor:"pointer"}}><i style={{ color: 'blue' }} class="fa-solid fa-pen"></i></span>}
        <span style={{color:"red",fontSize:"20px",cursor:"pointer"}} onClick={()=>onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
        </div></div>
        <img src={image} alt="profile img" height="600px" width="920px" style={{paddingTop:'10px',marginBottom:'20px'}} />
    </div>
    </div>
)
}

export default ImageViewer;
import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import AddVehicle from './popupForms/AddVehicle';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import Loading from '../common/Loading';
import EditVehicle from './popupForms/EditVehicle';
import ViewVehicleDetails from './popupForms/ViewVehicleDetails';
import ConfirmPopUp from '../common/components/ConfirmPopUp';


const Vehicle = () => {

    const auth = useContext(AuthContext)
    const [refresh, setRefresh] = useState(false);
    const [viewVehicle, setViewVehicle] = useState('');
    const [viewVehicleDetail, setViewVehicleDetail] = useState('');
    const [searchVehicle, setSearchVehicle] = useState("");
    const [newVehicle, setNewVehicle] = useState(false)
    const [vehicle, setVehicle] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('active')
    const [active, setActive] = useState(true)
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();
    const [dialogue, setDialogue] = useState(false)
    const [deleteVehicleId, setDeleteVehicleId] = useState()


    const formHandler = () => {
        setNewVehicle(!newVehicle);
    }
    useEffect(() => {
        setCountA(1)

        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/vehicle/list/?&search=${searchVehicle}&is_active=${active}&ordering=name`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('vehicle', response)
            setVehicle(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, [searchVehicle, active, viewVehicle, refresh])

    const onSwitchPage = (url,action) => {
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setVehicle(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 12)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 12)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const actionVehicle = (status, vehicleId) => {


        const payload = {
            is_active: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/vehicle/update/${vehicleId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }
    const deleteVehicle = () => {


        const payload = {
            is_delete: true
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL + `sales/vehicle/update/${deleteVehicleId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRefresh(!refresh)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }


    return (
        <div className='full'>
         
            {newVehicle && <AddVehicle onClose={formHandler} forRefresh={()=>setRefresh(!refresh)}/>}
            <Sidebar name="vehicle" />
            <ToastContainer />
            {viewVehicle !== '' && <EditVehicle VehicleId={viewVehicle} onClose={setViewVehicle} />}
            {viewVehicleDetail !== '' && <ViewVehicleDetails vehicle={viewVehicleDetail} onClose={()=>setViewVehicleDetail('')} />}
            <div class="content">
            {loading && <Loading/>}
                <MainHeader />


                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginBottom: "10px" }}>Vehicle Details</h3>
                        <div class="search">
                            <input type="text" placeholder="search here..." onChange={(e) => setSearchVehicle(e.target.value)} />
                            <label for="search"><i class="fas fa-search"></i></label>
                        </div>
                        <button className='button-31' onClick={formHandler}>New</button>
                    </div>
                    <div class="tabs" style={{ marginLeft: '0px' }}>
                        <div class={status === 'active' ? "activetab" : "tab"} onClick={() => { setStatus('active'); setActive(true);setCountA(1) }}>Active</div>
                        <div class={status === 'notactive' ? "activetab" : "tab"} onClick={() => { setStatus('notactive'); setActive(false);setCountA(1) }}>Not-Active</div>
                    </div>
                    <div className='user-container'>
                    {vehicle.length===0 && <h4 style={{paddingLeft:'40%',paddingTop:'5%'}}>No results found.</h4>}
                        {vehicle.map((vehicle) => (
                            // <div style={{ width: 'calc(100%/4 - 40px)', margin: '20px',position:'relative' }} key={vehicle.id}  onClick={()=>setViewVehicle(vehicle.id)}>
                            <div className='vehicle-tile' key={vehicle.id}>
                                <figure class="vehicle-box" style={{ border: '3px solid grey',background:'white' }}>
                                    <div style={{background:'white'}}>
                                        <div style={{marginTop:'30px'}}><img src={vehicle.image} height='130px' width='100%' object-fit='contain' /></div>
                                        <figcaption >
                                            <h3 style={{ textAlign: 'center', paddingBottom: '3px' }}>{vehicle.name} </h3>

                                            <h3 style={{ textAlign: 'center', paddingBottom: '8px',marginTop:'0px' }}><span style={{ background: '#fff200c1', padding: '0px 10px', border: '1px solid black' }}>{vehicle.number}</span></h3>
                                           
                                            <div style={{ position: 'absolute', top: '0', left: '5px', background: 'white' }} >
                                                
                                                <div className="toggle-switch" onClick={() => actionVehicle(vehicle.is_active, vehicle.id)}>
                                                    <input readOnly type="checkbox" className="checkbox" checked={vehicle.is_active}
                                                        name='label' />
                                                    <label className="label" >
                                                        <span className="inner" />
                                                        <span className="switch" />
                                                    </label>
                                                </div>
                                                <i onClick={()=>setViewVehicle(vehicle.id)} style={{ cursor: 'pointer', color: 'blue', paddingLeft: '10px', fontSize: '18px' }} class="fa-solid fa-pen"></i>
                                                <i onClick={()=>{setDialogue(true);setDeleteVehicleId(vehicle.id)}} style={{ cursor: 'pointer', color: 'red',  fontSize: '18px',marginLeft:'130px' }} class="fa-solid fa-trash"></i>
                                                <i onClick={()=>setViewVehicleDetail(vehicle)} style={{ cursor: 'pointer',color: 'darkblue',background:'white',borderRadius:'50%',  marginLeft: '10px', fontSize: '20px' }} class="fa-solid fa-circle-info"></i>
                                            </div>
                                        </figcaption>
                                    </div>

                                </figure>


                            </div>
                        ))}

                    </div>


                </div>
                <div className="switchbutton">
                                
                                {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {vehicle.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                            </div>
            </div>
            {dialogue && <ConfirmPopUp deleteText='Delete' onDialog={setDialogue} onAction={deleteVehicle} />}
        </div>


    )
}
export default Vehicle;
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../shared/context/authContext'

import axios from 'axios';
import Loading from '../../common/Loading';
import { useNavigate } from 'react-router-dom';

const SaleBillDetail = ({ orderId }) => {

    const auth = useContext(AuthContext)
    let navigate = useNavigate()

    const [searchBill, setSearchBill] = useState("");
    const [loading, setLoading] = useState(true);
    const [bill, setBill] = useState([])
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();


    useEffect(() => {
        setLoading(true)
        const storedShopUrl = localStorage.getItem('viewSalesDeliveredTrackUrl');
        const storedShopData = JSON.parse(localStorage.getItem('viewSalesDeliveredPageDetails'));

            if (storedShopData && storedShopUrl) {
                setCountA(storedShopData.countA)


                axios({
                    method: 'get',
                    url: storedShopUrl,
                    headers: {
                        //  'Authorization': `bearer ${token}`,
                        'bearer': auth.token,
                        'user-id': auth.userId,
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    console.log(response)
                    setBill(response.data.results);
                    setNext(response.data.next);
                    setCount(response.data.count)
                    setCountB(storedShopData.countA+response.data.results.length-1) 
                    setPrevious(response.data.previous);
                    setLoading(false)
                    setPrevCountB(response.data.results.length)
                }
                )
                    .catch((error) => {
                        console.log('error', error.response.data)
        
                    })
            }
            else{
                

        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `sales/bill/list/?order=${orderId}&shop=&status=&delivery=&search=${searchBill}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('bill', response)
            setBill(response.data.results);
            setLoading(false)
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setLoading(false)

            })
    }}, [searchBill])

    const onSwitchPage = (url, action) => {
        localStorage.removeItem('viewSalesDeliveredPageDetails')
        localStorage.setItem('viewSalesDeliveredTrackUrl',url)
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setBill(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)


            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }




    return (
        <div>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                
            <div class="search">
                <input type="text" placeholder="search here..." onChange={(e) => { setSearchBill(e.target.value) }} />
                <label ><i class="fas fa-search"></i></label>
            </div>
            <div></div>
            </div>
            <table >
                <thead>
                    <td></td>
                    <td>Shop Name</td>
                    <td>Total Amount</td>
                </thead>
                {!loading &&
                    <tbody>
                        {bill.map((bill,i) => (
                            <tr key={bill.id} onClick={() =>{ navigate(`/bill/${bill.id}`);localStorage.setItem('viewSalesDeliveredPageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}))}}>
                                <td>{i+countA}</td>
                                <td>{bill.shop.name}</td>
                                <td>{bill.total_amount}</td>
                            </tr>
                        ))}

                    </tbody>}
            </table>
            <hr />
            {!loading && <div className="switchbutton">
                {(previous || next) && <div>

                    <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                    <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                </div>}
                {bill.length !== 0 && !loading && <div style={{ paddingLeft: '30px' }}>Showing {countA}-{countB} of {count}</div>}
            </div>}
        </div>
    )
}

export default SaleBillDetail;
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../shared/context/authContext'
import Dropdown from '../../common/components/DropDown';
import './viewUser.css'
import MainHeader from '../../common/components/MainHeader';
import { useParams } from 'react-router-dom'
import Sidebar from '../../common/components/Sidebar';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../common/Loading';
import EditShopPhoto from '../popupForms/EditShopPhoto';
import axios from 'axios';
import ImageViewer from '../../common/components/ImageViewer'
import capitalize from '../../common/Capitalize';
import UpdateShopPosition from '../popupForms/UpdateShopPosition';
import timeConverter from '../../common/TimeConverter';
import EditShopRoute from '../popupForms/EditShopRoute';

const ViewShop = () => {

    const openMap = (lat, lon) => {
        const url = `https://www.google.com/maps?q=${lat},${lon}`;
        var top = window.screen.height - 600;
        top = top > 0 ? top / 2 : 0;

        var left = window.screen.width - 800;
        left = left > 0 ? left / 2 : 0;

        var uploadWin = window.open(url, "location", "width=800,height=600" + ",top=" + top + ",left=" + left);
        uploadWin.moveTo(left, top);
        uploadWin.focus();
    }


    const notify = () => toast.success("Great! Shop Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    let navigate = useNavigate()
    const [updatePhoto, setUpdatePhoto] = useState(false)
    const [updatePosition, setUpdatePosition] = useState(false)
    const [showPhoto, setShowPhoto] = useState(false)
    const [loading, setLoading] = useState(true);
    const auth = useContext(AuthContext)
    const shopId = useParams().shopId
    const [tab, setTab] = useState('bill')
    const [payment, setPayment] = useState([])
    const [bill, setBill] = useState([])
    const [tab1, setTab1] = useState(localStorage.getItem('ShopViewTab') || 'shop')
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();
    const [reLoad, setReLoad] = useState(true);
    const [filterOpen, setFilterOpen] = useState(false)
    const [creator, setCreator] = useState('')
    const [dropDownUser, setDropDownUser] = useState([])
    const [userSearch, setUserSearch] = useState('')
    const [date, setDate] = useState();
    const [forDate, setForDate] = useState('');
    const [filter, setFilter] = useState(false)
    const [editRoute,setEditRoute] = useState()

    const changeDate = (e) => {
        let selectedDate = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
        setForDate(selectedDate);
        setDate(e.target.value)
    }

    const [nextPayment, setNextPayment] = useState("");
    const [previousPayment, setPreviousPayment] = useState("");
    const [countPayment, setCountPayment] = useState('');
    const [countPaymentA, setCountPaymentA] = useState(1);
    const [countPaymentB, setCountPaymentB] = useState('');
    const [prevCountPaymentB, setPrevCountPaymentB] = useState();

    const [shop, setShop] = useState({
        name: '',
        position: '',
        route_name: '',
        latitude: '',
        longitude: '',
        gst_number: '',
        location: '',
        address: '',
        phone: ''
    })

    function dateConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ' ' + month + ' ' + year;
        return time;
    }

    const handleChange = (e) => {
        setShop({
            ...shop, [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop-details/${shopId}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('shop', response)
            setShop(response.data);
            setLoading(false)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, [updatePhoto, reLoad]
    )
    useEffect(() => {
        setLoading(true)
        const storedShopUrl = localStorage.getItem('viewShopBillTrackUrl');
        const storedShopData = JSON.parse(localStorage.getItem('viewShopBillPageDetails'));

            if (storedShopData && storedShopUrl) {
                setCountA(storedShopData.countA)
                
      
                  axios({
                      method: 'get',
                      url: storedShopUrl,
                      headers: {
                          //  'Authorization': `bearer ${token}`,
                          'bearer': auth.token,
                          'user-id': auth.userId,
                          'Content-Type': 'application/json'
                      },
                  }).then((response) => {
                      console.log(response)
                      setBill(response.data.results);
                      setNext(response.data.next);
                      setCount(response.data.count)
                      setCountB(storedShopData.countA+response.data.results.length-1) 
                      setPrevious(response.data.previous);
                      setLoading(false)
                      setPrevCountB(response.data.results.length)
                  }
                  )
                      .catch((error) => {
                          console.log('error', error.response.data)
          
                      })
              }

else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/bill/list/?order=&shop=${shopId}&date=${forDate}&user=${creator}&ordering=-created_on`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('bill', response)
            setBill(response.data.results);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
            setNext(response.data.next);
            setPrevious(response.data.previous);
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }}, [filter])


    const onUpdate = () => {


        const payload = {
            name: capitalize(shop.name),
            position: shop.position,
            latitude: shop.latitude,
            longitude: shop.longitude,
            gst_number: shop.gst_number,
            location: capitalize(shop.location),
            address: capitalize(shop.address),
            phone: shop.phone
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/update/${shopId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);

            notify();

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/payment/list/?shop=${shopId}&search=&ordering=-created_on&creator=${creator}&date=${forDate}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('payment', response)
            setPayment(response.data.results);
            setLoading(false)
            setCountPayment(response.data.count)
            setCountPaymentB(response.data.results.length)
            setNextPayment(response.data.next);
            setPreviousPayment(response.data.previous);
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, [filter])

   
    const deliveryStatus = (status) => {
        if (status === 101) {
            return 'Pending'
        }
        else if (status === 201) {
            return 'Delivered'
        }
        else if (status === 301) {
            return 'Cancelled'
        }
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&type=`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('user for dropdown', response.data.results)
            setDropDownUser(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])


    const onSwitchPage = (url, action) => {
        setLoading(true)
        localStorage.removeItem('viewShopBillPageDetails')
        localStorage.setItem('viewShopBillTrackUrl',url)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setBill(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)


            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const onSwitchPaymentPage = (url, action) => {
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setPayment(response.data.results);
            setNextPayment(response.data.next);
            setPreviousPayment(response.data.previous);
            setLoading(false)
            setPrevCountPaymentB(response.data.results.length)
            if (action === 'next') {
                setCountPaymentA((value) => value + 10)
                setCountPaymentB((value) => value + response.data.results.length)


            }
            if (action === 'prev') {
                setCountPaymentA((value) => value - 10)
                setCountPaymentB((value) => value - prevCountPaymentB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }
    const actionShop = (status) => {
        const payload = {
            is_active: !status
        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/shop/update/${shopId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setReLoad(!reLoad)

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    const applyFilter = () => {
        setFilter(!filter)
        setFilterOpen(!filterOpen)
    }
    const clearFilter = () => {
        setFilter(!filter)
        setFilterOpen(false)
        setCreator('')
        setForDate('')
        setDate('')
    }
    const findUnit=(value)=>{
        if(value===101){
            return 'KG'
        }
        else if(value===201){
            return 'L'
        }
        else if(value===301){
            return 'G'
        }
        else if(value===401){
            return 'ML'
        }
    }


    return (
        <div>
            {editRoute && <EditShopRoute onClose={setEditRoute} shopId={shopId} forRefresh={()=>setReLoad(!reLoad)}/>}
            <Sidebar />
            <div class="content">
                <MainHeader />
                {loading && <Loading />}
                {showPhoto && <ImageViewer onClose={setShowPhoto} name='Shop Photo' image={shop.image} />}
                {updatePhoto && <EditShopPhoto onClose={setUpdatePhoto} shopId={shopId} />}
                {updatePosition && <UpdateShopPosition onClose={setUpdatePosition} shopId={shopId} route={shop.route} refresh={()=>setReLoad(!reLoad)} />}
                <ToastContainer />

                <div class="main-container">

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', marginBottom: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}><img src={shop.image} style={{ borderRadius: '50%', boxShadow: '0 0 0.5em' }} height='100px' width='100px' onClick={()=>setShowPhoto(true)}/>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                    <h2 style={{ marginLeft: '15px', marginBottom: '5px' }}>{shop.name}</h2>


                                    <div className="toggle-container" style={{ marginTop: '-5px', marginLeft: '20px' }}>

                                        <div className="toggle-switch" onClick={(e) => actionShop(shop.is_active)}>
                                            <input readOnly type="checkbox" className="checkbox" checked={shop.is_active}
                                                name='label' />
                                            <label className="label" >
                                                <span className="inner" />
                                                <span className="switch" />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <span onClick={() => setUpdatePhoto(true)} style={{ cursor: 'pointer', color: 'blue', marginLeft: '15px', fontWeight: '600' }}>Edit photo <i style={{ color: 'blue' }} class="fa-solid fa-pen"></i></span>
                            </div>
                        </div>
                        
                    </div>
                    <hr />

<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div class="tabs" style={{ marginTop: '20px', marginLeft: '0px' }}>
                        <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab1 === 'shop' ? "activetab" : "tab"} onClick={() => setTab1('shop')} >Shop Details</div>
                        <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: "10px" }} class={tab1 === 'Business' ? "activetab" : "tab"} onClick={() => { setTab1('Business'); localStorage.setItem('ShopViewTab', 'Business') }} >Business Details </div>
                    </div>
                    {tab1 === 'shop' && <button className='button-31' style={{  background: 'green', color: 'white' }} onClick={onUpdate} >Save</button>}
                    </div>
                    {tab1 === 'shop' &&
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px' }}>

                            <div className="input-box">
                                <span className="details">Name</span>
                                <input type="text" value={shop.name} name="name" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Route 
                                <i onClick={()=>setEditRoute(shopId)} style={{ paddingLeft: '10px', cursor: 'pointer', color: 'blue' }} class="fa-solid fa-pen"></i>
                                </span>
                                <input readOnly type="text" value={shop.route_name} name="route_name" onChange={handleChange} required />
                            </div>
                            <div className="input-box" onClick={() => setUpdatePosition(true)}>
                                <span className="details">Position</span>
                                <input readOnly type="text" value={shop.position} name="position" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Location</span>
                                <input type="text" value={shop.location} name="location" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Address</span>
                                <input type="text" value={shop.address} name="address" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Phone</span>
                                <input type="text" value={shop.phone} name="phone" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Latitude</span>
                                <input type="text" value={shop.latitude} name="latitude" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Longitude</span>
                                <input type="text" value={shop.longitude} name="longitude" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">GST Number</span>
                                <input type="text" value={shop.gst_number} name="gst_number" onChange={handleChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Created On</span>
                                <input type="text" value={dateConverter(shop.created_on)} readOnly required />
                            </div>
                            <div className="input-box">
                                <span className="details">Direction</span>
                                <div className='aadhar'><button className='button-31' style={{ border: '1px solid grey',padding:'8px 20px',background:'white',color:'black' }} onClick={() => openMap(shop.latitude, shop.longitude)}> <i style={{color:'blue'}} class="fa-solid fa-location-dot"></i> Open map</button></div>
                            </div>
                            <div className="input-box">
                                
                            </div>
                        </div>
                    }
                    {tab1 === 'Business' &&
                        <div>

                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px' }}>
                                <div className="input-box" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                                    Paid Amount : <i class="fa-solid fa-indian-rupee-sign"></i><span style={{ fontWeight: '600', fontSize: '20px' }}> {shop.business ? shop.business.paid_amount : ''}</span>
                                </div>
                                <div className="input-box" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                                    Balance Amount : <i class="fa-solid fa-indian-rupee-sign"></i><span style={{ fontWeight: '600', fontSize: '20px' }}> {shop.business ? shop.business.balance : ''}</span>
                                </div>
                                <div className="input-box" style={{ backgroundColor: 'lightblue', color: 'black', textAlign: "center", padding: '10px 0', fontSize: '18px' }}>
                                    Total Amount : <i class="fa-solid fa-indian-rupee-sign"></i><span style={{ fontWeight: '600', fontSize: '20px' }}> {shop.business ? shop.business.total_business : ''}</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                <div class="tabs" style={{ marginTop: '0px', marginLeft: '0px' }}>
                                    <div class={tab === 'bill' ? "activetab" : "tab"} onClick={() => { setTab('bill'); clearFilter() }}>Bills </div>
                                    <div class={tab === 'payment' ? "activetab" : "tab"} onClick={() => { setTab('payment'); clearFilter() }}>Payment </div>
                                    <div class={tab === 'purchased' ? "activetab" : "tab"} onClick={() => setTab('purchased')}>Purchased Products </div>
                                </div>
                                {tab !== 'purchased' && <div style={{position:'relative'}}><button className='filter' onClick={() => setFilterOpen(!filterOpen)}>
                                    <i class="fa-solid fa-filter"></i> Filters
                                </button>

                                    {filterOpen &&
                                        <div style={{ paddingRight: '20px', position: 'absolute', top: '38px', right: '15px', height: '160px', width: '300px', backgroundColor: 'white', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}>

                                            <Dropdown dropdownFor='User' dropdownElements={dropDownUser} setId={setCreator} dropdownSearch={setUserSearch} />
                                            <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                                                <span style={{ marginRight: '10px' }}>Date : </span>
                                                <input className='filter-dropdown' type='date' style={{ width: '62%' }} value={date} onChange={changeDate} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '10px' }}>
                                                <button onClick={clearFilter} style={{ background: 'grey', color: 'white', padding: '4px 10px', borderRadius: '5px', borderColor: 'grey', marginRight: '20px' }}>Clear</button>
                                                <button onClick={applyFilter} style={{ background: 'blue', color: 'white', padding: '4px 10px', borderRadius: '5px', borderColor: 'blue' }}>Apply</button>
                                            </div>
                                        </div>}

                                </div>}
                            </div>
                            {tab === 'bill' &&
                                <>
                                    <table >
                                        <thead>
                                            <td>Executive</td>
                                            <td>Bill generated</td>
                                            <td>Salesman</td>
                                            <td>Delivery Date</td>
                                            {/* <td>Delivery status</td> */}


                                        </thead>
                                        <tbody>
                                            {bill.map((bill) => (
                                                <tr key={bill.id} onClick={() => {navigate(`/bill/${bill.id}`);localStorage.setItem('viewShopBillPageDetails',JSON.stringify({'countA':countA,'countB':countB,'count':count}))}}>
                                                    <td>{bill.executive.full_name}</td>
                                                    <td>{timeConverter(bill.created_on)}</td>
                                                    <td>{bill.salesman ? bill.salesman.full_name : 'Not Assigned'}</td>
                                                    <td style={{color:bill.delivered_on?'blue':'black'}}>{bill.delivered_on ? timeConverter(bill.delivered_on) : 'Delivery Pending'}</td>
                                                    {/* <td>{deliveryStatus(bill.delivery)}</td> */}
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                    {bill.length !== 0 && <hr style={{ border: '1px solid black', marginTop: '10px' }} />}
                                    <div className="switchbutton">
                                        {(previous || next) && <div>

                                            <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                            <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                                        </div>}
                                        {bill.length !== 0 && <div style={{ paddingLeft: '10px' }}>Showing {countA}-{countB} of {count}</div>}
                                    </div>
                                </>
                            }
                            {tab === 'payment' && <>
                                <table >
                                    <thead>
                                        <td>Date & Time</td>
                                        <td>Creator</td>
                                        <td>Amount</td>
                                    </thead>
                                    <tbody>
                                        {payment.map((payment) => (
                                            <tr key={payment.id} >
                                                <td>{timeConverter(payment.time)}</td>
                                                <td style={{ fontWeight: '500' }}>{payment.creator.full_name}</td>
                                                <td>&#8377; {payment.amount}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                {payment.length !== 0 && <hr style={{ border: '1px solid black', marginTop: '10px' }} />}
                                <div className="switchbutton">
                                    {(previousPayment || nextPayment) && <div>

                                        <button disabled={previousPayment === null} className="nextbtn" onClick={() => onSwitchPaymentPage(previousPayment, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                        <button disabled={nextPayment === null} className="nextbtn" onClick={() => onSwitchPaymentPage(nextPayment, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                                    </div>}
                                    {payment.length !== 0 && <div style={{ paddingLeft: '10px' }}>Showing {countPaymentA}-{countPaymentB} of {countPayment}</div>}
                                </div></>
                            }
                            {tab === 'purchased' &&
                                <table >
                                    <thead>
                                        <td>Product</td>
                                        <td>Purchased Count</td>
                                    </thead>
                                    <tbody>
                                        {shop.business.product_history && shop.business.product_history.map((prdt) => (
                                            <tr key={prdt.product__name} >
                                                <td>{prdt.product__name} {prdt.weight} {findUnit(prdt.unit)}</td>
                                                <td>{prdt.total}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            }
                        </div>
                    }
                </div>
            </div>


        </div>


    )

}
export default ViewShop
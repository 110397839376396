import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../shared/context/authContext";

function EditOrderEmployee({ onClose, userType, odrId, forRefresh }) {

    const auth = useContext(AuthContext)
    const [error, setError] = useState()
    const [dropDownUser, setDropDownUser] = useState([])
    const [userSearch, setUserSearch] = useState('')

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&type=${userType}&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('executive for dropdown', response.data.results)
            setDropDownUser(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])

    const [userId, setUserId] = useState()
    const handleChangeUser = (id, name) => {
        setUserId(id)
        setUserValue(name)
        setShowUser(false)
    }
    const [userValue, setUserValue] = useState("Select an Employee")
    const [showUser, setShowUser] = useState(false)
    const handleToggleUser = (e) => {
        e.target.focus();
        setShowUser(!showUser);
    }

    const notify = () => toast.success("Great! Order Updated Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
    const handleSubmit = (e) => {
        e.preventDefault();
        let payload

        if (userType === '201') {
            payload = {
                executive: userId,
            }
        }
        else if (userType === '301') {
            payload = {
                salesman: userId
            }
        }
        else if (userType === '401') {
            {
                payload = {
                    driver: userId
                }
            }

        }

        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/order/update/${odrId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            forRefresh()
            notify();
            onClose('')
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px', width: '700px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Select Employee</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => onClose('')}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >


                        <div className="input-box" style={{ width: '700px' }}>



                            <div >
                                <label className="arrow" >
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={userValue}
                                        className="dropdown"
                                        onClick={handleToggleUser}
                                    />
                                </label>
                                <div hidden={!showUser} className="dropdown-list" style={{ width: '656px' }} >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setUserSearch(e.target.value)} style={{ width: '650px' }} />
                                    <ul>
                                        {dropDownUser.map((DUser) => (
                                            <li className="option" onClick={() => handleChangeUser(DUser.id, DUser.full_name)} key={DUser.id}>{DUser.full_name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <center><span style={{ color: "red" }}>{error}</span></center>
                    <div className="button" >
                        <input type="submit" value="Update" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default EditOrderEmployee;
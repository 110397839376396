import React, { useState, useContext } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";
import capitalize from "../../common/Capitalize";

function AddProduct({ onClose, forReload }) {

    const auth = useContext(AuthContext)
    const [error, setError] = useState([])
    const [product, setProduct] = useState({
        name: "",
        price: "",
        gst_percentage: '',
        weight: null,
        unit: 201,
        wholesale_price:'',
        super_market_price:'',
        distributor_price:'',
        retail_price:'',
        case_count:''

    });

    const notify = () => toast.success("Great! Product Added Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setProduct({
            ...product, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const payload = {
            name: capitalize(product.name),
            price: product.price,
            gst_percentage: product.gst_percentage,
            weight:product.weight,
            unit:product.unit,
            super_market_price:product.super_market_price?product.super_market_price:0,
            wholesale_price:product.wholesale_price?product.wholesale_price:0,
            distributor_price:product.distributor_price?product.distributor_price:0,
            retail_price:product.retail_price?product.retail_price:0,
            case_count:product.case_count

        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `sales/product/create/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setProduct(
                {
                    name: "",
                    price: "",
                }
            )
            notify();
            onClose();
            forReload()
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)
                // if(product.name.length>100){
                //     setError([...{extra:'Ensure name field has no more than 100 characters'}])
                // }
                // console.log("hello",errors)
            })




    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '630px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Add Product</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Name*</span><span style={{color:'red',fontWeight:'400'}}>{error.name?error.name:''}</span></div>
                            <input type="text" placeholder="Enter Name" name="name" value={product.name} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Price*</span><span style={{color:'red',fontWeight:'400'}}>{error.price?error.price:''}</span></div>
                            <input  type="number" placeholder="Enter Price" name="price" value={product.price} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Distributor Price</span><span style={{color:'red',fontWeight:'400'}}>{error.distributor_price?error.distributor_price:''}</span></div>
                            <input  type="number" placeholder="Enter Distributor Price" name="distributor_price" value={product.distributor_price} onChange={handleChange} required />
                        </div>

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Wholesale Price</span><span style={{color:'red',fontWeight:'400'}}>{error.wholesale_price?error.wholesale_price:''}</span></div>
                            <input  type="number" placeholder="Enter Wholesale Price" name="wholesale_price" value={product.wholesale_price} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Supermarket Price</span><span style={{color:'red',fontWeight:'400'}}>{error.super_market_price?error.super_market_price:''}</span></div>
                            <input  type="number" placeholder="Enter Supermarket Price" name="super_market_price" value={product.super_market_price} onChange={handleChange} required />
                        </div>

                        
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Retail Price</span><span style={{color:'red',fontWeight:'400'}}>{error.retail_price?error.retail_price:''}</span></div>
                            <input  type="number" placeholder="Enter Retail Price" name="retail_price" value={product.retail_price} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Case count</span><span style={{color:'red',fontWeight:'400'}}>{error.case_count?error.case_count:''}</span></div>
                            <input  type="number" placeholder="Enter case count" name="case_count" value={product.case_count} onChange={handleChange} required />
                        </div>


                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">GST%*</span><span style={{color:'red',fontWeight:'400'}}>{error.gst_percentage?error.gst_percentage:''}</span></div>
                            <input  type="number" placeholder="Enter percentage of GST" name="gst_percentage" value={product.gst_percentage} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}> <span className="details">Weight*</span><span style={{color:'red',fontWeight:'400'}}>{error.weight?error.weight:''}</span></div>
                            <input  type="number" placeholder="Enter weight" name="weight" value={product.weight} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <span className="details">Unit*</span>
                            <select className='dropdown' name="unit" value={product.unit} onChange={handleChange} >
                                
                                <option value="201">Liter</option>
                                <option value="301">Gram</option>
                                <option value="401">Milliliter</option>
                                <option value="101">Kilogram</option>
                            </select>
                        </div>
                    </div>

                    {/* <center><span style={{ color: "red" }}>{errors['extra']?errors['extra']:''}</span></center> */}
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddProduct;
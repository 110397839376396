import { AuthContext } from "./shared/context/authContext";
import { useState, useEffect, useCallback } from "react";
import { HashRouter, Route, Routes } from 'react-router-dom';
import Login from "./pages/Login";
import Employee from "./pages/Employee";
import Shop from "./pages/Shop";
import SaleRoute from "./pages/SaleRoute";
import Order from "./pages/Order";
import Bill from "./pages/Bill";
import Payment from "./pages/Payment";
import Product from "./pages/Product";
import ViewUser from "./pages/singleViewPage/ViewUsers";
import ViewRoute from "./pages/singleViewPage/ViewRoute";
import ViewShop from "./pages/singleViewPage/ViewShop";
import ViewOrder from "./pages/singleViewPage/ViewOrder";
import ViewBill from "./pages/singleViewPage/ViewBill";
import ViewProduct from "./pages/singleViewPage/ViewProduct";
import Vehicle from "./pages/Vehicle";
import CheckIn from "./pages/CheckIn";
import Credit from "./pages/Credit";


const Main = () => {

    const [mobileView, setMobileView] = useState('false');
    useEffect(() => {
        if (window.innerWidth < 1000) {
          setMobileView('true');
        }
      }, [])

    const [token, setToken] = useState(false);
    const [userId, setUserId] = useState();

    const login = useCallback((uid, token) => {
        setToken(token);
        setUserId(uid);
        localStorage.setItem('userData', JSON.stringify({ userId: uid, token: token }))
        
    }, [])
    const logout = useCallback(() => {
        setToken(null)
        setUserId(null)

        localStorage.clear()
    }, [])

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (storedData && storedData.token) {
            login(storedData.userId, storedData.token)
        }
    }, [login])

    let routes;
    if (token) {
        routes = (
            <Routes>
                <Route exact path="/" element={<Employee />}></Route>
                <Route exact path="/shop" element={<Shop />}></Route>
                <Route exact path="/route" element={<SaleRoute />}></Route>
                <Route exact path="/order" element={<Order />}></Route>
                <Route exact path="/product" element={<Product />}></Route>
                <Route exact path="/checkin" element={<CheckIn />}></Route>
                <Route exact path="/vehicle" element={<Vehicle />}></Route>
                <Route exact path="/bill" element={<Bill />}></Route>
                <Route exact path="/credit" element={<Credit />}></Route>
                <Route exact path="/payment" element={<Payment />}></Route>
                <Route exact path="/user/:userId" element={<ViewUser />}></Route>
                <Route exact path="/product/:productId" element={<ViewProduct />}></Route>
                <Route exact path="/order/:orderId" element={<ViewOrder />}></Route>
                <Route exact path="/shop/:shopId" element={<ViewShop />}></Route>
                <Route exact path="/route/:routeId" element={<ViewRoute />}></Route>
                <Route exact path="/bill/:billId" element={<ViewBill />}></Route>
                <Route path="*" element={<Login />}></Route>
                
            </Routes>
        );
    } else {
        routes = (
            <Routes>
                <Route path="/" element={<Login />}></Route>
                <Route path="*" element={<Login />}></Route>
            </Routes>
        );
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn: !!token, token: token, userId: userId, login: login, logout: logout,mobileView:mobileView,setMobileView:setMobileView }}>
            <HashRouter>
                <main>
                    {routes}
                </main>
                </HashRouter>
        </AuthContext.Provider>
    )
}

export default Main;
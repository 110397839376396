
import { useState } from 'react';

const Dropdown = ({ dropdownFor, dropdownElements, setId, dropdownSearch }) => {


    const [value, setValue] = useState(`Select ${dropdownFor}`)
    const [show, setShow] = useState(false)
    const dropdownValue = dropdownElements

    const handleToggle = (e) => {
        e.target.focus();
        setShow(!show);
    }
    const handleChange = (id, name) => {
        setValue(name);
        setShow(false);
        setId(id)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
            <span style={{ marginRight: '10px' }}>{dropdownFor} : </span>
            <div >
                <label className="filter-arrow">
                    <textarea
                        readOnly
                        type="button"
                        value={value}
                        className='filter-dropdown'
                        onClick={handleToggle}
                    />
                </label>
                <div hidden={!show} className='filter-dropdown-list' >
                    {dropdownFor !== 'User Role' &&
                        <input type='text' className='filter-search' placeholder="Type to Search..." onChange={(e) => dropdownSearch(e.target.value)} />
                    }<ul   >
                        {(dropdownFor === 'User' || dropdownFor === 'User Role') &&
                            dropdownValue.map((Dvalue) => (
                                <li className="option" onClick={() => handleChange(Dvalue.id, Dvalue.full_name)} key={Dvalue.id}>{Dvalue.full_name} </li>

                            ))}

                        {dropdownFor === 'Shop' &&
                            dropdownValue.map((Dvalue) => (
                                <li className="option" onClick={() => handleChange(Dvalue.id, Dvalue.name)} key={Dvalue.id}>{Dvalue.name} </li>

                            ))}

                    </ul>
                </div>
            </div>
        </div>)
}
export default Dropdown
import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function EditPurchase({ onClose, setRefresh, purchaseId ,orderId,productId}) {

    const auth = useContext(AuthContext)
    const [error, setError] = useState(null)
    const [stock,setStock] = useState()
    const [initialQuantity,setInitialQuantity] = useState()
    const [purchase, setPurchase] = useState({

        quantity: '',
        price: '',
        discount: '',
        gst:''
    })


    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/purchase-details/${purchaseId}/`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('bill', response)
            setPurchase(response.data);
            setInitialQuantity(response.data.quantity)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })

            axios({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL+`sales/stock/list/?search=&order=${orderId}&product=${productId}`,
                headers: {
                    //  'Authorization': `bearer ${token}`,
                    'bearer': auth.token,
                    'user-id': auth.userId,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
    
                console.log('stock product', response.data.results[0].balance)
                setStock(response.data.results[0].balance)
            }
            )
                .catch((error) => {
                    console.log('error', error.response)
    
                })

    }, []
    )

    const notify = () => toast.success("Great! Purchase Edited Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setPurchase({
            ...purchase, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            quantity: purchase.quantity,
            price: purchase.price,
            discount: purchase.discount,
            gst:purchase.gst
        }
        
        if(purchase.quantity>initialQuantity+stock){
            console.log('')
        }
        else{
        axios({
            method: 'patch',
            url: process.env.REACT_APP_BACKEND_URL+`sales/purchase/update/${purchaseId}/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            notify();
            onClose(false)
            setRefresh();
            setPurchase({
                quantity: '',
                price: '',
                discount: ''
            })

        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
        }

    }


    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '400px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Update Purchase</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => onClose(false)}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                            <span className="details">Price</span>
                            <input type="number" placeholder="Enter Price" name="price" value={purchase.price} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                           <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Quantity</span><span style={{color:'red',fontWeight:'400'}}>{stock?`Balance Quantity is`:''} <b>{stock}</b></span></div> 
                            <input type="number" placeholder="Enter Price" name="quantity" value={purchase.quantity} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">Discount</span>
                            <input type="number" placeholder="Enter Price" name="discount" value={purchase.discount} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                            <span className="details">GST%</span>
                            <input type="number" max={100} min={1} placeholder="Enter GST%" name="gst" value={purchase.gst} onChange={handleChange} required />
                        </div>
                    </div>

                    <center><span style={{ color: "red" }}>{error !== null && (error.non_field_errors ? "Product already Purchased" : 'Please fill all fields')}</span></center>
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default EditPurchase;
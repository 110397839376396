import React, { useState, useContext, useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from "../../shared/context/authContext";

function AddOrder({ onClose,refreshPage }) {

    let navigate = useNavigate()
    const auth = useContext(AuthContext)
    const [error,setError]=useState([])
    const [dropDownRoute, setDropDownRoute] = useState([])
    const [dropDownExecutive, setDropDownExecutive] = useState([])
    const [routeSearch, setRouteSearch] = useState('')
    const [userSearch, setUserSearch] = useState('')
    const [value, setValue] = useState("Select a Route")
    const [valueExecutive, setValueExecutive] = useState("Select an Executive")
    const [show, setShow] = useState(false)
    const [showExecutive, setShowExecutive] = useState(false)
    const [order, setOrder] = useState({
        route: "",
        executive: "",
        date: "",
        type: '101'
    });

    const notify = () => toast.success("Great! Order Created Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=route&limit=1000&offset=0&name=${routeSearch}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('route for dropdown', response.data.results)
            setDropDownRoute(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [routeSearch])

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&type=201&is_active=true`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('executive for dropdown', response.data.results)
            setDropDownExecutive(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])

    const handleToggle = (e) => {
        e.target.focus();
        setShow(!show);
    }
    const handleToggleExecutive = (e) => {
        e.target.focus();
        setShowExecutive(!showExecutive);
    }
    const handleChange = (e) => {
        setOrder({
            ...order, [e.target.name]: e.target.value
        });
    }

    const handleChangeRoute = (id, name) => {
        setOrder({
            ...order, route: id
        });
        setValue(name)
        setShow(false)
    }
    const handleChangeExecutive = (id, name) => {
        setOrder({
            ...order, executive: id
        });
        setValueExecutive(name)
        setShowExecutive(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(order)
        let payload;
        if (order.type==='101'){

        payload = {
            route: order.route,
            executive: order.executive,
            date: parseInt((new Date(order.date).getTime() / 1000).toFixed(0)),
            type: order.type
        }
    }
    else
    {
        payload = {
            executive: order.executive,
            date: parseInt((new Date(order.date).getTime() / 1000).toFixed(0)),
            type: order.type
        }
    }

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`sales/order/create/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            if(response.data.type===201){
                navigate(`/order/${response.data.id}`)
            }
            setOrder(
                {
                    route: "",
                    executive: "",
                    date: "",
                }
            )
            notify();
            onClose();
            refreshPage();
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError( error.response.data)

            })




    }

    const disabledDate = () =>{
        var today,dd,mm,yy;
        today=new Date()
        dd = today.getDate();
        mm = today.getMonth()+1
        yy = today.getFullYear()
        dd<10?dd='0'+dd:dd=dd
        mm<10?mm='0'+mm:mm=mm
        return yy+'-'+mm+'-'+dd
    }


    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '400px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Create Order</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <div className="radio-option" style={{marginTop:'10px',marginBottom:'10px'}}>
                    <div className="radio-item">
                        <label>
                            <input checked={order.type==='101'?true:false} type="radio" name="type" value='101' onChange={handleChange} /> Order
                        </label>
                    </div>
                    <div className="radio-item">
                        <label >
                            <input  checked={order.type==='201'?true:false} type="radio" name="type" value='201' onChange={handleChange} /> Sale
                        </label>
                    </div>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Route*</span><span style={{color:'red',fontWeight:'400'}}>{error.route?error.route:''}</span></div>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        disabled={order.type==='101'?false:true} 
                                        readOnly
                                        type="button"
                                        value={value}
                                        className="dropdown"
                                        onClick={handleToggle}
                                    />
                                </label>
                                <div hidden={!show} className="dropdown-list" >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setRouteSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownRoute.map((DRoute) => (
                                            <li className="option" onClick={() => handleChangeRoute(DRoute.id, DRoute.name)} key={DRoute.id}>{DRoute.name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Executive*</span><span style={{color:'red',fontWeight:'400'}}>{error.executive?error.executive:''}</span></div>
                            <div >
                                <label className="arrow">
                                    <textarea
                                        readOnly
                                        type="button"
                                        value={valueExecutive}
                                        className="dropdown"
                                        onClick={handleToggleExecutive}
                                    />
                                </label>
                                <div hidden={!showExecutive} className="dropdown-list" >
                                    <input type='text' placeholder="Type to Search..." onChange={(e) => setUserSearch(e.target.value)} />
                                    <ul   >
                                        {dropDownExecutive.map((DExecutive) => (
                                            <li className="option" onClick={() => handleChangeExecutive(DExecutive.id, DExecutive.full_name)} key={DExecutive.id}>{DExecutive.full_name} </li>

                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Pickup Date*</span><span style={{color:'red',fontWeight:'400'}}>{error.date?error.date:''}</span></div>
                            <input type="date" name="date" min={disabledDate()} value={order.date} onChange={handleChange} required />
                        </div>
                    </div>
                    {/* <center><span style={{ color: "red" }}>{error}</span></center> */}
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddOrder;
import React, { useState, useContext } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";
import capitalize from "../../common/Capitalize";

function AddRoute({ onClose,forReload }) {

    const auth = useContext(AuthContext)
    const [error,setError]=useState([])
    const [route, setRoute] = useState({
        name: "",
        start: "",
        end: "",
        target_shop: 0,
        target_amount: 0
    });

    const notify = () => toast.success("Great! Route Added Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setRoute({
            ...route, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            name:capitalize(route.name),
            start: capitalize(route.start),
            end:capitalize(route.end),
            target_shop: route.target_shop,
            target_amount: route.target_amount
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`sales/route/create/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setRoute(
                {
                    name: "",
                    start: "",
                    end: "",
                    target_shop: 0,
                    target_amount: 0
                }
            )
            notify();
            onClose()
            forReload()
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)

            })




    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '400px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Add Route</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >

                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Name*</span><span style={{color:'red',fontWeight:'400'}}>{error.name?error.name:''}</span></div>
                            <input  type="text" placeholder="Enter Name" name="name" value={route.name} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Start*</span><span style={{color:'red',fontWeight:'400'}}>{error.start?error.start:''}</span></div>
                            <input  type="text" placeholder="Enter starting place" name="start" value={route.start} onChange={handleChange} required />
                        </div>
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">End*</span><span style={{color:'red',fontWeight:'400'}}>{error.end?error.end:''}</span></div>
                            <input  type="text" placeholder="Enter ending place" name="end" value={route.end} onChange={handleChange} required />
                        </div>
                    </div>
                    {/* <center><span style={{ color: "red" }}>{error}</span></center> */}
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                    
                </form>
            </div>
        </div>
    );
}
export default AddRoute;
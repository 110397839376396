import React, { useState, useContext,useEffect } from "react";
import './addUser.css';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../shared/context/authContext";

function AddExpense({ onClose,userId,refresh }) {

    const auth = useContext(AuthContext)
    const [expense, setExpense] = useState({
        amount: "",
        note:''
    });
    const [error,setError]=useState([])
    const notify = () => toast.success("Expense Added Successfully!", { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });

    const handleChange = (e) => {
        setExpense({
            ...expense, [e.target.name]: e.target.value
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(expense)

        const payload = {
            user: userId,
            note: expense.note,
            amount: expense.amount,
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL+`sales/expense/create/`,
            data: payload,
            headers: {
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response);
            setExpense(
                {
                    note: "",
                    amount: "",
                }
            )
            notify();
            onClose()
            refresh()
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)
                setError(error.response.data)
            })




    }

    return (
        <div className="dialoguePage">
            <div className="dialogueBox" style={{ height: '300px' }}>
                <div className="head">
                    <h2 style={{ color: "#003cff" }}>Create Expense</h2>
                    <span style={{ color: "red", fontSize: "20px", cursor: "pointer" }} onClick={onClose}><i class="fa-regular fa-circle-xmark"></i></span>
                </div>
                <form>
                    <div className="user-details" >
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Amount*</span><span style={{color:'red',fontWeight:'400'}}>{error.amount?error.amount:''}</span></div>
                            <input type="number" placeholder="Enter Amount" name="amount" value={expense.amount} onChange={handleChange} required />
                        </div>
                    
                    
                        <div className="input-box">
                        <div style={{display:'flex',justifyContent:'space-between'}}><span className="details">Note*</span><span style={{color:'red',fontWeight:'400'}}>{error.note?error.note:''}</span></div>
                            <input type="text" placeholder="Enter Note" name="note" value={expense.note} onChange={handleChange} required />
                        </div>
                    </div>
                    
                    <div className="button" >
                        <input type="submit" value="Add" onClick={handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddExpense;
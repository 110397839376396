import './employee.css'
import { useState, useEffect, useContext } from 'react';
import MainHeader from '../common/components/MainHeader';
import Sidebar from '../common/components/Sidebar';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../shared/context/authContext';
import Loading from '../common/Loading';
import getTime from '../common/GetTime';
import { getCurrentDate } from '../common/GetCurrentDate';
import DropDown from '../common/components/DropDown'


const CheckIn = () => {

    
    const todaysDate = () =>{
        var today,dd,mm,yy;
        today=new Date()
        dd = today.getDate();
        mm = today.getMonth()+1
        yy = today.getFullYear()
        dd<10?dd='0'+dd:dd=dd
        mm<10?mm='0'+mm:mm=mm
        return yy+'-'+mm+'-'+dd
    }

    const openMap = (lat, lon) => {
        const url = `https://www.google.com/maps?q=${lat},${lon}`;
        var top = window.screen.height - 600;
        top = top > 0 ? top / 2 : 0;

        var left = window.screen.width - 800;
        left = left > 0 ? left / 2 : 0;

        var uploadWin = window.open(url, "location", "width=800,height=600" + ",top=" + top + ",left=" + left);
        uploadWin.moveTo(left, top);
        uploadWin.focus();
    }

    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState(true);
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [checkIn, setCheckIn] = useState([])
    const [date, setDate] = useState(todaysDate());
    const [forDate, setForDate] = useState(getCurrentDate());
    const [dropDownUser, setDropDownUser] = useState([])
    const [userSearch, setUserSearch] = useState('')
    const [creator, setCreator] = useState('')
    const [count, setCount] = useState('');
    const [countA, setCountA] = useState(1);
    const [countB, setCountB] = useState('');
    const [prevCountB, setPrevCountB] = useState();

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`accounts/checkin/list/?user=${creator}&date=${forDate}`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('checkin', response)
            setCheckIn(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setCount(response.data.count)
            setCountB(response.data.results.length)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }, [forDate,creator])
    

    const changeDate =(e)=>{
        let selectedDate = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
        setForDate(selectedDate);
        setDate(e.target.value)
    }

    const onSwitchPage = (url,action) => {
        setLoading(true)
        axios({
            method: 'get',
            url: url,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response)
            setCheckIn(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setLoading(false)
            setPrevCountB(response.data.results.length)
            if (action === 'next') {
                setCountA((value) => value + 10)
                setCountB((value) => value + response.data.results.length)
               

            }
            if (action === 'prev') {
                setCountA((value) => value - 10)
                setCountB((value) => value - prevCountB)
            }
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL+`sales/model/list/?model=user&limit=1000&offset=0&name=${userSearch}&type=`,
            headers: {
                //  'Authorization': `bearer ${token}`,
                'bearer': auth.token,
                'user-id': auth.userId,
                'Content-Type': 'application/json'
            },
        }).then((response) => {

            console.log('user for dropdown', response.data.results)
            setDropDownUser(response.data.results)
        }
        )
            .catch((error) => {
                console.log('error', error.response.data)

            })


    }, [userSearch])

   

    return (
        <div className='full'>
        
            <Sidebar name="checkin" />
            <ToastContainer />
            <div class="content">
            {loading && <Loading/>}

                <MainHeader />


                <div class="main-container">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <h3 style={{ color: "#060082", marginBottom: "10px" }}>Check-In Details</h3>
                        <div class="search" >
                            <input  type="date" value={date} onChange={changeDate}/>
                            
                        </div>
                       <div style={{marginBottom:'10px',fontWeight:'600'}}> <DropDown dropdownFor='User' dropdownElements={dropDownUser} setId={setCreator} dropdownSearch={setUserSearch} />
                       </div>
                       
                    </div>
                   
                    <div class="tables">
                        <div class="table-content">
                            <table >
                                <thead>
                                    <td>Name</td>
                                    <td>Time</td>
                                    <td>Note</td>
                                    <td>Check-In Location</td>
                                    <td>Check-Out Location</td>
                                    


                                </thead>
                                <tbody>
                                    {checkIn.map((checkIn) => (
                                        <tr  key={checkIn.idencode}>
                                           <td>{checkIn.user.full_name}</td> 
                                           <td>{getTime(checkIn.created_on)}</td>
                                           <td>{checkIn.note}</td>
                                           <td><button onClick={(e) =>{e.stopPropagation();openMap(checkIn.latitude,checkIn.longitude)}} className='button-31' style={{ border: '1px solid grey',padding:'0px 5px',background:'white',color:'black' }}><i style={{color:'blue'}} class="fa-solid fa-location-dot"></i>Open map</button></td>
                                           <td>{checkIn.check_out_latitude===''?"No Location Data":
                                            <button onClick={(e) =>{e.stopPropagation();openMap(checkIn.check_out_latitude,checkIn.check_out_longitude)}} className='button-31' style={{ border: '1px solid grey',padding:'0px 5px',background:'white',color:'black' }}><i style={{color:'blue'}} class="fa-solid fa-location-dot"></i>Open map</button>
                                            }</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        <div className="switchbutton">
                                
                                {(previous || next) && <div>
                                <button disabled={previous === null} className="nextbtn" onClick={() => onSwitchPage(previous, 'prev')} ><i class="fa-sharp fa-solid fa-angle-left"></i></button>
                                <button disabled={next === null} className="nextbtn" onClick={() => onSwitchPage(next, 'next')}><i class="fa-sharp fa-solid fa-angle-right"></i></button>
                            </div>}
                            {checkIn.length!==0 &&<div style={{paddingLeft:'30px'}}>Showing {countA}-{countB} of {count}</div>}
                            </div>
                    </div>
                    
                            
                        </div>
                      
                    </div>
                </div>
           

    )
}
export default CheckIn;